import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardDeck, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { HOST } from '../../../utils/routingUtils';
import tut1 from '../../../assets/img/tutorial-bg-1.jpg';
import tut2 from '../../../assets/img/tutorial-bg-2.jpg';
import tut3 from '../../../assets/img/tutorial-bg-3.jpg';
import styles from './Home.module.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DeveloperKey, Host, HostsData, OS, User } from '../../../types/types.d';
import { getHostsData } from '../../../graphql/queries';
import { addHostToDeveloperKey, deleteHostFromDeveloperKey } from '../../../graphql/mutations';
import logger from '../../../utils/logger/logger';
import { developerKeyContainsHost, link2dkFileName, linkhqFileName } from '../../../utils/businessUtils';
import { downloadFile } from '../../../utils/windowUtils';
import { useSelector } from 'react-redux';
import { AuthSliceState } from '../../../slices/authSlice';
import { userHasAdminRole } from '../../../utils/userUtils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Store } from 'react-notifications-component';
import { baseErrorNotification, baseSuccessNotification } from '../../../utils/nitificationUtils';
import DeleteHost from '../common/deleteHost';
import { getLocalisedOs } from '../../../utils/stringUtils';

const DEV_KEY_PATH_WINDOWS = 'C:\\Users\\<username>';
const DEV_KEY_PATH_OTHERS = '/home/<username>';

export default function Home(): JSX.Element {
  const { t } = useTranslation();
  const [doAddHostToDeveloperKey] = useMutation<{ addHostToDeveloperKey?: DeveloperKey }>(addHostToDeveloperKey);
  const [doDeleteHostFromDeveloperKey] =
    useMutation<{ deleteHostFromDeveloperKey?: DeveloperKey }>(deleteHostFromDeveloperKey);

  const [hostToDelete, setHostToDelete] = useState<Host | null>(null);

  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const isAdmin = useMemo(() => userHasAdminRole(user as User), [user]);

  // const [devKeyUpdated, setDevKeyUpdated] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = useCallback(() => setShowModal(false), []);
  const handleShowModal = useCallback(() => setShowModal(true), []);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const [fetchHostsData, { data, loading, error }] = useLazyQuery<{ getHostsData?: HostsData }>(getHostsData);
  useEffect(() => {
    fetchHostsData();
  }, [fetchHostsData]);

  const hosts = useMemo(
    () => (data?.getHostsData?.hosts ? data?.getHostsData?.hosts : []),
    [data?.getHostsData?.hosts],
  );
  const developerKey = useMemo(() => data?.getHostsData?.developerKey, [data?.getHostsData?.developerKey]);

  const handleHostDelete = useCallback(() => {
    fetchHostsData();
    // close modal
    setHostToDelete(null);
  }, [fetchHostsData]);

  const handleHostAttach = useCallback(
    (host: Host) => {
      if (developerKey && user?.maxDevHostsCount && developerKey.hostIds.length >= user.maxDevHostsCount) {
        handleShowModal();
      } else {
        doAddHostToDeveloperKey({ variables: { hostId: host.id } })
          .then((result) => {
            if (result) {
              fetchHostsData();
              // setDevKeyUpdated(true);
              showSuccessNotification(
                result.data?.addHostToDeveloperKey?.hostIds?.length === 1
                  ? t('PRIVATE.HOME.KEY_CREATED')
                  : t('PRIVATE.HOME.KEY_UPDATED'),
              );
            } else {
              showErrorNotification(t('PRIVATE.HOME.KEY_UPDATE_FAILED'));
              logger.error('Not added to developer key host');
            }
          })
          .catch((error) => {
            showErrorNotification(t('PRIVATE.HOME.KEY_UPDATE_FAILED'));
            logger.error(`Error adding host to developer key: ${error}`);
          });
      }
    },
    [
      developerKey,
      doAddHostToDeveloperKey,
      fetchHostsData,
      handleShowModal,
      showErrorNotification,
      showSuccessNotification,
      t,
      user?.maxDevHostsCount,
    ],
  );

  const handleHostDetach = useCallback(
    (host: Host) => {
      doDeleteHostFromDeveloperKey({ variables: { hostId: host.id } })
        .then((result) => {
          if (result) {
            fetchHostsData();
            // setDevKeyUpdated(true);
            showSuccessNotification(
              result.data?.deleteHostFromDeveloperKey?.hostIds?.length === 0
                ? t('PRIVATE.HOME.KEY_DELETED')
                : t('PRIVATE.HOME.KEY_UPDATED'),
            );
          } else {
            showErrorNotification(t('PRIVATE.HOME.KEY_UPDATE_FAILED'));
            logger.error('Not deleted host from developer key');
          }
        })
        .catch((error) => {
          showErrorNotification(t('PRIVATE.HOME.KEY_UPDATE_FAILED'));
          logger.error(`Error deleting host from developer key: ${error}`);
        });
    },
    [doDeleteHostFromDeveloperKey, fetchHostsData, showErrorNotification, showSuccessNotification, t],
  );

  // if initial data finished loading and not hosts are returned then redirect for User only
  if (!isAdmin && !loading && (error || (data && !data.getHostsData?.hosts?.length))) {
    return <Redirect to={HOST} />;
  }
  if (loading) return <></>;

  return (
    <>
      {hostToDelete && (
        <DeleteHost
          show={!!hostToDelete}
          onHide={() => setHostToDelete(null)}
          host={hostToDelete}
          onDelete={handleHostDelete}
        />
      )}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          You have reached the highest number of host in your Developer Key. Please detach one host first.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Container className={classNames(styles.content, 'box full-h p-5')}>
        <Row>
          <Col>
            <h1>{t('PRIVATE.HOME.TITLE')}</h1>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={10}>
            <h5>{t('PRIVATE.HOME.TUTORIALS_TO_START')}</h5>
          </Col>
          <Col sm={2} className="text-right">
            <a href="https://draive.com/link_dev/guide/" target="_blank" rel="noreferrer">
              <strong>{t('PRIVATE.HOME.ALL_TUTORIALS')}</strong>
            </a>
          </Col>
          <Col />
        </Row>
        <Row className="mt-2">
          <Col>
            <Row>
              <Col>
                <CardDeck>
                  <Card className={styles.card}>
                    <a href="https://draive.com/link_dev/guide/02_Run_Node/" target="_blank" rel="noreferrer">
                      <Card.Img variant="top" src={tut1} />
                      <Card.Body>
                        <header className="font-weight-bold h6">{t('PRIVATE.HOME.HOW_TO_1')}</header>
                        <Card.Text>{t('PRIVATE.HOME.DEVELOPER')}</Card.Text>
                      </Card.Body>
                    </a>
                  </Card>

                  <Card className={styles.card}>
                    <a href="https://draive.com/link_dev/guide/03a_Build/" target="_blank" rel="noreferrer">
                      <Card.Img variant="top" src={tut2} />
                      <Card.Body>
                        <header className="font-weight-bold h6">{t('PRIVATE.HOME.HOW_TO_1')}</header>
                        <Card.Text>{t('PRIVATE.HOME.DEVELOPER')}</Card.Text>
                      </Card.Body>
                    </a>
                  </Card>

                  <Card className={styles.card}>
                    <a href="https://draive.com/link_dev/guide/03f_Node_From_Scratch/" target="_blank" rel="noreferrer">
                      <Card.Img variant="top" src={tut3} />
                      <Card.Body>
                        <header className="font-weight-bold h6">{t('PRIVATE.HOME.HOW_TO_1')}</header>
                        <Card.Text>{t('PRIVATE.HOME.DEVELOPER')}</Card.Text>
                      </Card.Body>
                    </a>
                  </Card>
                </CardDeck>
              </Col>
            </Row>
            {!loading && (
              <Row className="mt-4">
                <Col>
                  <div>
                    {developerKey && !!developerKey.hostIds.length && (
                      <>
                        <h5 className="font-weight-bold">{t('PRIVATE.HOME.YOUR_KEY')}</h5>
                        {/*{devKeyUpdated && (*/}
                        {/*  <div className="alert alert-danger mt-3" role="alert">*/}
                        {/*    {t('PRIVATE.HOME.KEY_UPDATED')}*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        <div className={styles.devKey}>
                          <div className={styles.header}>
                            <div>
                              <dl>
                                <dd>{t('PRIVATE.HOME.VALID_THROUGH')}</dd>
                                <dt>
                                  {new Date(developerKey.expirationDate).toLocaleDateString('de', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                  })}
                                </dt>
                              </dl>
                              <dl>
                                <dd>{t('PRIVATE.HOME.HOSTS_IN_USE')}</dd>
                                <dt>{`${developerKey.hostIds.length} of ${user?.maxDevHostsCount}`}</dt>
                              </dl>
                            </div>
                          </div>
                          <div className={styles.footer}>
                            <div className={styles.footerContent}>
                              <div className={styles.msg}>{t('PRIVATE.HOME.DOWNLOAD_DEV_KEY')}</div>
                              <div className={styles.path}>
                                <div className={styles.os}>{getLocalisedOs(OS.WINDOWS)}:</div>
                                <div className={styles.osValue}>{DEV_KEY_PATH_WINDOWS}</div>
                              </div>
                              <div className={styles.path}>
                                <div className={styles.os}>{getLocalisedOs(OS.LINUX)}:</div>
                                <div className={styles.osValue}>{DEV_KEY_PATH_OTHERS}</div>
                              </div>
                            </div>
                            <button
                              className={'btn btn-primary'}
                              onClick={() =>
                                developerKey.developerKey
                                  ? downloadFile(link2dkFileName, developerKey.developerKey)
                                  : {}
                              }
                            >
                              Download Developer Key
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    <h5
                      className={
                        'font-weight-bold' + (developerKey?.developerKey ? (hosts?.length > 0 ? ' mt-5' : '') : '')
                      }
                    >
                      {t('PRIVATE.HOME.REGISTERED_HOSTS', { count: hosts?.length })}
                    </h5>

                    <p>{t('PRIVATE.HOME.USE_UP_TO_HOSTS', { count: user?.maxDevHostsCount })}</p>

                    <div className="mt-3">
                      {hosts.map((host, i) => (
                        <div key={i} className="data-row">
                          <div>
                            <i className="host-status" />
                          </div>
                          <div className="flex-grow-1">{host.hostName}</div>
                          <div className={styles.devKeyLabel}>
                            {developerKey && developerKeyContainsHost(developerKey, host) && (
                              <div className="badge">dev host</div>
                            )}
                          </div>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="link"
                                className={classNames('row-functions', styles.devKeyOptions)}
                              >
                                <svg width="16" height="4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M2 0a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4z"
                                    fill="#7F84B0"
                                  />
                                </svg>
                              </Dropdown.Toggle>

                              <Dropdown.Menu alignRight>
                                <Dropdown.Item onClick={() => downloadFile(linkhqFileName, host.hostQualifier)}>
                                  Download &quot;{linkhqFileName}&quot; file
                                </Dropdown.Item>
                                {developerKey && developerKeyContainsHost(developerKey, host) ? (
                                  <Dropdown.Item onClick={() => handleHostDetach(host)}>
                                    Stop using as a development host
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item onClick={() => handleHostAttach(host)}>
                                    Use as a development host
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item onClick={() => setHostToDelete(host)}>Delete host</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="text-right mt-3">
                      <Link className="btn btn-outline-primary" to={HOST}>
                        Add host
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          {/* <Col sm={true}>
            <div className="box">
              <h4>Current plan</h4>
              <figure className={styles.planbg}>
                <figcaption>Core</figcaption>
              </figure>
              <div className="mt-2">
                {['400 CI/CD Minutes', 'Available nodes', 'API Calls', 'Pin Metrics', 'Data Probes'].map((el, i) => (
                  <div key={i} className="data-row row-s">
                    <div>
                      <img width="24px" src={check} />
                    </div>
                    <div className="flex-grow-1">{el}</div>
                  </div>
                ))}
              </div>
              <div className="text-right mt-3">
                <a
                  className="btn btn-outline-primary"
                  href="https://draive.webflow.io/pricing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Upgrade
                </a>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}
