import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/theme.scss';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import App from './components/app';
import store from './components/app/store';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import './i18n.config';
import { CookiesProvider } from 'react-cookie';
import logger from './utils/logger/logger';
import { StatusCodes } from 'http-status-codes';
import { allPrivateRoutes, LOGIN, URL_QUERY_PARAM_EXPIRED_SESSION } from './utils/routingUtils';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

const uri = `${process.env.REACT_APP_GRAPHQL_PATH}`;
const link = new HttpLink();

/**
 * used to control logout action due to possible multiple parallel graphql requests
 */
let logoutTriggered = false;

/**
 * Reacts to a 401 HTTP error from Apollo Client requests and redirects to the Login page on the very first encountered 401
 */
const logoutLink = onError(({ networkError }: ErrorResponse) => {
  try {
    const error = networkError as ServerError;
    const loginLocationWithExpired = `#${LOGIN}?${URL_QUERY_PARAM_EXPIRED_SESSION}`;
    const locationHash = location.hash.substring(1); // remove # sign

    if (error.statusCode === StatusCodes.UNAUTHORIZED && allPrivateRoutes.includes(locationHash) && !logoutTriggered) {
      logoutTriggered = true;

      // update the history and location and then refresh the page, only in such way normal redirect works
      history.replaceState(null, '', loginLocationWithExpired);
      location.hash = loginLocationWithExpired;
      window.location.reload();
    }
  } catch (e) {
    logger.error(`logoutLink error: ${e}`);
  }
});

const client = new ApolloClient({
  uri,
  credentials: 'include',
  link: logoutLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <CookiesProvider>
          <div className="app-container">
            <ReactNotifications isMobile={true} />
            <App />
          </div>
        </CookiesProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
