import { gql } from '@apollo/client';

export const fragments = {
  paging: gql`
    fragment Paging on Paging {
      page
      perPage
      pageCount
      totalCount
    }
  `,
  users: gql`
    fragment Users on Users {
      users {
        ...User
      }
      paging {
        ...Paging
      }
    }
  `,
  user: gql`
    fragment User on User {
      id
      email
      fullName
      firstName
      lastName
      techUsername
      techPassword
      role
      languageIso6391
      countryIsoAlpha2
      position
      companyName
      companyType
      supportWays
      otherSupportWays
      twoFAEnabled
      productUpdatesEnabled
      maxDevHostsCount
      emailVerified
      regIp
      createdByAdmin
      groups {
        id
        name
        ownerId
        membersCount
        plan
        createdDate
      }
      condaKey
      createdDate
      lastModifiedDate
    }
  `,
  host: gql`
    fragment Host on Host {
      id
      machineId
      hostName
      expirationDate
      createdDate
      hostQualifier
    }
  `,
  developerKey: gql`
    fragment DeveloperKey on DeveloperKey {
      hostIds
      expirationDate
      createdDate
      developerKey
    }
  `,
  hostsData: gql`
    fragment HostsData on HostsData {
      hosts {
        ...Host
      }
      developerKey {
        ...DeveloperKey
      }
    }
  `,
  blacklistedUsernames: gql`
    fragment BlacklistedUsernames on BlacklistedUsernames {
      usernames {
        ...BlacklistedUsername
      }
      paging {
        ...Paging
      }
    }
  `,
  blacklistedUsername: gql`
    fragment BlacklistedUsername on BlacklistedUsername {
      id
      username
      createdBy
      createdDate
    }
  `,
  groups: gql`
    fragment Groups on Groups {
      groups {
        ...Group
      }
      paging {
        ...Paging
      }
    }
  `,
  group: gql`
    fragment Group on Group {
      id
      name
      ownerId
      membersCount
      plan
      createdBy
      createdDate
    }
  `,
  groupWithMembers: gql`
    fragment Group on Group {
      id
      name
      ownerId
      membersCount
      members {
        id
        fullName
        firstName
        lastName
        techUsername
      }
      plan
      createdBy
      createdDate
    }
  `,
  groupData: gql`
    fragment GroupData on GroupData {
      group {
        id
        name
        ownerId
        membersCount
        members {
          id
          fullName
          firstName
          lastName
          techUsername
        }
        plan
        condaChannel
        condaKey
        createdBy
        createdDate
      }
      subscriptions {
        ...PspSubscription
      }
      subscriptionSchedules {
        ...PspSubscriptionSchedule
      }
    }
  `,
  pspProduct: gql`
    fragment PspProduct on PspProduct {
      id
      name
    }
  `,
  pspPrice: gql`
    fragment PspPrice on PspPrice {
      id
      product
      amount
      recurringPeriod
    }
  `,
  pspPromotionCode: gql`
    fragment PspPromotionCode on PspPromotionCode {
      id
      code
      active
    }
  `,
  pspInvoice: gql`
    fragment PspInvoice on PspInvoice {
      created
      number
      hostedUrl
      pdfUrl
    }
  `,
  pspSubscriptionSchedule: gql`
    fragment PspSubscriptionSchedule on PspSubscriptionSchedule {
      id
      created
      status
      startDate
      subscription
      groupId
      plan
    }
  `,
  pspSubscription: gql`
    fragment PspSubscription on PspSubscription {
      id
      cancelAtPeriodEnd
      cancelledAt
      created
      currentPeriodEnd
      currentPeriodStart
      discount {
        ...PspDiscount
      }
      endedAt
      items {
        ...PspSubscriptionItem
      }
      groupId
      groupName
      startDate
      status
    }
  `,
  pspDiscount: gql`
    fragment PspDiscount on PspDiscount {
      coupon {
        ...PspCoupon
      }
    }
  `,
  pspCoupon: gql`
    fragment PspCoupon on PspCoupon {
      id
      amountOff
      duration
      name
      percentOff
      valid
    }
  `,
  pspSubscriptionItem: gql`
    fragment PspSubscriptionItem on PspSubscriptionItem {
      price {
        ...PspPrice
      }
    }
  `,
  pspCustomer: gql`
    fragment PspCustomer on PspCustomer {
      address {
        ...PspCustomerAddress
      }
      invoiceSettings {
        ...PspCustomerInvoiceSettings
      }
      taxId
    }
  `,
  pspCustomerAddress: gql`
    fragment PspCustomerAddress on PspCustomerAddress {
      city
      country
      line1
      line2
      postalCode
    }
  `,
  pspCustomerInvoiceSettings: gql`
    fragment PspCustomerInvoiceSettings on PspCustomerInvoiceSettings {
      defaultPaymentMethodObject {
        ...PspPaymentMethod
      }
    }
  `,
  pspPaymentMethod: gql`
    fragment PspPaymentMethod on PspPaymentMethod {
      type
      card {
        ...PspPaymentMethodCard
      }
      sepaDebit {
        ...PspPaymentMethodSepaDebit
      }
    }
  `,
  pspPaymentMethodCard: gql`
    fragment PspPaymentMethodCard on PspPaymentMethodCard {
      brand
      expMonth
      expYear
      last4
    }
  `,
  pspPaymentMethodSepaDebit: gql`
    fragment PspPaymentMethodSepaDebit on PspPaymentMethodSepaDebit {
      bankCode
      last4
    }
  `,
};

export const me = gql`
  query {
    me {
      ...User
    }
  }
  ${fragments.user}
`;

export const getUser = gql`
  query ($id: String!) {
    getUser(id: $id) {
      ...User
    }
  }
  ${fragments.user}
`;

export const getUsers = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: UserSearchCriteria) {
    getUsers(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Users
    }
  }
  ${fragments.users}
  ${fragments.user}
  ${fragments.paging}
`;

export const getHosts = gql`
  query ($email: String) {
    getHosts(email: $email) {
      ...Host
    }
  }
  ${fragments.host}
`;

export const getDeveloperKey = gql`
  query ($email: String) {
    getDeveloperKey(email: $email) {
      ...DeveloperKey
    }
  }
  ${fragments.developerKey}
`;

export const getHostsData = gql`
  query ($email: String) {
    getHostsData(email: $email) {
      ...HostsData
    }
  }
  ${fragments.hostsData}
  ${fragments.host}
  ${fragments.developerKey}
`;

export const getBlacklistedUsernames = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: BlacklistedUsernameSearchCriteria) {
    getBlacklistedUsernames(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...BlacklistedUsernames
    }
  }
  ${fragments.blacklistedUsernames}
  ${fragments.blacklistedUsername}
  ${fragments.paging}
`;

export const getGroups = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: GroupSearchCriteria) {
    getGroups(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Groups
    }
  }
  ${fragments.groups}
  ${fragments.group}
  ${fragments.paging}
`;

export const getGroup = gql`
  query ($groupId: String!) {
    getGroup(groupId: $groupId) {
      ...Group
    }
  }
  ${fragments.groupWithMembers}
`;

export const getGroupData = gql`
  query ($groupId: String!, $checkoutSessionId: String) {
    getGroupData(groupId: $groupId, checkoutSessionId: $checkoutSessionId) {
      ...GroupData
    }
  }
  ${fragments.groupData}
  ${fragments.pspSubscriptionSchedule}
  ${fragments.pspSubscription}
  ${fragments.pspDiscount}
  ${fragments.pspCoupon}
  ${fragments.pspSubscriptionItem}
  ${fragments.pspPrice}
`;

export const groupExists = gql`
  query ($name: String!) {
    groupExists(name: $name)
  }
`;

export const userExists = gql`
  query ($email: String!) {
    userExists(email: $email)
  }
`;

export const getCheckoutSessionUrlForSetup = gql`
  query ($successUrl: String!, $cancelUrl: String!) {
    getCheckoutSessionUrlForSetup(successUrl: $successUrl, cancelUrl: $cancelUrl)
  }
`;

export const getCheckoutSessionUrlForSubscription = gql`
  query ($groupId: String!, $priceId: String!, $successUrl: String!, $cancelUrl: String!, $promotionCodeId: String) {
    getCheckoutSessionUrlForSubscription(
      groupId: $groupId
      priceId: $priceId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      promotionCodeId: $promotionCodeId
    )
  }
`;

export const getSubscriptions = gql`
  query getSubscriptions($email: String) {
    getSubscriptions(email: $email) {
      ...PspSubscription
    }
  }
  ${fragments.pspSubscription}
  ${fragments.pspDiscount}
  ${fragments.pspCoupon}
  ${fragments.pspSubscriptionItem}
  ${fragments.pspPrice}
`;

export const getProducts = gql`
  query getProducts {
    getProducts {
      ...PspProduct
    }
  }
  ${fragments.pspProduct}
`;

export const getPrices = gql`
  query getPrices {
    getPrices {
      ...PspPrice
    }
  }
  ${fragments.pspPrice}
`;

export const getInvoices = gql`
  query getSubscriptions($subscriptionId: String) {
    getInvoices(subscriptionId: $subscriptionId) {
      ...PspInvoice
    }
  }
  ${fragments.pspInvoice}
`;

export const getPromotionCode = gql`
  query getPromotionCode($code: String!) {
    getPromotionCode(code: $code) {
      ...PspPromotionCode
    }
  }
  ${fragments.pspPromotionCode}
`;

export const getCoupon = gql`
  query getCoupon($id: String!) {
    getCoupon(id: $id) {
      ...PspCoupon
    }
  }
  ${fragments.pspCoupon}
`;

export const getCustomer = gql`
  query getCustomer($email: String, $checkoutSessionId: String) {
    getCustomer(email: $email, checkoutSessionId: $checkoutSessionId) {
      ...PspCustomer
    }
  }
  ${fragments.pspCustomer}
  ${fragments.pspCustomerAddress}
  ${fragments.pspCustomerInvoiceSettings}
  ${fragments.pspPaymentMethod}
  ${fragments.pspPaymentMethodCard}
  ${fragments.pspPaymentMethodSepaDebit}
`;
