import React, { useEffect, useState } from 'react';
import styles from './EmailVerification.module.scss';
import { LOGIN, ROOT, URL_QUERY_PARAM_KEY, useURLSearchParams } from '../../utils/routingUtils';
import { Link, Redirect } from 'react-router-dom';
import { Button, Container, Row } from 'react-bootstrap';
import { triggerVerificationEmail, verifyEmail } from '../../services/user.service';
import { useSelector } from 'react-redux';
import { AuthSliceState } from '../../slices/authSlice';
import { useTranslation } from 'react-i18next';
import logger from '../../utils/logger/logger';

/**
 * Handles a link of type http://localhost:3000/#/verify-email or http://localhost:3000/#/verify-email?key=7100481527758907.
 * User can open this component when he is logged in or not. Both cases are handled using conditional checks.
 * The component is used to cover following use cases:
 * - user verified his email
 * - user didn't verify his email
 * - user is logged in
 * - user is not logged in
 *
 * Note: special use cases like EMAIL_ALREADY_VERIFIED, USER_NOT_FOUND are not covered
 * and user is not notified about such error
 */
export default function EmailVerification(): JSX.Element {
  const { t } = useTranslation();
  const key = useURLSearchParams().get(URL_QUERY_PARAM_KEY);
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const userLoggedIn = user != null;
  const emailAlreadyVerified = user && user.emailVerified;

  const [verificationSucceeded, setVerificationSucceeded] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [verifying, setVerifying] = useState(true);

  const [resending, setResending] = useState(false);
  const [resent, setResent] = useState(false);

  useEffect(() => {
    if (key) {
      verifyEmail(key)
        .then((result) => (result ? setVerificationSucceeded(true) : setVerificationFailed(true)))
        .finally(() => setVerifying(false));
    }
  }, [key]);

  const resendEmail = () => {
    setResending(true);
    setResent(false);
    triggerVerificationEmail()
      .then(() => {
        setResending(false);
        setResent(true);
      })
      .catch((error) => {
        logger.error(`Error while triggering verification email: ${error}`);
      });
  };

  // if "key" is present in the URL then this is user coming from his email box to verify the email
  // otherwise it's a protection from the unverified user seeing private pages.

  if (key) {
    if (userLoggedIn) {
      if (verifying) {
        // USER IS LOGGED IN AND VERIFICATION IS TAKING PLACE
        return <></>;
      } else {
        if (verificationSucceeded) {
          // USER IS LOGGED IN AND VERIFICATION FINISHED SUCCESSFULLY
          setTimeout(() => window.location.reload(), 5000);
          return (
            <Container className={styles.content}>
              <Row className={styles.msg}>
                <div>
                  <h2>Your email has been successfully verified!</h2>
                  <div className="alert alert-primary mt-4" role="alert">
                    You will be redirected shortly.
                  </div>
                </div>
              </Row>
            </Container>
          );
        } else if (verificationFailed) {
          // USER IS LOGGED IN AND VERIFICATION FINISHED WITH ERROR
          return <Redirect to={{ pathname: ROOT }} />;
        } else {
          return <Redirect to={{ pathname: ROOT }} />;
        }
      }
    } else {
      // USER NOT PRESENT
      if (verifying) {
        // USER IS LOGGED IN AND VERIFICATION IS TAKING PLACE
        return <></>;
      } else {
        if (verificationSucceeded) {
          // VERIFICATION SUCCESSFUL
          return (
            <Container className={styles.content}>
              <Row className={styles.msg}>
                <h2>Your email have been verified and you can sign in now.</h2>
              </Row>
              <Row className={styles.msg}>
                <div>{<Link to={LOGIN}>{t('EMAIL_VERIFICATION.LOGIN')}</Link>}</div>
              </Row>
            </Container>
          );
        } else if (verificationFailed) {
          // ERROR HAPPENED WHILE VERIFYING
          // USER IS NOT LOGGED IN AND VERIFICATION FINISHED WITH ERROR
          return <Redirect to={{ pathname: ROOT }} />;
        } else {
          return <Redirect to={{ pathname: ROOT }} />;
        }
      }
    }
  } else {
    if (userLoggedIn) {
      if (emailAlreadyVerified) {
        return <Redirect to={{ pathname: ROOT }} />;
      } else {
        // USER IS LOGGED IN AND HAS NOT YET VERIFIED THE ADDRESS
        return (
          <div className="authpage text-center">
            <div className="subheader">Join linkworks</div>
            <h4>Please verify your email</h4>

            <div className="authbox">
              <div className="authform">
                <figure className="mb-0">
                  <svg width="100" height="96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M84 21L48 54 12 21"
                      stroke="#27B4FF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 21h72v51a3 3 0 01-3 3H15a3 3 0 01-3-3V21zM41.455 48l-28.53 26.152M83.08 74.152L54.55 48"
                      stroke="#27B4FF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M95.967 65.033a2.002 2.002 0 00-1.957-.51L70.719 71.09a2 2 0 00-.313 3.733l9.741 4.614 6.66-6.66a1 1 0 011.415 1.414l-6.66 6.66 4.614 9.742a1.98 1.98 0 001.975 1.137 1.984 1.984 0 001.757-1.45l6.57-23.291a2.002 2.002 0 00-.511-1.957z"
                      fill="#0F1652"
                    />
                  </svg>
                </figure>

                <p className="mt-5">
                  You’re almost there! We sent an email to <span className="font-weight-bold">{user?.email}</span>
                </p>
                <p className="mt-5 font-weight-normal text-info">
                  Click on the link in that email to complete your signup. If you do not see it, you may need to check
                  your spam folder.
                </p>

                <p className="mt-5">Still can’t find the email?</p>
                <Button variant="primary" onClick={resendEmail} disabled={resending}>
                  {resending ? '...' : 'Resend Email'}
                </Button>

                {resent && (
                  <div className="alert alert-success mt-2" role="alert">
                    Your email was resent
                  </div>
                )}

                <small id="emailHelp" className="form-text mt-3 text-center">
                  <span>Need help?</span>{' '}
                  <Link to={LOGIN} className="alert-link">
                    Contact us via the chat button at the bottom right.
                  </Link>
                </small>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <Redirect to={{ pathname: LOGIN }} />;
    }
  }
}
