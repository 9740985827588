import { gql } from '@apollo/client';
import { fragments } from './queries';

export const createUser = gql`
  mutation ($input: UserInput!) {
    createUser(input: $input) {
      ...User
    }
  }
  ${fragments.user}
`;

export const updateUser = gql`
  mutation ($input: UserInput!) {
    updateUser(input: $input) {
      ...User
    }
  }
  ${fragments.user}
`;

export const requestEmailChange = gql`
  mutation ($email: String!, $newEmail: String!) {
    requestEmailChange(email: $email, newEmail: $newEmail)
  }
`;

export const changePassword = gql`
  mutation ($email: String!, $currentPassword: String!, $newPassword: String!) {
    changePassword(email: $email, currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

export const verifyPassword = gql`
  mutation ($email: String, $password: String!) {
    verifyPassword(email: $email, password: $password)
  }
`;

export const deleteUser = gql`
  mutation ($email: String) {
    deleteUser(email: $email)
  }
`;

export const addHost = gql`
  mutation ($machineId: String!, $hostName: String!, $email: String) {
    addHost(machineId: $machineId, hostName: $hostName, email: $email) {
      ...Host
    }
  }
  ${fragments.host}
`;

export const deleteHost = gql`
  mutation ($email: String, $hostId: String!) {
    deleteHost(email: $email, hostId: $hostId)
  }
`;

export const addHostToDeveloperKey = gql`
  mutation ($hostId: String!) {
    addHostToDeveloperKey(hostId: $hostId) {
      ...DeveloperKey
    }
  }
  ${fragments.developerKey}
`;

export const deleteHostFromDeveloperKey = gql`
  mutation ($hostId: String!) {
    deleteHostFromDeveloperKey(hostId: $hostId) {
      ...DeveloperKey
    }
  }
  ${fragments.developerKey}
`;

export const addUsernameToBlacklist = gql`
  mutation ($username: String!) {
    addUsernameToBlacklist(username: $username) {
      ...BlacklistedUsername
    }
  }
  ${fragments.blacklistedUsername}
`;

export const deleteUsernameFromBlacklist = gql`
  mutation ($id: String!) {
    deleteUsernameFromBlacklist(id: $id)
  }
`;

export const createGroup = gql`
  mutation ($email: String, $name: String!, $channelName: String!) {
    createGroup(email: $email, name: $name, channelName: $channelName) {
      ...Group
    }
  }
  ${fragments.group}
`;

export const deleteGroup = gql`
  mutation ($groupId: String!) {
    deleteGroup(groupId: $groupId)
  }
`;

export const renameGroup = gql`
  mutation ($groupId: String!, $name: String!) {
    renameGroup(groupId: $groupId, name: $name) {
      ...Group
    }
  }
  ${fragments.group}
`;

export const leaveGroup = gql`
  mutation ($groupId: String!) {
    leaveGroup(groupId: $groupId)
  }
`;

export const addUserToGroup = gql`
  mutation ($username: String, $email: String, $groupId: String!) {
    addUserToGroup(username: $username, email: $email, groupId: $groupId)
  }
`;

export const deleteUserFromGroup = gql`
  mutation ($username: String!, $groupId: String!) {
    deleteUserFromGroup(username: $username, groupId: $groupId)
  }
`;

export const inviteUserToGroupViaEmail = gql`
  mutation ($value: String!, $groupId: String!) {
    inviteUserToGroupViaEmail(value: $value, groupId: $groupId)
  }
`;

export const inviteUserToGroupViaUsername = gql`
  mutation ($value: String!, $groupId: String!) {
    inviteUserToGroupViaUsername(value: $value, groupId: $groupId)
  }
`;

export const cancelGroupSubscription = gql`
  mutation ($groupId: String!) {
    cancelGroupSubscription(groupId: $groupId) {
      ...Group
    }
  }
  ${fragments.group}
`;

export const updateGroupSubscription = gql`
  mutation ($groupId: String!, $priceId: String, $promotionCodeId: String) {
    updateGroupSubscription(groupId: $groupId, priceId: $priceId, promotionCodeId: $promotionCodeId) {
      ...Group
    }
  }
  ${fragments.group}
`;

export const updateTaxId = gql`
  mutation ($value: String!) {
    updateTaxId(value: $value)
  }
`;
