import { Formik, FormikHelpers } from 'formik';
import styles from './AddOrInviteMember.module.scss';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import logger from '../../../../utils/logger/logger';
import { addUserToGroup, inviteUserToGroupViaEmail } from '../../../../graphql/mutations';
import { Store } from 'react-notifications-component';
import {
  baseErrorNotification,
  baseInfoNotification,
  baseSuccessNotification,
} from '../../../../utils/nitificationUtils';
import { Group } from '../../../../types/types';
import { verifyTechUsername } from '../../../../services/user.service';
import { userExists } from '../../../../graphql/queries';

interface Values {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, memberUsernames: string[], checkEmail: boolean): any | (() => any) => {
  return checkEmail
    ? Yup.object().shape({
        name: Yup.string()
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .email(t('VALIDATION.EMAIL_VALID'))
          .test(
            'name-test',
            t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.USERNAME_IN_GROUP'),
            (value) => !value || !memberUsernames.includes(value.trim()),
          )
          .required(t('VALIDATION.REQUIRED')),
      })
    : Yup.object().shape({
        name: Yup.string()
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .test(
            'name-test',
            t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.USERNAME_IN_GROUP'),
            (value) => !value || !memberUsernames.includes(value.trim()),
          )
          .required(t('VALIDATION.REQUIRED')),
      });
};

interface Props {
  onHide: () => void;
  group: Group;
  onAdd: () => void;
}

/**
 * Shows form to invite or add a member to a group.
 * Checks if username or email exists in the system. If so then asks user to confirm adding.
 * Otherwise suggests to invite via email.
 */
export default function AddOrInviteMember({ onHide, group, onAdd }: Props): JSX.Element {
  const { t } = useTranslation();
  const [checkUserByEmail] = useLazyQuery<{ userExists: boolean }>(userExists);
  const [doAdd] = useMutation<{ addUserToGroup: boolean }>(addUserToGroup);
  const [doInviteViaEmail] = useMutation<{ inviteUserToGroupViaEmail: boolean }>(inviteUserToGroupViaEmail);
  const members = useMemo(() => group.members || [], [group.members]);
  const memberUsernames = useMemo(() => members.map((m) => m.techUsername) || [], [members]);

  const [tryInvite, setTryInvite] = useState(false);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showInfoNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseInfoNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string, withMsg: boolean) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title: withMsg ? title : null,
        message: withMsg ? t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE') : title,
      }),
    [t],
  );

  const clearState = useCallback(() => {
    setTryInvite(false);
  }, []);

  const add = useCallback(
    (addViaUsername: boolean, { name }: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      const variables = {
        username: addViaUsername ? name.trim() : null,
        email: !addViaUsername ? name.trim() : null,
        groupId: group.id,
      };
      doAdd({ variables })
        .then((result) => {
          if (result.data?.addUserToGroup) {
            showSuccessNotification(t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.SUCCESS', { name: name.trim() }));
            clearState();
            onAdd();
          } else {
            setSubmitting(false);
            clearState();
            showInfoNotification(t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.USER_IN_GROUP'));
            logger.error(`Not added group: ${name.trim()}`);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          clearState();
          showErrorNotification(t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.ERROR', { name: name.trim() }), true);
          logger.error(`Error adding group ${name.trim()}: ${error}`);
        });
    },
    [clearState, doAdd, group.id, onAdd, showErrorNotification, showInfoNotification, showSuccessNotification, t],
  );

  const verifyByEmail = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      // checks if the username exists in the system or not
      checkUserByEmail({ variables: { email: values.name } })
        .then((result) => {
          if (result?.data?.userExists) {
            // email exists in the system
            add(false, values, setSubmitting);
          } else {
            // email doesn't exist in the system
            setTryInvite(true);
            setSubmitting(false);
          }
        })
        .catch(() => {
          showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
          setSubmitting(false);
        });
    },
    [add, checkUserByEmail, showErrorNotification, t],
  );

  const verifyByUsername = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      // checks if the username exists in the system or not
      verifyTechUsername(values.name)
        .then((result: boolean) => {
          if (result) {
            // username doesn't exist in the system
            verifyByEmail(values, setSubmitting);
          } else {
            // username exists in the system
            add(true, values, setSubmitting);
            setSubmitting(false);
          }
        })
        .catch(() => {
          showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
          setSubmitting(false);
        });
    },
    [add, showErrorNotification, t, verifyByEmail],
  );

  const invite = useCallback(
    ({ name }: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitting(true);

      doInviteViaEmail({ variables: { value: name.trim(), groupId: group.id } })
        .then((result) => {
          if (result.data?.inviteUserToGroupViaEmail) {
            clearState();
            showSuccessNotification(
              t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.SUCCESS_INVITE', {
                name: name.trim(),
              }),
            );
            onHide();
          } else {
            setSubmitting(false);
            showErrorNotification(
              t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.ERROR_INVITE', {
                name: name.trim(),
              }),
              true,
            );
            logger.error(`Not invited to group: ${name.trim()}`);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          clearState();
          showErrorNotification(
            t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.ERROR_INVITE', {
              name: name.trim(),
            }),
            true,
          );
          logger.error(`Error inviting to group ${name.trim()}: ${error}`);
        });
    },
    [doInviteViaEmail, group.id, clearState, showSuccessNotification, t, onHide, showErrorNotification],
  );

  const handleSubmit = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitting(true);

      if (tryInvite) {
        invite(values, setSubmitting);
      } else {
        verifyByUsername(values, setSubmitting);
      }
    },
    [tryInvite, invite, verifyByUsername],
  );

  return (
    <Modal
      show={true}
      onHide={() => {
        clearState();
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema(t, memberUsernames, tryInvite)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{ name: '' }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Form.Group className="position-relative" controlId="formGroupName">
                <Form.Label>{t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.USERNAME_OR_EMAIL')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  autoFocus={true}
                  onChange={(event) => {
                    // clearState();
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              {tryInvite && (
                <div className={styles.tryInvite}>{t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.TRY_INVITE')}</div>
              )}

              <div className={styles.ctas}>
                <Button variant="primary" type="submit" disabled={isSubmitting} className={styles.cta}>
                  {tryInvite
                    ? t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.CTA_INVITE')
                    : t('PRIVATE.COMMON.ADD_OR_INVITE_MEMBER.CTA_ADD')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
