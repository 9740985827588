import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AdminUser.module.scss';
import * as Yup from 'yup';
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  CompanyType,
  CountryCode,
  Group,
  Host,
  LanguageCode,
  SecurityRole,
  User,
  UserPosition,
} from '../../../../types/types.d';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createUser, updateUser } from '../../../../graphql/mutations';
import logger from '../../../../utils/logger/logger';
import arrowBack from '../../../../assets/img/arrow-back.svg';
import { ADMIN_USERS, HOST, NEW } from '../../../../utils/routingUtils';
import { getHosts, getUser } from '../../../../graphql/queries';
import { HostLocationState } from '../../host/Host';
import { linkhqFileName } from '../../../../utils/businessUtils';
import { downloadFile } from '../../../../utils/windowUtils';
import { CREATED_BY_ADMIN, UserInput } from '../../../../types/inputTypes.d';
import DeleteHost from '../../common/deleteHost';
import { Store } from 'react-notifications-component';
import { baseErrorNotification, baseSuccessNotification } from '../../../../utils/nitificationUtils';
import { DEFAULT_MAX_DEV_HOSTS_COUNT } from '../../../../utils/userUtils';
import { useSelector } from 'react-redux';
import { BlacklistSliceState } from '../../../../slices/blacklistSlice';
import { verifyTechUsername } from '../../../../services/user.service';
import classNames from 'classnames';
import CompanyTypeSelector from '../../common/companyTypeSelector';
import UserPositionSelector from '../../common/userPositionSelector';
import { Loading } from '../../../utils/Loading';

const validationSchema = (
  t: TFunction,
  validateOnSubmit: boolean,
  passwordRequired: boolean,
  blacklistedUsernames: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string().email(t('VALIDATION.EMAIL_VALID')).max(100, t('VALIDATION.EMAIL_MAX_LENGTH')).optional(),
    password: passwordRequired
      ? Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .optional(),
    firstName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.FIRST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.FIRST_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.FIRST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.FIRST_NAME_MAX_LENGTH'))
          .optional(),
    lastName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.LAST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.LAST_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.LAST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.LAST_NAME_MAX_LENGTH'))
          .optional(),
    techUsername: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.TECH_USERNAME_MIN_LENGTH'))
          .max(30, t('VALIDATION.TECH_USERNAME_MAX_LENGTH'))
          .test(
            'name-test',
            t('VALIDATION.TECH_USERNAME_NOT_ALLOWED'),
            (value) => !value || !blacklistedUsernames.includes(value.trim()),
          )
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.TECH_USERNAME_MIN_LENGTH'))
          .max(30, t('VALIDATION.TECH_USERNAME_MAX_LENGTH'))
          .test(
            'name-test',
            t('VALIDATION.TECH_USERNAME_NOT_ALLOWED'),
            (value) => !value || !blacklistedUsernames.includes(value.trim()),
          )
          .optional(),
    companyName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.COMPANY_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.COMPANY_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.COMPANY_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.COMPANY_NAME_MAX_LENGTH'))
          .optional(),
    maxDevHostsCount: validateOnSubmit
      ? Yup.string()
          .test('maxDevHostsCount-test', (value) => !!value && parseInt(value) >= 0)
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string().optional(),
  });
};

/**
 * Handles existing or new user
 */
export default function AdminUser(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { usernames } = useSelector((state: { blacklist: BlacklistSliceState }) => state.blacklist);
  const blacklistedUsernames = useMemo(() => usernames.map((u) => u.username), [usernames]);
  const [fetchUser, { data: userData }] = useLazyQuery<{ getUser?: User }>(getUser);

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [anotherUserName, setAnotherUserName] = useState('');

  const [doCreateUser] = useMutation<{ createUser?: User }>(createUser);
  const [doUpdateUser] = useMutation<{ updateUser?: User }>(updateUser);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string, withMsg: boolean) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title: withMsg ? title : null,
        message: withMsg ? t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE') : title,
      }),
    [t],
  );

  // define if it is for new user
  const newUser = useMemo(() => {
    if (!location.pathname) return;
    const paths = location.pathname.split('/') || [];
    return paths.length > 2 ? paths[2] === NEW : false;
  }, [location.pathname]);

  // define if it is for existing user then fetch it
  const anotherUserId = useMemo(() => {
    if (!location.pathname) return;
    const paths = location.pathname.split('/') || [];
    return paths.length > 2 ? paths[2] : null;
  }, [location.pathname]);
  useEffect(() => {
    if (anotherUserId) {
      fetchUser({ variables: { id: anotherUserId } }).then((result) => result.data?.getUser);
    }
  }, [anotherUserId, fetchUser]);
  const anotherUser = useMemo(() => {
    if (userData?.getUser) return userData?.getUser;
  }, [userData?.getUser]);

  const definedUser = useMemo(() => {
    if (anotherUser) {
      const splitFullName = anotherUser.fullName?.split(' ') || [];
      const userFirstName = anotherUser.firstName || (splitFullName.length > 0 ? splitFullName[0] : '');
      const userLastName = anotherUser.lastName || (splitFullName.length > 1 ? splitFullName[1] : '');

      // the logged in user wants to update another user
      return {
        email: anotherUser.email,
        firstName: userFirstName,
        lastName: userLastName,
        techUsername: anotherUser.techUsername,
        role: anotherUser.role,
        languageIso6391: anotherUser.languageIso6391,
        countryIsoAlpha2: anotherUser.countryIsoAlpha2,
        position: anotherUser.position || UserPosition.DEVELOPER,
        companyName: anotherUser.companyName,
        companyType: [CompanyType.OEM, CompanyType.TIER_1_2, CompanyType.ACADEMIA, CompanyType.OTHER].includes(
          anotherUser.companyType,
        )
          ? anotherUser.companyType
          : CompanyType.OTHER,
        supportWays: anotherUser.supportWays,
        otherSupportWays: anotherUser.otherSupportWays,
        twoFAEnabled: anotherUser.twoFAEnabled,
        productUpdatesEnabled: anotherUser.productUpdatesEnabled,
        maxDevHostsCount: anotherUser.maxDevHostsCount,
      } as UserInput;
    } else if (newUser) {
      // initialise a new user
      return {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        techUsername: '',
        role: SecurityRole.USER,
        languageIso6391: LanguageCode.EN,
        countryIsoAlpha2: CountryCode.DE,
        position: UserPosition.DEVELOPER,
        companyName: '',
        companyType: CompanyType.OEM,
        supportWays: '',
        twoFAEnabled: false,
        productUpdatesEnabled: true,
        maxDevHostsCount: DEFAULT_MAX_DEV_HOSTS_COUNT,
        createdByAdmin: true,
      } as UserInput;
    }

    return undefined;
  }, [anotherUser, newUser]);

  useEffect(() => {
    if (!anotherUser) return;
    const name =
      anotherUser.firstName || anotherUser.lastName
        ? `${anotherUser.firstName} ${anotherUser.lastName}`
        : anotherUser.fullName;
    setAnotherUserName(name || '');
  }, [anotherUser]);

  const upsertUser = useCallback(
    (values: UserInput, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      if (anotherUser) {
        doUpdateUser({ variables: { input: values } })
          .then((result) => {
            const updatedUser = result?.data?.updateUser;
            if (updatedUser) {
              showSuccessNotification(t('PRIVATE.ADMIN.USERS.ACCOUNT.ACCOUNT_UPDATED'));
              if (anotherUser) setAnotherUserName(`${updatedUser.firstName} ${updatedUser.lastName}`);
            } else {
              logger.error('No result for user update from AdminUser');
              showErrorNotification(t('PRIVATE.ADMIN.USERS.ACCOUNT.ACCOUNT_NOT_UPDATED'), true);
            }
          })
          .catch((error) => {
            logger.error(`User update error from Account: ${error}`);
            showErrorNotification(t('PRIVATE.ADMIN.USERS.ACCOUNT.ACCOUNT_NOT_UPDATED'), true);
          })
          .finally(() => setSubmitting(false));
      } else {
        doCreateUser({
          variables: {
            input: values,
          },
        })
          .then((result) => {
            const createdUser = result?.data?.createUser;
            if (createdUser) {
              showSuccessNotification(
                t('PRIVATE.ADMIN.USERS.ACCOUNT.ACCOUNT_CREATED', { name: `${values.firstName} ${values.lastName}` }),
              );
              history.goBack();
            } else {
              logger.error('No result for user create from AdminUser');
              showErrorNotification(
                t('PRIVATE.ADMIN.USERS.ACCOUNT.ACCOUNT_NOT_CREATED', {
                  name: `${values.firstName} ${values.lastName}`,
                }),
                true,
              );
            }
          })
          .catch((error) => {
            logger.error(`User create error from Account: ${error}`);
            showErrorNotification(
              t('PRIVATE.ADMIN.USERS.ACCOUNT.ACCOUNT_NOT_CREATED', { name: `${values.firstName} ${values.lastName}` }),
              true,
            );
          })
          .finally(() => setSubmitting(false));
      }
    },
    [anotherUser, doCreateUser, doUpdateUser, history, showErrorNotification, showSuccessNotification, t],
  );

  const handleSubmit = useCallback(
    (values: UserInput, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      // verify username uniqueness: 1) for new user and 2) if username of an existing user has changed
      if (newUser || (!!definedUser && definedUser.techUsername !== values.techUsername)) {
        verifyTechUsername(values.techUsername)
          .then((result: boolean) => {
            if (result) {
              upsertUser(values, setSubmitting);
            } else {
              showErrorNotification(t('PUBLIC.AUTH.REG.TECH_USERNAME_EXISTS'), false);
              setSubmitting(false);
            }
          })
          .catch(() => {
            showErrorNotification(t('PUBLIC.AUTH.REG.TECH_USERNAME_EXISTS'), false);
            setSubmitting(false);
          });
      } else {
        upsertUser(values, setSubmitting);
      }
    },
    [newUser, definedUser, upsertUser, showErrorNotification, t],
  );

  const handleBack = useCallback(() => history.push(ADMIN_USERS), [history]);

  return (
    <>
      <Container>
        {definedUser ? (
          <>
            <header className="d-flex align-items-center mb-4">
              <img alt="back" className="mr-4 arrowback" src={arrowBack} onClick={handleBack} />
              {anotherUser ? (
                <>
                  <h1>{anotherUserName}</h1>
                </>
              ) : (
                <h1>{newUser ? 'New' : 'My'} Account</h1>
              )}
            </header>
            <Row>
              <Col>
                <div className="p-5 box">
                  <h4 className="mb-5">Profile</h4>
                  <Formik
                    validationSchema={validationSchema(t, submitTriggered, newUser || false, blacklistedUsernames)}
                    onSubmit={(values: UserInput, { setSubmitting }: FormikHelpers<UserInput>) =>
                      handleSubmit(values, setSubmitting)
                    }
                    initialValues={definedUser}
                  >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="position-relative">
                          <Form.Label>{t('PUBLIC.AUTH.EMAIL_ADDRESS')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder={t('PUBLIC.AUTH.EMAIL_ADDRESS_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            isInvalid={touched.email && !!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="position-relative">
                          <Form.Label>{t('PUBLIC.AUTH.PASSWORD')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="password"
                            name="password"
                            placeholder={t('PUBLIC.AUTH.PASSWORD_PLACEHOLDER')}
                            autoComplete="true"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password || ''}
                            isInvalid={touched.password && !!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>{t('PUBLIC.AUTH.FIRST_NAME')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="firstName"
                            placeholder={t('PUBLIC.AUTH.FIRST_NAME_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName || ''}
                            isInvalid={touched.firstName && !!errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>{t('PUBLIC.AUTH.LAST_NAME')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="lastName"
                            placeholder={t('PUBLIC.AUTH.LAST_NAME_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName || ''}
                            isInvalid={touched.lastName && !!errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>{t('PUBLIC.AUTH.TECH_USERNAME')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="techUsername"
                            placeholder={t('PUBLIC.AUTH.TECH_USERNAME_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.techUsername}
                            isInvalid={touched.techUsername && !!errors.techUsername}
                          />
                          <Form.Control.Feedback type="invalid">{errors.techUsername}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>{t('PRIVATE.PROFILE.ROLE')}</Form.Label>
                          <div className={classNames(styles.selector)}>
                            <UserPositionSelector
                              externallyManagedSelectedValues={false}
                              selectedValues={[values.position]}
                              onSelect={(value) => {
                                if (value.length === 1) {
                                  values.position = value[0];
                                }
                              }}
                              controlShouldRenderValue={true}
                              isMulti={false}
                              isSearchable={false}
                              closeMenuOnSelect={true}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>{t('PRIVATE.PROFILE.COMPANY_NAME')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="companyName"
                            placeholder={t('PRIVATE.PROFILE.COMPANY_NAME_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyName}
                            isInvalid={touched.companyName && !!errors.companyName}
                          />
                          <Form.Control.Feedback type="invalid">{errors.companyName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formGroupName">
                          <Form.Label>{t('PRIVATE.PROFILE.COMPANY_TYPE')}</Form.Label>
                          <div className={classNames(styles.selector)}>
                            <CompanyTypeSelector
                              externallyManagedSelectedValues={false}
                              selectedValues={[values.companyType]}
                              onSelect={(value) => {
                                if (value.length === 1) {
                                  values.companyType = value[0];
                                }
                              }}
                              controlShouldRenderValue={true}
                              isMulti={false}
                              isSearchable={false}
                              closeMenuOnSelect={true}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">{errors.companyType}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formGroupMaxDevHostsCount">
                          <Form.Label>{t('PRIVATE.ADMIN.USERS.ACCOUNT.MAX_DEV_HOSTS_COUNT')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="number"
                            name="maxDevHostsCount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.maxDevHostsCount}
                            isInvalid={touched.maxDevHostsCount && !!errors.maxDevHostsCount}
                          />
                          <Form.Control.Feedback type="invalid">{errors.maxDevHostsCount}</Form.Control.Feedback>
                        </Form.Group>

                        {anotherUser && (
                          <Form.Group controlId="formGroupRegIp">
                            <Form.Label>{t('PRIVATE.ADMIN.USERS.ACCOUNT.REG_IP')}</Form.Label>
                            <Form.Control
                              className="form-control"
                              type="string"
                              name="regIp"
                              value={
                                !!anotherUser.regIp?.length
                                  ? anotherUser.regIp
                                  : anotherUser.createdByAdmin
                                  ? CREATED_BY_ADMIN
                                  : ''
                              }
                              readOnly={true}
                            />
                          </Form.Group>
                        )}

                        <div className="mt-4 text-right">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => setSubmitTriggered(true)}
                          >
                            {t('PRIVATE.PROFILE.SAVE_CTA')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
              {anotherUser && (
                <Col sm="5">
                  <div className="box p-5">
                    <Hosts user={definedUser} />
                  </div>
                  <br />
                  <div className="box p-5">
                    <Groups user={anotherUser} />
                  </div>
                </Col>
              )}
            </Row>
          </>
        ) : (
          <div className={styles.loadingWrapper}>
            <Loading />
          </div>
        )}
      </Container>
    </>
  );
}

interface HostsProps {
  user: UserInput;
}

const Hosts = ({ user }: HostsProps) => {
  const [fetchHosts, { loading, data }] = useLazyQuery<{ getHosts?: Host[] }>(getHosts);
  const [hostToDelete, setHostToDelete] = useState<Host | null>(null);

  useEffect(() => {
    fetchHosts({ variables: { email: user.email } });
  }, [fetchHosts, user.email]);

  const handleHostDelete = useCallback(() => {
    fetchHosts({ variables: { email: user.email } });
    // close modal
    setHostToDelete(null);
  }, [fetchHosts, user.email]);

  if (loading) return <></>;
  const hosts = data?.getHosts ? data?.getHosts : [];

  return (
    <>
      {hostToDelete && (
        <DeleteHost
          show={!!hostToDelete}
          onHide={() => setHostToDelete(null)}
          onDelete={handleHostDelete}
          host={hostToDelete}
          userEmail={user.email}
        />
      )}
      <h4>Registered hosts ({hosts?.length})</h4>

      <div className="mt-5">
        {hosts.map((host, i) => (
          <div key={i} className="data-row">
            <div>
              <i className="host-status" />
            </div>
            <div className="flex-grow-1">{host.hostName}</div>
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="link" className="row-functions">
                  <svg width="16" height="4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2 0a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4z"
                      fill="#7F84B0"
                    />
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight>
                  <Dropdown.Item onClick={() => downloadFile(linkhqFileName, host.hostQualifier)}>
                    Download &quot;{linkhqFileName}&quot; file
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setHostToDelete(host)}>Delete host</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>

      <div className="text-right mt-5">
        <Link
          className="btn btn-outline-primary"
          to={{
            pathname: HOST,
            state: { user: user } as HostLocationState,
          }}
        >
          Add host
        </Link>
      </div>
    </>
  );
};

interface GroupsProps {
  user: User;
}

const Groups = ({ user }: GroupsProps) => {
  const groups: Group[] = useMemo(() => user.groups || [], [user.groups]);
  return (
    <>
      <h4>Groups ({groups.length})</h4>

      <div className="mt-5">
        {groups.map((group, i) => (
          <div key={i} className="data-row">
            <div className="flex-grow-1">{group.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};
