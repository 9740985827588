import logger from './logger/logger';

export const openInNewTab = (url: string): Window | null => {
  return window.open(url, '_blank', 'noopener,noreferrer');
};

export const redirectToExternalUrl = (url: string): void => {
  window.location.href = url;
};

export const getDomain = (): string => {
  return window.location.origin;
};

/**
 * Creates a file on the fly and downloads it.
 */
export const downloadFile = (filename: string, text: string): void => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const copyToClipboard = (content: string): void => {
  navigator.clipboard
    .writeText(content)
    .then(() => logger.log(`'${content}' copied to clipboard`))
    .catch((error) => logger.error(`Failed to copy to clipboard: ${error}`));
};
