import { CompanyType, SecurityRole, User, UserPosition } from '../types/types.d';
import { TFunction } from 'i18next';

export const DEFAULT_MAX_DEV_HOSTS_COUNT = 2;

/**
 * NOTE: assuming that user has only one role
 *
 * @return true if user has the specified role, false otherwise
 */
export const userHasAnyRole = (user: User | null | undefined, roles: SecurityRole[]): boolean =>
  user != undefined && user && roles?.find((obj) => obj.toString() === user.role) != undefined;

export const userHasUserRole = (user?: User): boolean => userHasAnyRole(user, [SecurityRole.USER]);
export const userHasAdminRole = (user?: User): boolean => userHasAnyRole(user, [SecurityRole.ADMIN]);
export const userHasUserOrAdminRoles = (user?: User): boolean =>
  userHasAnyRole(user, [SecurityRole.USER, SecurityRole.ADMIN]);

/**
 * @return first full name taken either from "fullname" or "first" and "last" names
 */
export const getFullName = (user: User): string => {
  const fullName = user?.fullName?.trim();
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim();
  if (!firstName && !lastName && !fullName) {
    return '';
  }
  return firstName && lastName ? `${firstName} ${lastName}` : fullName || '';
};

/**
 * @return first 2 letters of the user's first and last names
 */
export const getFullNameAbbr = (user: User | null): string => {
  const fullName = user?.fullName?.trim();
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim();
  if (!firstName && !lastName && !fullName) {
    return '--';
  }
  let abbr = '';
  const arr: string[] | undefined = firstName && lastName ? [firstName, lastName] : fullName?.toString().split(' ');
  if (!arr) {
    return '--';
  }

  abbr = arr[0][0].toUpperCase();
  if (arr.length > 1) {
    abbr += arr[arr.length - 1][0].toUpperCase();
  }
  return abbr;
};

/**
 * @return true if the user completed all profiling steps, false otherwise
 */
export const userProfilingCompleted = (user: User): boolean => {
  return (
    user.position !== UserPosition.NONE &&
    !!user.companyName.length &&
    user.companyType !== CompanyType.NONE &&
    !!user.supportWays?.length
  );
};

export const getLocalisedCompanyType = (value: CompanyType, t: TFunction): string => {
  switch (value) {
    case CompanyType.OEM:
      return t('COMPANY_TYPE.OEM');
    case CompanyType.TIER_1_2:
      return t('COMPANY_TYPE.TIER_1_2');
    case CompanyType.ACADEMIA:
      return t('COMPANY_TYPE.ACADEMIA');
    case CompanyType.OTHER:
    default:
      return t('COMPANY_TYPE.OTHER');
  }
};
