import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AdminGroup.module.scss';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftIcon } from '../../../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../../../assets/img/right.svg';
import { ReactComponent as SortIcon } from '../../../../../assets/img/sort.svg';
import { ReactComponent as OptionsIcon } from '../../../../../assets/img/options.svg';
import classNames from 'classnames';
import { OrderBy, Sorting } from '../../../../../types/inputTypes.d';
import { Group as G, GroupData, PspSubscription, tablePageSize, User } from '../../../../../types/types.d';
import { primary } from '../../../../../utils/styleUtils';
import { useSelector } from 'react-redux';
import { AuthSliceState } from '../../../../../slices/authSlice';
import orderBy from 'lodash/orderBy';
import lowerFirst from 'lodash/lowerFirst';
import { useLazyQuery } from '@apollo/client';
import { getGroupData } from '../../../../../graphql/queries';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import arrowBack from '../../../../../assets/img/arrow-back.svg';
import { ADMIN_GROUPS } from '../../../../../utils/routingUtils';
import { getFullName } from '../../../../../utils/userUtils';
import DeleteMember from './deleteMember/DeleteMember';
import { Loading } from '../../../../utils/Loading';
import PlanAndBilling from './planAndBilling';
import { capitalizeFirstLetter } from '../../../../../utils/stringUtils';

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting;
  usernameFilter: string;
}

/**
 * AdminGroup details page
 */
export default function AdminGroup(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const groupId = useMemo(() => {
    if (!location.pathname) return;
    const paths = location.pathname.split('/') || [];
    return paths.length > 2 ? paths[2] : null;
  }, [location.pathname]);

  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const [fetchGroupData] = useLazyQuery<{ getGroupData?: GroupData }>(getGroupData);

  const [memberToDelete, setMemberToDelete] = useState<User | null>(null);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.FirstNameAsc } as Sorting,
    usernameFilter: '',
  });

  const [group, setGroup] = useState<G | null>(null);
  const [subscriptions, setSubscriptions] = useState<PspSubscription[]>([]);
  useEffect(() => {
    if (groupId) {
      fetchGroupData({ variables: { groupId } }).then((result) => {
        const resultData = result.data?.getGroupData;
        if (resultData) {
          setGroup(resultData.group);
          setSubscriptions(resultData.subscriptions || []);
        }
      });
    }
  }, [fetchGroupData, groupId]);

  const allMembers = useMemo(() => group?.members || [], [group?.members]);
  const [members, setMembers] = useState<User[]>([]);
  useEffect(() => {
    // sort and filter
    const orderByValue = tableState.sorting.orderBy;
    const sortField = orderByValue.toLowerCase().endsWith('asc')
      ? orderByValue.substring(0, orderByValue.length - 3)
      : orderByValue.substring(0, orderByValue.length - 4);
    const asc = orderByValue.toLowerCase().endsWith('asc');

    const start = tableState.page * tableState.perPage;
    const end = start + tableState.perPage > allMembers.length ? allMembers.length : start + tableState.perPage;
    setMembers(
      orderBy(allMembers.slice(start, end), [lowerFirst(sortField)], [asc ? 'asc' : 'desc']).filter((m) =>
        m.techUsername.includes(tableState.usernameFilter),
      ),
    );
  }, [allMembers, tableState.page, tableState.perPage, tableState.sorting.orderBy, tableState.usernameFilter]);

  const totalCount = useMemo(() => allMembers.length, [allMembers.length]);
  const pageCount = useMemo(() => Math.ceil(totalCount / tableState.perPage), [tableState.perPage, totalCount]);
  const firstPageShown = useMemo(() => tableState.page === 0, [tableState.page]);
  const lastPageShown = useMemo(() => tableState.page === pageCount - 1, [pageCount, tableState.page]);

  const handlePreviousPage = useCallback(() => {
    if (!firstPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [firstPageShown]);
  const handleNextPage = useCallback(() => {
    if (!lastPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [lastPageShown]);

  const handleSorting = useCallback((orderBy: OrderBy) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setTableState((prevState) => ({
      ...prevState,
      usernameFilter: !!value?.length ? value : '',
    }));
  }, []);

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          { order: OrderBy.FirstNameAsc, title: t('PRIVATE.ADMIN.GROUPS.GROUP.SORTING.FIRST_NAME_ASC') },
          { order: OrderBy.FirstNameDesc, title: t('PRIVATE.ADMIN.GROUPS.GROUP.SORTING.FIRST_NAME_DESC') },
          { order: OrderBy.LastNameAsc, title: t('PRIVATE.ADMIN.GROUPS.GROUP.SORTING.LAST_NAME_ASC') },
          { order: OrderBy.LastNameDesc, title: t('PRIVATE.ADMIN.GROUPS.GROUP.SORTING.LAST_NAME_DESC') },
          { order: OrderBy.TechUsernameAsc, title: t('PRIVATE.ADMIN.GROUPS.GROUP.SORTING.USERNAME_ASC') },
          { order: OrderBy.TechUsernameDesc, title: t('PRIVATE.ADMIN.GROUPS.GROUP.SORTING.USERNAME_DESC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === tableState.sorting?.orderBy ? primary : '' }}
            onClick={() => handleSorting(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  const handleBack = useCallback(() => history.push(ADMIN_GROUPS), [history]);

  const handleMembersChange = useCallback(() => {
    setTableState((prevState) => ({
      ...prevState,
      page: 0,
    }));

    if (groupId) {
      fetchGroupData({ variables: { groupId } }).then((result) => {
        const resultData = result.data?.getGroupData;
        if (resultData) {
          setGroup(resultData.group);
          setSubscriptions(resultData.subscriptions || []);
        }
      });
    }

    // close modal
    setMemberToDelete(null);
  }, [fetchGroupData, groupId]);

  if (!groupId) {
    return <Redirect to={ADMIN_GROUPS} />;
  }

  return (
    <>
      {memberToDelete && group && (
        <DeleteMember
          show={!!memberToDelete}
          onHide={() => setMemberToDelete(null)}
          member={memberToDelete}
          group={group}
          onDelete={handleMembersChange}
        />
      )}
      <Container>
        {group ? (
          <>
            <div className={styles.header}>
              <div className={styles.start}>
                <img alt="back" className="mr-4 arrowback" src={arrowBack} onClick={handleBack} />
                <div className={styles.title}>{capitalizeFirstLetter(group?.name)}</div>
              </div>
            </div>
            <Row>
              <Col>
                <div className={classNames('box', styles.members)}>
                  <div className={styles.header}>{t('PRIVATE.GROUP.MEMBERS')}</div>
                  <div className={styles.toolbar}>
                    <div className={styles.sortAndSearch}>
                      {buttonSorting}
                      <input
                        className={styles.search}
                        placeholder={t('PRIVATE.ADMIN.GROUPS.GROUP.SEARCH_BY_EMAIL')}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className={styles.paginationInfo}>
                      {totalCount > 0 &&
                        t('PRIVATE.ADMIN.GROUPS.GROUP.PAGINATION_INFO', {
                          start: tableState.page * tableState.perPage + 1,
                          end:
                            totalCount < (tableState.page + 1) * tableState.perPage
                              ? totalCount
                              : (tableState.page + 1) * tableState.perPage,
                          total: totalCount,
                        })}
                      {pageCount > 1 && (
                        <div className={styles.previousNext}>
                          <div
                            className={styles.previous}
                            style={{
                              opacity: firstPageShown ? 0.2 : 0.8,
                              cursor: firstPageShown ? 'default' : 'pointer',
                            }}
                            onClick={() => handlePreviousPage()}
                          >
                            <LeftIcon />
                          </div>
                          <div
                            className={styles.next}
                            style={{
                              opacity: lastPageShown ? 0.2 : 0.8,
                              cursor: lastPageShown ? 'default' : 'pointer',
                            }}
                            onClick={() => handleNextPage()}
                          >
                            <RightIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {totalCount === 0 && (
                      <div className={styles.noResults}>{t('PRIVATE.ADMIN.GROUPS.GROUP.NO_MEMBERS_FOUND')}</div>
                    )}
                    {totalCount > 0 && (
                      <>
                        <div className={classNames('data-row', styles.tableHeader)}>
                          <div className={styles.name}>{t('PRIVATE.ADMIN.GROUPS.GROUP.NAME')}</div>
                          <div className={styles.username}>{t('PRIVATE.ADMIN.GROUPS.GROUP.USERNAME')}</div>
                          <div className={styles.role}>{t('PRIVATE.ADMIN.GROUPS.GROUP.ROLE')}</div>
                          <div className={styles.placeholderOptions} />
                        </div>
                        {members.map((item, index) => {
                          const owner = item.id === user?.id;
                          return (
                            <div key={index} className={classNames('data-row', styles.row)}>
                              <div className={styles.name}>{getFullName(item)}</div>
                              <div className={styles.username}>{item.techUsername}</div>
                              <div className={styles.role}>
                                {owner ? t('GROUP_MEMBER_ROLE.OWNER') : t('GROUP_MEMBER_ROLE.DEVELOPER')}
                              </div>
                              {owner || group?.membersCount === 1 ? (
                                <div className={styles.placeholderOptions} />
                              ) : (
                                <div onClick={(e) => e.stopPropagation()}>
                                  <Dropdown>
                                    <Dropdown.Toggle variant="link" className="row-functions">
                                      <OptionsIcon />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu alignRight>
                                      <Dropdown.Item onClick={() => setMemberToDelete(item)}>
                                        {t('PRIVATE.ADMIN.GROUPS.GROUP.DELETE_MEMBER.TITLE')}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </Col>

              <Col sm="3">
                <PlanAndBilling subscriptions={subscriptions} />
              </Col>
            </Row>
          </>
        ) : (
          <div className={styles.loadingWrapper}>
            <Loading />
          </div>
        )}
      </Container>
    </>
  );
}
