import styles from './InvoicesHistory.module.scss';
import React, { useMemo } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { PspInvoice } from '../../../../types/types';
import { getInvoices } from '../../../../graphql/queries';
import classNames from 'classnames';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/dateTimeUtils';
import { ReactComponent as OptionsIcon } from '../../../../assets/img/options.svg';
import { openInNewTab } from '../../../../utils/windowUtils';

interface Props {
  onHide: () => void;
  subscriptionId: string;
}

export default function InvoicesHistory({ onHide, subscriptionId }: Props): JSX.Element {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ getInvoices: PspInvoice[] }>(getInvoices, {
    variables: { subscriptionId },
  });
  const invoices = useMemo(() => data?.getInvoices || [], [data?.getInvoices]);

  return !loading ? (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.COMMON.INVOICES_HISTORY.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        {invoices.map((i) => (
          <div key={i.created} className={styles.invoice}>
            <div>{moment.unix(parseInt(i.created)).format(DATE_FORMAT)}</div>
            <div>{i.number}</div>
            {i.hostedUrl && i.pdfUrl && (
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                    <OptionsIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight={true}>
                    {i.hostedUrl && (
                      <Dropdown.Item onClick={() => openInNewTab(i.hostedUrl || '')}>
                        {t('PRIVATE.COMMON.INVOICES_HISTORY.VIEW')}
                      </Dropdown.Item>
                    )}
                    {i.pdfUrl && (
                      <Dropdown.Item onClick={() => openInNewTab(i.pdfUrl || '')}>
                        {t('PRIVATE.COMMON.INVOICES_HISTORY.DOWNLOAD')}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  ) : (
    <></>
  );
}
