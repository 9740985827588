import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { User } from '../../../../types/types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import logger from '../../../../utils/logger/logger';
import { requestEmailChange, verifyPassword } from '../../../../graphql/mutations';
import { Store } from 'react-notifications-component';
import { baseErrorNotification, baseInfoNotification } from '../../../../utils/nitificationUtils';

interface Values {
  email: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean, email: string): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .test('email-test', t('PRIVATE.PROFILE.CHANGE_EMAIL.EMAIL_IS_SAME'), (value) => value !== email)
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string().email(t('VALIDATION.EMAIL_VALID')).max(100, t('VALIDATION.EMAIL_MAX_LENGTH')).optional(),
    password: validateOnSubmit
      ? Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .optional(),
  });
};

interface ChangeEmailProps {
  show: boolean;
  onHide: () => void;
  user: User | null;
}

/**
 * Shows form to provide a new email.
 */
export default function ChangeEmail({ show, onHide, user }: ChangeEmailProps): JSX.Element {
  const { t } = useTranslation();
  const [doRequestEmailChange] = useMutation<{ requestEmailChange: boolean }>(requestEmailChange);
  const [doVerifyPassword] = useMutation<{ verifyPassword: boolean }>(verifyPassword);

  // const [updateFailed, setUpdateFailed] = useState(false);
  // const [updateSucceeded, setUpdateSucceeded] = useState(false);
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseInfoNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const handleModalClosing = useCallback(() => {
    // setUpdateSucceeded(false);
    // setUpdateFailed(false);
    onHide();
  }, [onHide]);

  const changeEmail = useCallback(
    (email: string, setSubmitting: (isSubmitting: boolean) => void) => {
      doRequestEmailChange({
        variables: {
          email: user?.email,
          newEmail: email,
        },
      })
        .then((result) => {
          if (result?.data?.requestEmailChange) {
            showSuccessNotification(t('PRIVATE.PROFILE.CHANGE_EMAIL.EMAIL_CHANGE_INITIATED'));
            onHide();
            // setUpdateSucceeded(true);
          } else {
            showErrorNotification(t('PRIVATE.PROFILE.CHANGE_EMAIL.EMAIL_NOT_CHANGED'));
            logger.error('No result for email update');
            // setUpdateFailed(true);
          }
        })
        .catch((error) => {
          showErrorNotification(t('PRIVATE.PROFILE.CHANGE_EMAIL.EMAIL_NOT_CHANGED'));
          logger.error(`Email update error: ${error}`);
          // setUpdateFailed(true);
        })
        .finally(() => setSubmitting(false));
    },
    [doRequestEmailChange, onHide, showErrorNotification, showSuccessNotification, t, user?.email],
  );

  const handleSubmit = useCallback(
    ({ email, password }: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitting(true);
      // setUpdateFailed(false);
      // setUpdateSucceeded(false);

      doVerifyPassword({ variables: { password } })
        .then((result) => {
          if (result?.data?.verifyPassword) {
            changeEmail(email, setSubmitting);
          } else {
            showErrorNotification(t('PRIVATE.PROFILE.CHANGE_EMAIL.PASSWORD_NOT_VALID'));
            logger.error('No result for email update');
            setSubmitting(false);
          }
        })
        .catch((error) => {
          showErrorNotification(t('PRIVATE.PROFILE.CHANGE_EMAIL.EMAIL_NOT_CHANGED'));
          logger.error(`Email update error: ${error}`);
          setSubmitting(false);
        });
    },
    [changeEmail, doVerifyPassword, showErrorNotification, t],
  );

  if (!user) {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Email Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sorry, an error happened. Please contact our customer support.</Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleModalClosing}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.PROFILE.CHANGE_EMAIL_HEADING')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema(t, submitTriggered, user.email)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            email: user.email,
            password: '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Form.Group className="position-relative" controlId="formGroupEmail">
                <Form.Label>{t('PUBLIC.AUTH.NEW_EMAIL_ADDRESS')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="email"
                  autoFocus={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword" className="position-relative">
                <Form.Label>{t('PUBLIC.AUTH.CURRENT_PASSWORD')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder={t('PUBLIC.AUTH.PASSWORD_PLACEHOLDER')}
                    autoComplete="true"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <div className="mt-4 text-right">
                {/*{updateFailed ? (*/}
                {/*  <Alert variant="danger" className="mt-3 text-left">*/}
                {/*    {t('PRIVATE.PROFILE.EMAIL_FAILED')}*/}
                {/*  </Alert>*/}
                {/*) : (*/}
                {/*  <></>*/}
                {/*)}*/}
                {/*{updateSucceeded ? (*/}
                {/*  <Alert variant="success" className="mt-3 text-left">*/}
                {/*    <div>{t('PRIVATE.PROFILE.EMAIL_CHANGED_MSG1')}</div>*/}
                {/*    <div>{t('PRIVATE.PROFILE.EMAIL_CHANGED_MSG2')}</div>*/}
                {/*  </Alert>*/}
                {/*) : (*/}
                {/*  <></>*/}
                {/*)}*/}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => setSubmitTriggered(true)}
                >
                  {t('PRIVATE.PROFILE.CHANGE_EMAIL_CTA')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
