import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PspPrice, PspProduct } from '../types/types';

export type PspSliceState = {
  products: PspProduct[];
  prices: PspPrice[];
};

const pspSlice = createSlice({
  name: 'psp',
  initialState: {} as PspSliceState,
  reducers: {
    productsSet(state, action: PayloadAction<PspProduct[]>) {
      state.products = action.payload;
    },
    pricesSet(state, action: PayloadAction<PspPrice[]>) {
      state.prices = action.payload;
    },
  },
});

export const { productsSet, pricesSet } = pspSlice.actions;

export default pspSlice.reducer;
