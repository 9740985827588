import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { getPrices, getProducts } from '../../graphql/queries';
import { PspPrice, PspProduct } from '../../types/types';
import { pricesSet, productsSet } from '../../slices/pspSlice';

export const PspProductsFetcher = (): JSX.Element => {
  const dispatch = useDispatch();
  const { data } = useQuery<{ getProducts: PspProduct[] }>(getProducts);

  // is executed when the data really changes and not on simple page change
  useEffect(() => {
    if (data?.getProducts) {
      dispatch(productsSet(data.getProducts));
    }
  }, [data, dispatch]);

  return <></>;
};

export const PspPricesFetcher = (): JSX.Element => {
  const dispatch = useDispatch();
  const { data } = useQuery<{ getPrices: PspPrice[] }>(getPrices);

  // is executed when the data really changes and not on simple page change
  useEffect(() => {
    if (data?.getPrices) {
      dispatch(pricesSet(data.getPrices));
    }
  }, [data, dispatch]);

  return <></>;
};
