import { iNotification } from 'react-notifications-component';

export const baseNotificationDismissTimeoutMs = 5000;

export const baseNotification: iNotification = {
  type: 'success',
  insert: 'top',
  container: 'top-right',
  dismiss: {
    duration: baseNotificationDismissTimeoutMs,
    onScreen: false,
    click: true,
    touch: true,
    showIcon: true,
  },
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
};

export const baseSuccessNotification: iNotification = baseNotification;

export const baseErrorNotification: iNotification = {
  ...baseNotification,
  type: 'danger',
  dismiss: {
    duration: 10000,
    onScreen: false,
    click: true,
    touch: true,
    showIcon: true,
  },
};

export const baseInfoNotification: iNotification = {
  ...baseNotification,
  type: 'info',
  dismiss: {
    duration: 10000,
    onScreen: false,
    click: true,
    touch: true,
    showIcon: true,
  },
};
