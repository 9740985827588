import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { getBlacklistedUsernames } from '../../graphql/queries';
import { BlacklistedUsernames } from '../../types/types';
import { usernamesSet } from '../../slices/blacklistSlice';
import { SearchPaging } from '../../types/inputTypes';

/**
 * Fetches blacklisted usernames from BE.
 */
export const BlacklistedUsernamesFetcher = (): JSX.Element => {
  const dispatch = useDispatch();
  const { data } = useQuery<{ getBlacklistedUsernames: BlacklistedUsernames }>(getBlacklistedUsernames, {
    variables: {
      paging: {
        page: 0,
        perPage: 200,
      } as SearchPaging,
    },
  });

  // is executed when the data really changes and not on simple page change
  useEffect(() => {
    if (data?.getBlacklistedUsernames?.usernames) {
      dispatch(usernamesSet(data.getBlacklistedUsernames.usernames));
    }
  }, [data, dispatch]);

  return <></>;
};
