/* eslint-disable prettier/prettier */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import styles from './Profiling.module.scss';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { gsap, TweenLite } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSliceState, userLoaded } from '../../../slices/authSlice';
import { CompanyType, User, UserPosition } from '../../../types/types.d';
import logger from '../../../utils/logger/logger';
import { useMutation } from '@apollo/client';
import { updateUser } from '../../../graphql/mutations';
import { Redirect } from 'react-router-dom';
import { HOME, LOGIN } from '../../../utils/routingUtils';
import { useHistory } from 'react-router';
import iconDeveloper from '../../../assets/img/icons/icon-developer.svg';
import iconExperiments from '../../../assets/img/icons/icon-experiemtns.svg';
import iconNodes from '../../../assets/img/icons/icon-nodes.svg';
import iconPresentation from '../../../assets/img/icons/icon-presentation.svg';
import iconProject_manager from '../../../assets/img/icons/icon-project_manager.svg';
import iconRecordings from '../../../assets/img/icons/icon-recordings.svg';
import iconScientist from '../../../assets/img/icons/icon-scientist.svg';
import iconTeam_lead from '../../../assets/img/icons/icon-team_lead.svg';
import { useTranslation } from 'react-i18next';
import { UserInput } from '../../../types/inputTypes';
import { getLocalisedCompanyType } from '../../../utils/userUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const optionMap: { [key: string]: any } = {};

interface Values {
  position: UserPosition.DEVELOPER | UserPosition.SCIENTIST | UserPosition.TEAM_LEAD | UserPosition.PROJECT_MANAGER;
  companyName: string;
  companyType: CompanyType;
  supportWays: string[];
  otherSupportWays: string;
}

/**
 * Admin page where an admin can see list of users and manage them
 */
export default function Profiling(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const [doUpdateUser] = useMutation<{ updateUser?: User }>(updateUser);

  const [step, setStep] = useState(-1);
  const maxSteps = 3;
  const steps: Array<RefObject<HTMLDivElement>> = [];

  steps.push(useRef(null));
  steps.push(useRef(null));
  steps.push(useRef(null));

  useEffect(() => {
    steps.map((el) => {
      TweenLite.set(el.current, { autoAlpha: 0, css: { display: 'none' } });
    });
    gotoStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  optionMap[UserPosition.DEVELOPER] = { label: t('PRIVATE.PROFILE.ROLE_DEVELOPER'), img: iconDeveloper };
  optionMap[UserPosition.SCIENTIST] = { label: t('PRIVATE.PROFILE.ROLE_SCIENTIST'), img: iconScientist };
  optionMap[UserPosition.TEAM_LEAD] = { label: t('PRIVATE.PROFILE.ROLE_TEAM_LEAD'), img: iconTeam_lead };
  optionMap[UserPosition.PROJECT_MANAGER] = {
    label: t('PRIVATE.PROFILE.ROLE_PROJECT_MANAGER'),
    img: iconProject_manager,
  };
  optionMap['Quick experiments'] = { label: t('PRIVATE.PROFILE.TASK_EXPERIMENTS'), img: iconExperiments };
  optionMap['Protected recordings'] = { label: t('PRIVATE.PROFILE.TASK_RECORDINGS'), img: iconRecordings };
  optionMap['Presentation'] = { label: t('PRIVATE.PROFILE.TASK_PRESENTATION'), img: iconPresentation };
  optionMap['Nodes'] = { label: t('PRIVATE.PROFILE.TASK_NODES'), img: iconNodes };

  const gotoStep = (newStep: number) => {
    const tl = gsap.timeline();
    if (steps[step]) {
      tl.to(steps[step].current, { duration: 0.3, autoAlpha: 0, ease: 'power2.easeOut' }).set(steps[step].current, {
        css: { display: 'none' },
      });
    }
    if (steps[newStep]) {
      tl.set(steps[newStep].current, { autoAlpha: 0, css: { display: 'flex' } });
      tl.to(steps[newStep].current, { duration: 0.3, autoAlpha: 1, ease: 'Power2.easeOut' });
    }
    setStep(newStep);
  };

  const validateStep = (values: Values): boolean => {
    let result = false;

    if (step === 0) {
      result = true;
    }

    if (step === 1 && values.companyName.trim() !== '') {
      result = true;
    }

    if (step === 2 && (!!values.supportWays.length || values.otherSupportWays.trim() !== '')) {
      result = true;
    }

    return result;
  };

  if (!user) return <Redirect to={{ pathname: LOGIN }} />;

  const handleSubmit = (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
    setSubmitting(true);

    doUpdateUser({
      variables: {
        input: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          techUsername: user.techUsername,
          role: user.role,
          languageIso6391: user.languageIso6391,
          countryIsoAlpha2: user.countryIsoAlpha2,
          position: values.position,
          companyName: values.companyName,
          companyType: values.companyType,
          supportWays: values.supportWays.join(', '),
          otherSupportWays: values.otherSupportWays,
          twoFAEnabled: user.twoFAEnabled,
          productUpdatesEnabled: user.productUpdatesEnabled,
          maxDevHostsCount: user.maxDevHostsCount,
        } as UserInput,
      },
    })
      .then((result) => {
        if (result && result.data && result.data.updateUser) {
          dispatch(userLoaded(result.data.updateUser));
          history.push(HOME);
        } else {
          logger.error('No result for user update from Profiling');
        }
      })
      .catch((error) => {
        logger.error(`User update error from Profiling: ${error}`);
      })
      .finally(() => setSubmitting(false));
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <ProgressBar now={(step * 100) / maxSteps} className={styles.progress} />
      <div className="authpage no-bg">
        <Formik
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            position: user.position !== UserPosition.NONE ? user.position : UserPosition.DEVELOPER,
            companyName: !!user.companyName.length ? user.companyName : '',
            companyType: [CompanyType.OEM, CompanyType.TIER_1_2, CompanyType.ACADEMIA, CompanyType.OTHER].includes(
              user.companyType,
            )
              ? user.companyType
              : CompanyType.OEM,
            supportWays: [],
            otherSupportWays: !!user.otherSupportWays.length ? user.otherSupportWays : '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div ref={steps[0]} className={styles.profilingstep}>
                <h4 className="text-center">What does describe your role best?</h4>
                <div className="authform mt-5">
                  {[
                    UserPosition.DEVELOPER,
                    UserPosition.SCIENTIST,
                    UserPosition.TEAM_LEAD,
                    UserPosition.PROJECT_MANAGER,
                  ].map(function (val, i) {
                    return (
                      <label
                        key={i}
                        className={'box mt-2 ' + styles.option + (values.position === val ? ' active' : '')}
                      >
                        <input
                          type="radio"
                          name="position"
                          onChange={handleChange}
                          value={val}
                          checked={values.position === val}
                        />
                        <img src={optionMap[val].img} className="mr-3" />
                        <div>{optionMap[val].label}</div>
                      </label>
                    );
                  })}
                </div>
              </div>

              <div ref={steps[1]} className={styles.profilingstep}>
                <h4 className="text-center">About your company</h4>
                <div className="authform mt-5">
                  <Form.Group className="form-group">
                    <Form.Label>Company name</Form.Label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="companyName"
                      placeholder="Your company's name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName}
                    />
                  </Form.Group>

                  <label className="mt-3">Company type</label>
                  {[CompanyType.OEM, CompanyType.TIER_1_2, CompanyType.ACADEMIA, CompanyType.OTHER].map(function (
                    val,
                    i,
                  ) {
                    return (
                      <label
                        key={i}
                        className={'box mt-2 ' + styles.option + (values.companyType === val ? ' active' : '')}
                      >
                        <input
                          type="radio"
                          name="companyType"
                          onChange={handleChange}
                          value={val}
                          checked={values.companyType === val}
                        />
                        {/*<img src={optionMap[val].img} className="mr-3" />*/}
                        <div>{getLocalisedCompanyType(val, t)}</div>
                      </label>
                    );
                  })}
                </div>
              </div>

              <div ref={steps[2]} className={styles.profilingstep}>
                <h4 className="text-center">How can LinkWorks support you best?</h4>
                <div className="authform mt-5">
                  {['Quick experiments', 'Protected recordings', 'Presentation', 'Nodes'].map(function (val, i) {
                    return (
                      <label
                        key={i}
                        className={
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          'box mt-2 ' + styles.option + (values.supportWays.indexOf(val) !== -1 ? ' active' : '')
                        }
                      >
                        <input type="checkbox" name="supportWays" onChange={handleChange} value={val} />
                        <img src={optionMap[val].img} className="mr-3" />
                        <div>{optionMap[val].label}</div>
                      </label>
                    );
                  })}

                  <div className={'box mt-2 pb-1' + (values.otherSupportWays.trim() !== '' ? ' active' : '')}>
                    <Form.Group className="form-group">
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="otherSupportWays"
                        placeholder="Other ways to support me"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.otherSupportWays}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <footer className={styles.footer}>
                <div className={styles.footerframe}>
                  {step > 0 && (
                    <Button variant="outline-info" onClick={() => gotoStep(step - 1)}>
                      Back
                    </Button>
                  )}
                  <div />
                  {step + 1 >= maxSteps && (
                    <Button variant="primary" type="submit" disabled={!validateStep(values)}>
                      Next
                    </Button>
                  )}
                  {step + 1 < maxSteps && (
                    <Button variant="primary" onClick={() => gotoStep(step + 1)} disabled={!validateStep(values)}>
                      Next
                    </Button>
                  )}
                </div>
              </footer>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
