import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, InputGroup } from 'react-bootstrap';
import {
  PASSWORD_RECOVER,
  REGISTER,
  URL_QUERY_PARAM_EXPIRED_SESSION,
  useURLSearchParams,
} from '../../../utils/routingUtils';
import { useTranslation } from 'react-i18next';
import { login } from '../../../services/auth.service';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import styles from './Login.module.scss';
import { ReactComponent as GdprIcon } from '../../../assets/img/gdpr.svg';
import { ReactComponent as TlsIcon } from '../../../assets/img/tls.svg';
import { Store } from 'react-notifications-component';
import { baseErrorNotification } from '../../../utils/nitificationUtils';

interface Values {
  email: string;
  password: string;
  rememberMe: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string().email(t('VALIDATION.EMAIL_VALID')).max(100, t('VALIDATION.EMAIL_MAX_LENGTH')).optional(),
    password: validateOnSubmit
      ? Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .optional(),
  });
};

/**
 * Shows login form for a user to provide basic authentication credentials.
 * Login is possible with email/password.
 */
export default function Login(): JSX.Element {
  const { t } = useTranslation();
  const expiredSession = useURLSearchParams().has(URL_QUERY_PARAM_EXPIRED_SESSION);
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      login(values.email, values.password, values.rememberMe)
        .then((result: boolean) => {
          if (result) {
            // update the page and let user start working
            window.location.reload();
          } else {
            setSubmitting(false);
            showErrorNotification(t('PUBLIC.AUTH.LOGIN.FAILED'));
          }
        })
        .catch(() => {
          setSubmitting(false);
          showErrorNotification(t('PUBLIC.AUTH.LOGIN.FAILED'));
        });
    },
    [showErrorNotification, t],
  );

  return (
    <div className="authpage">
      {expiredSession && (
        <>
          <div className={styles.expiredSession}>{t('PUBLIC.AUTH.LOGIN.EXPIRED_SESSION')}</div>
          <br />
        </>
      )}
      <h1 className="text-center">{t('PUBLIC.AUTH.LOGIN.HEADING')}</h1>
      <div className="authbox">
        <Formik
          validationSchema={validationSchema(t, submitTriggered)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            email: '',
            password: '',
            rememberMe: false,
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="authform">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>{t('PUBLIC.AUTH.EMAIL_ADDRESS')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder={t('PUBLIC.AUTH.EMAIL_ADDRESS_PLACEHOLDER')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword" className="position-relative">
                <Form.Label>{t('PUBLIC.AUTH.PASSWORD')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder={t('PUBLIC.AUTH.PASSWORD_PLACEHOLDER')}
                    autoComplete="true"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </InputGroup>
                <small className={styles.pwlink}>
                  <Link to={PASSWORD_RECOVER} className="alert-link">
                    {t('PUBLIC.AUTH.FORGOT_PASSWORD')}
                  </Link>
                </small>
              </Form.Group>

              <div className="mt-5">
                <Button
                  variant="primary"
                  size="lg"
                  type="submit"
                  block
                  disabled={isSubmitting}
                  onClick={() => setSubmitTriggered(true)}
                >
                  {t('PUBLIC.AUTH.LOGIN.CTA')}
                </Button>
              </div>

              <small className="form-text mt-3 text-center">
                <span>{t('PUBLIC.AUTH.NO_ACCOUNT')}</span>{' '}
                <Link to={REGISTER} className="alert-link">
                  {t('PUBLIC.AUTH.NO_ACCOUNT_CTA')}
                </Link>
              </small>
            </Form>
          )}
        </Formik>
      </div>
      <div className={styles.trustInfo}>
        <div className={styles.trustInfoItem}>
          <div className={styles.trustInfoItemIcon}>
            <GdprIcon />
          </div>
          <div className={styles.trustInfoItemText}>{t('PUBLIC.AUTH.LOGIN.GDPR')}</div>
        </div>
        <div className={styles.trustInfoItem}>
          <div className={styles.trustInfoItemIcon}>
            <TlsIcon />
          </div>
          <div className={styles.trustInfoItemText}>{t('PUBLIC.AUTH.LOGIN.TLS')}</div>
        </div>
      </div>
    </div>
  );
}
