import React, { useCallback, useMemo } from 'react';
import styles from './PrivateHome.module.scss';
import { Link, Route, Switch } from 'react-router-dom';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import {
  ACCOUNT,
  ADMIN_BLACKLIST,
  ADMIN_GROUP,
  ADMIN_GROUPS,
  ADMIN_USER,
  ADMIN_USERS,
  GROUP,
  GROUPS,
  HOME,
  HOST,
  SUBSCRIPTIONS,
} from '../../../utils/routingUtils';
import Home from '../home/Home';
import Account from '../account/Account';
import Host from '../host/Host';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSliceState } from '../../../slices/authSlice';
import AdminUsers from '../admin/users';
import { logout } from '../../../services/auth.service';
import { RESET } from '../../app/store';
import { getFullName, getFullNameAbbr, userHasAdminRole } from '../../../utils/userUtils';
import { AdminPrivateRoute } from '../PrivateRoute';
import AdminUser from '../admin/user';
import { useHistory } from 'react-router';
import Blacklist from '../admin/blacklist';
import AdminGroups from '../admin/groups';
import AdminGroup from '../admin/groups/group';
import { ReactComponent as ProductNameIcon } from '../../../assets/img/productNameNew.svg';
import { ReactComponent as HomeIcon } from '../../../assets/img/home.svg';
import { ReactComponent as DocsIcon } from '../../../assets/img/docs.svg';
import { ReactComponent as GroupsIcon } from '../../../assets/img/groups.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/img/externalLink.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/copy.svg';
import { useTranslation } from 'react-i18next';
import Groups from '../groups';
import Group from '../common/group';
import Plans from '../plans';

const externalLinks = {
  docs: 'https://draive.com/docs/link2',
  pricing: 'https://linkworks.io/pricing',
};

/**
 * Main Home that structures what a logged in user sees.
 * See the React Switch that defines the content shown in the middle of the screen.
 */
export default function PrivateHome(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const fullName = useMemo(() => (user ? getFullName(user) : ''), [user]);

  const handleLogout = useCallback(() => {
    logout().finally(() => {
      dispatch(RESET);
    });
  }, [dispatch]);

  const handleAccount = useCallback(() => history.push(ACCOUNT), [history]);

  if (!user) return <></>;

  return (
    <Container fluid={true} className={styles.content}>
      <div className={styles.sidebarWrapper}>
        <div className="m-4 mb-5">
          <ProductNameIcon />
        </div>
        <Nav className="flex-column usermenu">
          <Link to={HOME} className={HOME === location.pathname ? 'active' : ''}>
            <HomeIcon />
            {t('PRIVATE.SIDEBAR.HOME')}
          </Link>
          <Link to={GROUPS} className={GROUPS === location.pathname ? 'active' : ''}>
            <GroupsIcon />
            {t('PRIVATE.SIDEBAR.GROUPS')}
          </Link>
          {/*<Link to={SUBSCRIPTIONS} className={SUBSCRIPTIONS === location.pathname ? 'active' : ''}>*/}
          {/*  <CopyIcon />*/}
          {/*  {t('PRIVATE.SIDEBAR.SUBSCRIPTIONS')}*/}
          {/*</Link>*/}
          <a href={externalLinks.pricing} target="_blank" rel="noreferrer">
            <CopyIcon />
            {t('PRIVATE.SIDEBAR.PLANS')}
            <span className={styles.outbound}>
              <ExternalLinkIcon />
            </span>
          </a>
          <a href={externalLinks.docs} target="_blank" rel="noreferrer">
            <DocsIcon />
            {t('PRIVATE.SIDEBAR.DOCS')}
            <span className={styles.outbound}>
              <ExternalLinkIcon />
            </span>
          </a>

          {userHasAdminRole(user) && (
            <>
              <Link to={ADMIN_USERS} className={ADMIN_USERS === location.pathname ? 'active' : ''}>
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.125 12a1.875 1.875 0 013.75 0 19.787 19.787 0 01-2.34 9.364M12 6.375A5.625 5.625 0 0117.625 12c0 .759-.035 1.509-.106 2.25M4.813 18.021a12.32 12.32 0 001.56-6.02A5.61 5.61 0 018.248 7.81M9.685 15.75a16.029 16.029 0 01-1.904 4.624M16.857 18c-.217.83-.48 1.648-.787 2.449"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.09 15c.356-.96.537-1.976.536-3h.001a9.375 9.375 0 0118.75 0h-.001c.001 2.018-.22 4.03-.66 6"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('PRIVATE.SIDEBAR.ADMIN_USERS')}
              </Link>
              <Link to={ADMIN_BLACKLIST} className={ADMIN_BLACKLIST === location.pathname ? 'active' : ''}>
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.125 12a1.875 1.875 0 013.75 0 19.787 19.787 0 01-2.34 9.364M12 6.375A5.625 5.625 0 0117.625 12c0 .759-.035 1.509-.106 2.25M4.813 18.021a12.32 12.32 0 001.56-6.02A5.61 5.61 0 018.248 7.81M9.685 15.75a16.029 16.029 0 01-1.904 4.624M16.857 18c-.217.83-.48 1.648-.787 2.449"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.09 15c.356-.96.537-1.976.536-3h.001a9.375 9.375 0 0118.75 0h-.001c.001 2.018-.22 4.03-.66 6"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('PRIVATE.SIDEBAR.ADMIN_BLACKLIST')}
              </Link>
              <Link to={ADMIN_GROUPS} className={ADMIN_GROUPS === location.pathname ? 'active' : ''}>
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.125 12a1.875 1.875 0 013.75 0 19.787 19.787 0 01-2.34 9.364M12 6.375A5.625 5.625 0 0117.625 12c0 .759-.035 1.509-.106 2.25M4.813 18.021a12.32 12.32 0 001.56-6.02A5.61 5.61 0 018.248 7.81M9.685 15.75a16.029 16.029 0 01-1.904 4.624M16.857 18c-.217.83-.48 1.648-.787 2.449"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.09 15c.356-.96.537-1.976.536-3h.001a9.375 9.375 0 0118.75 0h-.001c.001 2.018-.22 4.03-.66 6"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('PRIVATE.SIDEBAR.ADMIN_GROUPS')}
              </Link>
            </>
          )}
        </Nav>
      </div>
      <div className={styles.pageContentWrapper}>
        <Navbar collapseOnSelect bg="white" className="sticky-top shadow-sm">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav aria-label="breadcrumb" className="mr-auto">
              {/* <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Library</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Data
                </li>
              </ol> */}
            </Nav>
            <Dropdown className={styles.dropdown}>
              <Dropdown.Toggle variant="link">
                <figure className="avatar">
                  <figcaption>{getFullNameAbbr(user)}</figcaption>
                </figure>
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight>
                <Dropdown.Item className={styles.nameAndEmail}>
                  <div>
                    {!!fullName.length && <div className={styles.name}>{getFullName(user)}</div>}
                    <div className={styles.email}>{user?.email}</div>
                  </div>
                </Dropdown.Item>
                <hr className={styles.divider} />
                <Dropdown.Item onClick={handleAccount}>Account</Dropdown.Item>
                <hr className={styles.divider} />
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
        </Navbar>
        <Container fluid={true} className={styles.pageContent}>
          <Switch>
            <Route path={HOME} component={Home} />
            <Route path={HOST} component={Host} />
            <Route path={ACCOUNT} component={Account} />
            <Route path={GROUP} component={Group} />
            <Route path={GROUPS} component={Groups} />
            <Route path={SUBSCRIPTIONS} component={Plans} />
            {<AdminPrivateRoute path={ADMIN_USER} component={AdminUser} />}
            {<AdminPrivateRoute path={ADMIN_USERS} component={AdminUsers} />}
            {<AdminPrivateRoute path={ADMIN_BLACKLIST} component={Blacklist} />}
            {<AdminPrivateRoute path={ADMIN_GROUP} component={AdminGroup} />}
            {<AdminPrivateRoute path={ADMIN_GROUPS} component={AdminGroups} />}
          </Switch>
        </Container>
      </div>
    </Container>
  );
}
