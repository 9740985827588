import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types/types';

export type AuthSliceState = {
  user: User | null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {} as AuthSliceState,
  reducers: {
    userLoaded(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    userUnloaded(state) {
      state.user = null;
    },
  },
});

export const { userLoaded, userUnloaded } = authSlice.actions;

export default authSlice.reducer;
