import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Collapse, Container, Form, Row } from 'react-bootstrap';
import styles from './Host.module.scss';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import arrowBack from '../../../assets/img/arrow-back.svg';
import { useSelector } from 'react-redux';
import { AuthSliceState } from '../../../slices/authSlice';
import { downloadFile } from '../../../utils/windowUtils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { addHost } from '../../../graphql/mutations';
import { Host as HostType, OS, User } from '../../../types/types.d';
import logger from '../../../utils/logger/logger';
import { getHosts } from '../../../graphql/queries';
import { condarcContent, linkhqFileName } from '../../../utils/businessUtils';
import { HOME } from '../../../utils/routingUtils';
import OsSelector from '../common/osSelector';

interface Values {
  machineId: string;
  hostName: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    machineId: validateOnSubmit
      ? Yup.string()
          .min(1, t('PRIVATE.HOST.VALIDATION.ID_MIN_LENGTH'))
          .max(150, t('PRIVATE.HOST.VALIDATION.ID_MAX_LENGTH'))
          .required(t('PRIVATE.HOST.VALIDATION.REQUIRED'))
      : Yup.string()
          .min(1, t('PRIVATE.HOST.VALIDATION.ID_MIN_LENGTH'))
          .max(150, t('PRIVATE.HOST.VALIDATION.ID_MAX_LENGTH'))
          .optional(),
    hostName: validateOnSubmit
      ? Yup.string()
          .min(1, t('PRIVATE.HOST.VALIDATION.NAME_MIN_LENGTH'))
          .max(50, t('PRIVATE.HOST.VALIDATION.NAME_MAX_LENGTH'))
          .required(t('PRIVATE.HOST.VALIDATION.REQUIRED'))
      : Yup.string()
          .min(1, t('PRIVATE.HOST.VALIDATION.NAME_MIN_LENGTH'))
          .max(50, t('PRIVATE.HOST.VALIDATION.NAME_MAX_LENGTH'))
          .optional(),
  });
};

export interface HostLocationState {
  user: User;
}

export default function Host(): JSX.Element {
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<HostLocationState>();
  const [os, setOS] = useState<OS>(
    window.navigator.platform.toLowerCase().indexOf('win') === -1 ? OS.LINUX : OS.WINDOWS,
  );
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const finalUser = location.state?.user ? location.state.user : user;

  const [doAddHost] = useMutation<{ addHost?: HostType }>(addHost);

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [addHostSucceeded, setAddHostSucceeded] = useState(false);
  const [addHostFailed, setAddHostFailed] = useState(false);
  const [hostNameTaken, setHostNameTaken] = useState(false);

  const [linkhqContent, setLink2hqContent] = useState('');

  const [fetchHosts, { loading, error, data }] = useLazyQuery<{ getHosts?: HostType[] }>(getHosts);
  useEffect(() => {
    fetchHosts();
  }, [fetchHosts]);

  const hosts: HostType[] = useMemo(() => (data?.getHosts ? data?.getHosts : []), [data?.getHosts]);
  const stepsOpen = useMemo(() => hosts.length > 0, [hosts.length]);

  const condarc = useMemo(
    () => condarcContent(`${process.env.REACT_APP_CONDA_BASE_URL}`, user?.condaKey || ''),
    [user?.condaKey],
  );

  //   const netrcContent = useMemo(
  //     () => `machine conda.draive.com
  // login ${location.state?.user ? '(not showing login of another user)' : finalUser?.techUsername}
  // password ${location.state?.user ? '(not showing password of another user)' : finalUser?.techPassword}
  // `,
  //     [finalUser?.techPassword, finalUser?.techUsername, location.state?.user],
  //   );

  const handleSubmit = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);
      setHostNameTaken(false);
      setAddHostFailed(false);

      const formInputs = document.getElementById('form-inputs');
      const verHost = document.getElementById('verifyHost');

      // in case machine id contains also host name, split the value and take the first part only
      const parts = values.machineId.split(' ') || [];
      const machineId = parts.length > 0 ? parts[0] : null;
      const hostName = values.hostName;

      // check there is no host with provided name
      if (!!hosts?.find((host) => host.hostName === hostName)) {
        setHostNameTaken(true);
        setSubmitting(false);
        return;
      }

      doAddHost({
        variables: {
          machineId: machineId,
          hostName: hostName,
          email: finalUser?.email,
        },
      })
        .then((result) => {
          const addedHost = result?.data?.addHost;
          if (addedHost) {
            setLink2hqContent(addedHost.hostQualifier);
            setAddHostSucceeded(true);

            const tl = gsap.timeline();
            tl.to(formInputs, { duration: 0.3, autoAlpha: 0, ease: 'power2.easeOut' })
              .set(formInputs, { css: { display: 'none' } })
              .set(verHost, { css: { display: 'block' }, autoAlpha: 0 })
              .to(verHost, { duration: 0.3, autoAlpha: 1, ease: 'power2.easeOut' });
          } else {
            logger.error('No result for add host request');
            setAddHostFailed(true);
          }
        })
        .catch((error) => {
          logger.error(`Error adding host: ${error}`);
          setAddHostFailed(true);
        })
        .finally(() => setSubmitting(false));
    },
    [doAddHost, finalUser?.email, hosts],
  );

  const isStepOpen = useCallback(
    (step: number): boolean => {
      return step === currentStep || stepsOpen;
    },
    [currentStep, stepsOpen],
  );

  const completeHostCreation = useCallback(() => {
    setCurrentStep(currentStep + 1);
    setTimeout(() => {
      history.push(HOME);
    }, 1000);
  }, [currentStep, history]);

  const getStepClass = useCallback(
    (step: number): string => {
      let cls = '';

      if (!stepsOpen) {
        if (currentStep < step) {
          cls = styles.inactive;
        } else if (currentStep === step) {
          cls = '';
        } else {
          cls = styles.done;
        }
      }

      return cls;
    },
    [currentStep, stepsOpen],
  );

  if (loading && (!error || !data)) return <></>;

  return (
    <Container>
      <Row>
        <Col className="box p-5">
          <header className="d-flex align-items-center mb-4">
            {stepsOpen && <img alt="back" className="mr-4 arrowback" src={arrowBack} onClick={history.goBack} />}
            {!stepsOpen && !location.state?.user ? (
              <div>
                <h1>Welcome aboard!</h1>
                <h3>Let&rsquo;s start with the registration of a new host.</h3>
              </div>
            ) : (
              <h3>
                Register new host
                {location.state?.user
                  ? ` for ${
                      finalUser?.firstName && finalUser?.lastName
                        ? finalUser?.firstName + ' ' + finalUser?.lastName
                        : finalUser?.fullName
                    } (${finalUser?.email})`
                  : ''}
              </h3>
            )}
          </header>

          {/*<div className={styles.plattformselector}>*/}
          {/*  <Form.Control as="select" custom name="companyType" onChange={onOSChange}>*/}
          {/*    <option value="windows">For Windows</option>*/}
          {/*    <option value="linux">For Linux</option>*/}
          {/*  </Form.Control>*/}
          {/*</div>*/}
          <div className={styles.osSelector}>
            {!stepsOpen && <div className={styles.selectOs}>Select your OS:</div>}
            <div className={styles.selector}>
              <OsSelector
                externallyManagedSelectedValues={true}
                selectedValues={[os]}
                onSelect={(values) => {
                  if (values.length === 1) {
                    setOS(values[0]);
                  }
                }}
                controlShouldRenderValue={true}
                isMulti={false}
                isSearchable={false}
                closeMenuOnSelect={true}
              />
            </div>
          </div>

          <div className={styles.step + ' ' + getStepClass(1)}>
            <header onClick={() => setCurrentStep(1)}>
              <div className={styles.stepnumber + ' mr-3'}>
                <svg width="19" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6.09L7.294 11 17 2" stroke="#0F1652" strokeWidth="4" />
                </svg>
                <span>1</span>
              </div>
              <div className="mr-3">Install Conda</div>
              {/* <a href="#">What is conda?</a> */}
            </header>
            <Collapse in={isStepOpen(1)}>
              <div>
                <div className={styles.stepbody}>
                  <p>
                    Conda is a package manager. We use it to distribute the Link library, useful tools and dependencies.
                    Conda can install packages from different sources. You get DRAIVE software from our servers and open
                    source dependencies from the &quot;conda-forge&quot; project{' '}
                    {
                      <a target="_blank" rel="noopener noreferrer" href="https://conda-forge.org/">
                        https://conda-forge.org/
                      </a>
                    }
                    .
                  </p>
                  <p>
                    More information and a short introduction can be found here:{' '}
                    {
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.conda.io/projects/conda/en/latest/index.html"
                      >
                        https://docs.conda.io/projects/conda/en/latest/index.html
                      </a>
                    }
                    .
                  </p>
                  <div className="mt-4 d-flex align-items-center">
                    <a
                      className={'btn btn-primary mr-3 + ' + styles.stepcta}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        os === OS.LINUX
                          ? 'https://github.com/conda-forge/miniforge/releases/latest/download/Miniforge3-Linux-x86_64.sh'
                          : 'https://github.com/conda-forge/miniforge/releases/latest/download/Miniforge3-Windows-x86_64.exe'
                      }
                    >
                      Download latest version
                    </a>
                    <span>Please download Conda and run the installer.</span>
                  </div>

                  <div className="mt-3 text-info font-size-small">
                    For achitectures other than x86_64 we recommend the &quot;miniforge&quot; distribution from the
                    conda-forge project. Installers are available here{' '}
                    {
                      <a target="_blank" rel="noopener noreferrer" href="https://github.com/conda-forge/miniforge">
                        https://github.com/conda-forge/miniforge
                      </a>
                    }
                  </div>
                </div>
                {!stepsOpen && (
                  <footer className="text-right mt-5">
                    <a
                      className="button btn-link mr-3"
                      target="_blank"
                      href="https://linkworks.io/docs/onboarding.html"
                      rel="noreferrer"
                    >
                      Help
                    </a>
                    <Button variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>
                      Done
                    </Button>
                  </footer>
                )}
              </div>
            </Collapse>
          </div>

          <div className={styles.step + ' ' + getStepClass(2)}>
            <header onClick={() => setCurrentStep(2)}>
              <div className={styles.stepnumber + ' mr-3'}>
                <svg width="19" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6.09L7.294 11 17 2" stroke="#0F1652" strokeWidth="4" />
                </svg>
                <span>2</span>
              </div>
              <div className="mr-3">Configure Conda</div>
            </header>
            <Collapse in={isStepOpen(2)}>
              <div>
                <div className={styles.stepbody}>
                  <p>
                    After you have installed conda, we need to configure the package sources for you. We have a suitable
                    configuration for you. Please, follow the steps below.
                  </p>

                  <div className="mt-4">
                    <p>Download your .condarc file:</p>
                    <a className="btn btn-primary" onClick={() => downloadFile('rename.condarc', condarc)}>
                      Download .condarc
                    </a>
                    <p className="mt-4">
                      Save it under:{' '}
                      {os === OS.WINDOWS ? 'C:\\Users\\<username>\\.condarc' : '/home/<username>/.condarc'}
                      <br />
                      <span className="text-primary">(Attention: You need to rename the file locally to .condarc)</span>
                    </p>
                  </div>
                  <div className="mt-3">
                    <strong>Your .condarc configuration file preview</strong>
                  </div>
                  <textarea className={'mt-2 ' + styles.configpreview} readOnly={true} defaultValue={condarc} />

                  {/*<div className="mt-5">*/}
                  {/*  <p>Download your .netrc file:</p>*/}
                  {/*  <a className="btn btn-primary" onClick={() => downloadFile('rename.netrc', netrcContent)}>*/}
                  {/*    Download .netrc*/}
                  {/*  </a>*/}
                  {/*  <p className="mt-4">*/}
                  {/*    Save it under: {os === OS.WINDOWS ? 'C:\\Users\\<username>\\.netrc' : '/home/<username>/.netrc'}*/}
                  {/*    <br />*/}
                  {/*    <span className="text-primary">(Attention: You need to rename the file locally to .netrc)</span>*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                  {/*<div className="mt-3">*/}
                  {/*  <strong>Your .netrc configuration file preview</strong>*/}
                  {/*</div>*/}
                  {/*<textarea*/}
                  {/*  className={`mt-2 ${styles.configpreview} ${styles.netrcPreview}`}*/}
                  {/*  readOnly={true}*/}
                  {/*  defaultValue={netrcContent}*/}
                  {/*/>*/}
                </div>
                {!stepsOpen && (
                  <footer className="text-right mt-5">
                    <a
                      className="button btn-link mr-3"
                      target="_blank"
                      href="https://linkworks.io/docs/onboarding.html"
                      rel="noreferrer"
                    >
                      Help
                    </a>
                    <Button variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>
                      Done
                    </Button>
                  </footer>
                )}
              </div>
            </Collapse>
          </div>

          <div className={styles.step + ' ' + getStepClass(3)}>
            <header onClick={() => setCurrentStep(3)}>
              <div className={styles.stepnumber + ' mr-3'}>
                <svg width="19" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6.09L7.294 11 17 2" stroke="#0F1652" strokeWidth="4" />
                </svg>
                <span>3</span>
              </div>
              <div className="mr-3">Register your first host</div>
            </header>
            <Collapse in={isStepOpen(3)}>
              <div className={styles.stepbody}>
                <p>
                  To be able to develop with Link, you need a host-dependent developer key. We will generate one for you
                  now, so you can get started right away. You can register more hosts later if needed.
                </p>
                <div className="border p-5 rounded">
                  <div>
                    {os === OS.WINDOWS
                      ? '• Open the Anaconda Prompt from your Start menu.'
                      : "• Open a terminal and activate your base environment with 'conda activate'."}
                  </div>
                  <div className="mt-4">
                    <div className="mb-1">• Install the license tool</div>
                    <code>
                      {os === OS.WINDOWS ? 'conda install link2-license-tool' : 'conda install link2-license-tool'}
                    </code>
                  </div>
                  <div className="mt-4">
                    <div className="mb-1">• Run the license tool:</div>
                    <code>{os === OS.WINDOWS ? 'link2-license-tool show' : 'link2-license-tool show'}</code>
                  </div>

                  <Formik
                    validationSchema={validationSchema(t, submitTriggered)}
                    onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) =>
                      handleSubmit(values, setSubmitting)
                    }
                    initialValues={{
                      machineId: '',
                      hostName: '',
                    }}
                  >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
                      <Form noValidate onSubmit={handleSubmit} className="mt-4" id="host-form">
                        <div id="form-inputs">
                          <div className="mb-2">• Enter the Host ID that was issued in the terminal below:</div>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGroupMachineId">
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="machineId"
                                placeholder={t('PRIVATE.HOST.ID_PLACEHOLDER')}
                                onChange={(e) => {
                                  // split the input with space and treat the 1st part as machine id and the 2nd as host name
                                  // if host name value is empty then insert the present 2nd part as host name value
                                  const value = e?.target?.value;
                                  const parts = value?.split(' ') || [];
                                  const firstPart = parts.length > 0 ? parts[0] : null;
                                  const secondPart = parts.length > 1 ? parts[1] : null;

                                  if (firstPart) values.machineId = firstPart;
                                  if (secondPart && values.hostName.trim().length === 0) values.hostName = secondPart;

                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.machineId}
                                isInvalid={touched.machineId && !!errors.machineId}
                              />
                              <Form.Control.Feedback type="invalid">{errors.machineId}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGroupHostName" className="position-relative">
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="hostName"
                                placeholder={t('PRIVATE.HOST.NAME_PLACEHOLDER')}
                                autoComplete="true"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.hostName}
                                isInvalid={touched.hostName && !!errors.hostName}
                              />
                              <Form.Control.Feedback type="invalid">{errors.hostName}</Form.Control.Feedback>
                            </Form.Group>
                            <Col>
                              <div>
                                {/* <Form.Label>&nbsp;</Form.Label> */}
                                <Button
                                  variant="primary"
                                  size="lg"
                                  type="submit"
                                  block
                                  disabled={isSubmitting}
                                  onClick={() => setSubmitTriggered(true)}
                                >
                                  {t('PRIVATE.HOST.SAVE_CTA')}
                                </Button>
                              </div>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <>
                              {addHostFailed && (
                                <div className="mb-2">
                                  <small>{t('PRIVATE.HOST.ADD_HOST_FAILED')}</small>
                                </div>
                              )}
                              {hostNameTaken && (
                                <div className="mb-2">
                                  <small>{t('PRIVATE.HOST.HOST_NAME_TAKEN')}</small>
                                </div>
                              )}
                            </>
                          </Form.Row>
                        </div>
                        <div className="mt-4" id="verifyHost" style={{ display: 'none' }}>
                          <div className="mb-1">
                            • To complete registration <strong>download the file</strong> to{' '}
                            {os === OS.WINDOWS ? 'C:\\Users\\<username>\\' : '/home/<username>/'}
                          </div>
                          <div className={styles.hostItem}>
                            <header>
                              <i />
                              {values.hostName}
                            </header>
                            <a className={styles.hostFile} onClick={() => downloadFile(linkhqFileName, linkhqContent)}>
                              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6 2c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h8.172l-2-2H6V4h7v5h5v5h2V8l-6-6H6zm5.832 7c-.55 0-.962.373-1.043 1.023-.08.65.242 1.784.803 2.838-.241.73-.482 1.379-.803 2.028-1.043.324-2.005.81-2.406 1.297-.641.729-.32 1.215-.16 1.459.16.242.401.355.722.355.161 0 .322-.032.483-.113.642-.243 1.282-1.217 1.924-2.352.56-.162 1.122-.323 1.683-.404.561.648 1.125 1.055 1.606 1.217.56.162 1.042-.083 1.283-.65.16-.488.079-.892-.322-1.136-.482-.323-1.284-.325-2.247-.244-.32-.486-.64-.972-.88-1.459.4-1.216.56-2.269.4-2.918-.161-.568-.493-.941-1.043-.941zM18 16v4h-3l4 4 4-4h-3v-4h-2z"
                                  fill="#27B4FF"
                                />
                              </svg>
                              {linkhqFileName}
                            </a>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <footer className="text-right mt-4">
                    <a
                      className="button btn-link mr-3"
                      target="_blank"
                      href="https://linkworks.io/docs/onboarding.html"
                      rel="noreferrer"
                    >
                      Help
                    </a>
                    <Button disabled={!addHostSucceeded} variant="primary" onClick={completeHostCreation}>
                      Done
                    </Button>
                  </footer>
                </div>
              </div>
            </Collapse>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
