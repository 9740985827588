import styles from './ConfirmPlanChange.module.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Group, PlanData, Plans, PspCoupon, PspCustomer } from '../../../../../types/types.d';
import { getDomain, redirectToExternalUrl } from '../../../../../utils/windowUtils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getCheckoutSessionUrlForSetup,
  getCheckoutSessionUrlForSubscription,
  getCoupon,
} from '../../../../../graphql/queries';
import { Store } from 'react-notifications-component';
import {
  baseErrorNotification,
  baseInfoNotification,
  baseSuccessNotification,
} from '../../../../../utils/nitificationUtils';
import logger from '../../../../../utils/logger/logger';
import { updateGroupSubscription, updateTaxId } from '../../../../../graphql/mutations';
import { formatPriceAmount } from '../../../../../utils/businessUtils';
import { useLocalisedCurrencyFormatterWithoutDecimal } from '../../../../../utils/i18nUtils';
import classNames from 'classnames';
import { capitalizeFirstLetter } from '../../../../../utils/stringUtils';

interface Props {
  onHide: () => void;
  currentPlanData: PlanData;
  newPlanData: PlanData;
  group: Group;
  subscriptionExists: boolean;
  customer: PspCustomer;
  pathname: string;
  onTaxUpdated: (value: string) => void;
  onPlanChanged: () => void;
  onBackToPlans: () => void;
}

export default function ConfirmPlanChange({
  onHide,
  currentPlanData,
  newPlanData,
  group,
  subscriptionExists,
  customer,
  pathname,
  onTaxUpdated,
  onPlanChanged,
  onBackToPlans,
}: Props): JSX.Element {
  console.log({ currentPlanData, newPlanData });
  const { t } = useTranslation();
  const currencyFormatter = useLocalisedCurrencyFormatterWithoutDecimal();

  const [fetchCheckoutUrl] = useLazyQuery<{ getCheckoutSessionUrlForSubscription?: string }>(
    getCheckoutSessionUrlForSubscription,
  );
  const [fetchSetupUrl] = useLazyQuery<{ getCheckoutSessionUrlForSetup?: string }>(getCheckoutSessionUrlForSetup);
  const [updateSubscription] = useMutation<{ updateGroupSubscription?: Group }>(updateGroupSubscription);
  const [updateTax] = useMutation<{ updateTaxId: boolean }>(updateTaxId);
  // const [fetchCode] = useLazyQuery<{ getPromotionCode?: PspPromotionCode }>(getPromotionCode);
  const [fetchCoupon] = useLazyQuery<{ getCoupon?: PspCoupon }>(getCoupon);

  const [processing, setProcessing] = useState(false);
  const [promotionCode, setPromotionCode] = useState<string | null>(null);
  const [promotionCodeInvalid, setPromotionCodeInvalid] = useState(false);
  const [taxId, setTaxId] = useState<string | null>(customer.taxId || null);
  const [taxIdInvalid, setTaxIdInvalid] = useState(false);

  const subscribeNote: string | null = useMemo(() => {
    if (!currentPlanData.priceId) return t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.SUBSCRIBE_AFTER_PAYMENT');
    if (currentPlanData.name.toLowerCase() === Plans.TEAM && newPlanData.name.toLowerCase() !== Plans.TEAM)
      return t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.SUBSCRIBE_WITH_REFUND');
    return t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.SUBSCRIBE_WITH_CANCEL_AT_PERIOD_END');
  }, [currentPlanData.name, currentPlanData.priceId, newPlanData.name, t]);

  const showErrorNotification = useCallback(
    (title: string, withMsg: boolean) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title: withMsg ? title : null,
        message: withMsg ? t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE') : title,
      }),
    [t],
  );
  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showInfoNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseInfoNotification,
        dismiss: {
          duration: 5000,
          onScreen: false,
          click: true,
          touch: true,
          showIcon: true,
        },
        message: msg,
      }),
    [],
  );

  const triggerSetupSession = useCallback(() => {
    // successUrl - the "after" logic needs an indicator that a new one was created for the group
    fetchSetupUrl({
      variables: {
        successUrl: `${getDomain()}/#${pathname}?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${getDomain()}/#${pathname}`,
      },
    })
      .then((result) => {
        if (result?.data?.getCheckoutSessionUrlForSetup) {
          redirectToExternalUrl(result?.data?.getCheckoutSessionUrlForSetup);
        } else {
          logger.error(`Error fetching setup url`);
          showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
        }
      })
      .catch((e) => {
        logger.error(`Error fetching setup url: ${e}`);
        showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
      });
  }, [fetchSetupUrl, pathname, showErrorNotification, t]);

  const triggerCheckoutSession = useCallback(
    (promotionCodeId?: string) => {
      // successUrl - the "after" logic needs an indicator that a new one was created for the group
      fetchCheckoutUrl({
        variables: {
          groupId: group.id,
          priceId: newPlanData.priceId,
          promotionCodeId: promotionCodeId?.trim(),
          successUrl: `${getDomain()}/#${pathname}?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${getDomain()}/#${pathname}`,
        },
      })
        .then((result) => {
          if (result?.data?.getCheckoutSessionUrlForSubscription) {
            redirectToExternalUrl(result?.data?.getCheckoutSessionUrlForSubscription);
          } else {
            logger.error(`Error fetching checkout url`);
            showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
            setProcessing(false);
          }
        })
        .catch((e) => {
          logger.error(`Error fetching checkout url: ${e}`);
          showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
          setProcessing(false);
        });
    },
    [fetchCheckoutUrl, group.id, newPlanData.priceId, pathname, showErrorNotification, t],
  );

  const modifySubscription = useCallback(
    (promotionCodeId?: string) => {
      showInfoNotification(t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.UPDATING_PLAN'));
      updateSubscription({
        variables: {
          groupId: group.id,
          priceId: newPlanData.priceId,
          promotionCodeId: promotionCodeId?.trim(),
        },
      })
        .then((result) => {
          if (result?.data?.updateGroupSubscription) {
            showSuccessNotification(t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.UPDATE_SUCCESS'));
            onPlanChanged();
          } else {
            logger.error(`Error updating to another paid plan`);
            showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
          }
        })
        .catch((e) => {
          logger.error(`Error updating to another paid plan: ${e}`);
          showErrorNotification(t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.UPDATE_ERROR'), true);
        })
        .finally(() => setProcessing(false));
    },
    [
      showInfoNotification,
      updateSubscription,
      group.id,
      newPlanData.priceId,
      showSuccessNotification,
      t,
      onPlanChanged,
      showErrorNotification,
    ],
  );

  /**
   * Business logic on how to update the subscription
   */
  const handleConfirmation = useCallback(
    (promotionCodeId?: string) => {
      setProcessing(true);

      // if there is an existing subscription then let the BE decide how to handle subscription update
      // otherwise create subscription via checkout session
      if (subscriptionExists) modifySubscription(promotionCodeId);
      else triggerCheckoutSession(promotionCodeId);
    },
    [subscriptionExists, modifySubscription, triggerCheckoutSession],
  );

  /**
   * 1. Check promotion code if it is present
   * 2. Proceed to checkout logic
   */
  const checkPromotion = useCallback(() => {
    if (!promotionCode) {
      handleConfirmation();
    } else {
      // fetchCode({ variables: { code: promotionCode } })
      //   .then((result) => {
      //     const code = result?.data?.getPromotionCode;
      //     if (code && code.active) {
      //       setPromotionCodeInvalid(false);
      //       handleConfirmation(code.id);
      //     } else {
      //       setPromotionCodeInvalid(true);
      //     }
      //   })
      //   .catch((e) => {
      //     logger.error(`Error checking promotion code ${promotionCode}: ${e}`);
      //     setPromotionCodeInvalid(true);
      //   });
      fetchCoupon({ variables: { id: promotionCode } })
        .then((result) => {
          const coupon = result?.data?.getCoupon;
          if (coupon && coupon.valid) {
            setPromotionCodeInvalid(false);
            handleConfirmation(coupon.id);
          } else {
            setPromotionCodeInvalid(true);
          }
        })
        .catch((e) => {
          logger.error(`Error checking promotion code ${promotionCode}: ${e}`);
          setPromotionCodeInvalid(true);
        });
    }
  }, [fetchCoupon, handleConfirmation, promotionCode]);

  /**
   * 1. Validate tax id if it is present
   * 2. Initial tax id value and the current one do not fit then update it in the BE
   * 3. Check promotion code
   * 4. Proceed to plan subscription or change logic
   */
  const checkout = useCallback(() => {
    if (!!taxId?.length && (taxId.length != 11 || !taxId.startsWith('DE'))) {
      setTaxIdInvalid(true);
      return;
    }

    if ((customer.taxId || '') !== (taxId || '')) {
      updateTax({ variables: { value: taxId || '' } })
        .then((result) => {
          if (result.data?.updateTaxId) {
            setTaxId(taxId);
            onTaxUpdated(taxId || '');
            checkPromotion();
          } else {
            logger.error(`Error updating to tax id`);
            showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
          }
        })
        .catch((e) => {
          logger.error(`Error updating to tax id: ${e}`);
          showErrorNotification(t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'), false);
        });
    } else {
      checkPromotion();
    }
  }, [checkPromotion, customer.taxId, onTaxUpdated, showErrorNotification, t, taxId, updateTax]);

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.subscribe}>
          {t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.SUBSCRIBE', { plan: newPlanData.name })}
        </div>
        <div className={styles.price}>
          <div className={styles.amount}>{newPlanData.amountFormatted}</div>
          <div className={styles.desc}>{newPlanData.amountDescription}</div>
        </div>
        <div className={styles.planDesc}>{newPlanData.description}</div>
        {newPlanData.amount && (
          <>
            <div className={styles.subtotal}>
              <div>{t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.SUBTOTAL')}</div>
              <div>{formatPriceAmount(currencyFormatter, newPlanData.amount)}</div>
            </div>
            <div className={styles.vat}>
              <div>{t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.VAT')}</div>
              <div>{formatPriceAmount(currencyFormatter, `${parseInt(newPlanData.amount) * 0.19}`)}</div>
            </div>
            <div className={styles.total}>
              <div>{t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.TOTAL')}</div>
              <div>
                {formatPriceAmount(
                  currencyFormatter,
                  `${parseInt(newPlanData.amount) * 0.19 + parseInt(newPlanData.amount)}`,
                )}
              </div>
            </div>
            {newPlanData.priceId && (
              <Form.Group>
                <Form.Control
                  className={classNames('form-control', styles.promoCode)}
                  placeholder={t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.PROMOTION_CODE')}
                  onChange={(event) => {
                    const value = event.target?.value;
                    setPromotionCode(!value?.length ? null : value.trim());
                    setPromotionCodeInvalid(false);
                  }}
                  isInvalid={promotionCodeInvalid}
                />
                {promotionCodeInvalid && (
                  <Form.Control.Feedback type="invalid">
                    {t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.PROMOTION_CODE_INVALID')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </>
        )}
        <div className={styles.billingDetails}>
          <div className={styles.header}>
            <div className={styles.title}>{t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.BILLING_DETAILS')}</div>
            <div className={styles.update} onClick={triggerSetupSession}>
              {t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.UPDATE')}
            </div>
          </div>
          {!customer.address && !customer?.invoiceSettings?.defaultPaymentMethodObject && (
            <div className={styles.address}>
              {t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.NO_BILLING_DETAILS')}
            </div>
          )}
          {customer.address && (
            <div className={styles.address}>
              {customer.address.line1 && <span>{customer.address.line1}</span>}{' '}
              {customer.address.line2 && <span>{customer.address.line2}</span>}{' '}
              {customer.address.postalCode && <span>{customer.address.postalCode}</span>}{' '}
              {customer.address.city && <span>{customer.address.city}</span>}{' '}
              {customer.address.city && customer.address.country && <span>{customer.address.country}</span>}
            </div>
          )}
          {customer.invoiceSettings.defaultPaymentMethodObject?.card && (
            <div className={styles.paymentMethod}>
              {customer.invoiceSettings.defaultPaymentMethodObject?.card?.brand && (
                <span>{capitalizeFirstLetter(customer.invoiceSettings.defaultPaymentMethodObject?.card?.brand)}</span>
              )}{' '}
              {customer.invoiceSettings.defaultPaymentMethodObject?.card?.last4 && (
                <span>**** {customer.invoiceSettings.defaultPaymentMethodObject?.card?.last4}</span>
              )}
              {customer.invoiceSettings.defaultPaymentMethodObject?.card?.expMonth && (
                <span>
                  , expires {customer.invoiceSettings.defaultPaymentMethodObject?.card?.expMonth?.padStart(2, '0')}
                </span>
              )}{' '}
              {customer.invoiceSettings.defaultPaymentMethodObject?.card?.expYear && (
                <span>{customer.invoiceSettings.defaultPaymentMethodObject?.card?.expYear}</span>
              )}
            </div>
          )}
          {customer.invoiceSettings.defaultPaymentMethodObject?.sepaDebit && (
            <div className={styles.paymentMethod}>
              <div>
                <span>SEPA Direct Debit</span>{' '}
                {customer.invoiceSettings.defaultPaymentMethodObject?.sepaDebit?.last4 && (
                  <span>**** {customer.invoiceSettings.defaultPaymentMethodObject?.sepaDebit?.last4}</span>
                )}
              </div>
            </div>
          )}
          {newPlanData.priceId && (
            <>
              <div className={styles.tax}>{t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.TAX_DETAILS')}</div>
              <Form.Group>
                <Form.Control
                  className={classNames('form-control', styles.taxId)}
                  placeholder={t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.VAT_ID')}
                  onChange={(event) => {
                    const value = event.target?.value;
                    setTaxId(!value?.length ? null : value.trim());
                    setTaxIdInvalid(false);
                  }}
                  value={taxId || ''}
                  isInvalid={taxIdInvalid}
                />
                {taxIdInvalid && (
                  <Form.Control.Feedback type="invalid">
                    {t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.VAT_ID_INVALID')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </>
          )}
        </div>
        {subscribeNote && <div className={styles.subscribeNote}>{subscribeNote}</div>}
        <div className={styles.ctas}>
          <Button variant="primary" onClick={checkout} disabled={processing}>
            {subscriptionExists
              ? t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.CONFIRM_CHANGE_CTA')
              : t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.PROCEED_TO_PAYMENT')}
          </Button>
          <Button variant="outline-primary" onClick={onBackToPlans} disabled={processing}>
            {t('PRIVATE.COMMON.CHANGE_PLAN.CONFIRM_PLAN_CHANGE.BACK_CTA')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
