import { useTranslation } from 'react-i18next';

/**
 * @return a currency formatter that uses the defined in the i18n context locale
 */
export const useLocalisedCurrencyFormatterWithDecimal = (
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  const { i18n } = useTranslation();
  return getLocalisedCurrencyFormatterWithDecimal(i18n.language, currency, currencyDisplay);
};

/**
 * @return a currency formatter that uses the defined in the i18n context locale
 */
export const useLocalisedCurrencyFormatterWithoutDecimal = (
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  const { i18n } = useTranslation();
  return getLocalisedCurrencyFormatterWithoutDecimal(i18n.language, currency, currencyDisplay);
};

/**
 * @return a currency formatter that uses the provided number formatter options
 */
export const getLocalisedCurrencyFormatterWithDecimal = (
  language: string,
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency ? currency : 'EUR',
    currencyDisplay: currencyDisplay,
  });
};

/**
 * @return a currency formatter that uses the provided number formatter options
 */
export const getLocalisedCurrencyFormatterWithoutDecimal = (
  language: string,
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency ? currency : 'EUR',
    currencyDisplay: currencyDisplay,
    // These options are needed to round to whole numbers.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
};

export enum CurrencyDisplay {
  SYMBOL = 'symbol',
  CODE = 'code',
  NAME = 'name',
}
