import React, { Suspense } from 'react';
import '../app/App.module.scss';
import { useTranslation } from 'react-i18next';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ACCOUNT,
  ADMIN_BLACKLIST,
  ADMIN_GROUP,
  ADMIN_GROUPS,
  ADMIN_USER,
  ADMIN_USERS,
  DOCS,
  EMAIL_VERIFICATION,
  GROUP,
  GROUPS,
  HOME,
  HOST,
  LOGIN,
  PASSWORD_RECOVER,
  PASSWORD_RESET,
  PROFILING,
  REGISTER,
  ROOT,
  SUBSCRIPTIONS,
} from '../../utils/routingUtils';
import moment from 'moment';
import EmailVerification from '../emailVerification';
import { UserAndAdminPrivateRoute } from '../private/PrivateRoute';
import PrivateHome from '../private/privateHome';
import PublicRoute from '../public/PublicRoute';
import Root from '../public/root';
import Profiling from '../private/profiling';
import { PspPricesFetcher, PspProductsFetcher } from '../dataFetchers/PspFetcher';

interface AppBodyProps {
  language: string | undefined;
}

/**
 * Defines a Switch that covers all routes(public and for all possible user roles)
 *
 * Note: 1) the user's roles and 2) whether a user is logged in define which routes are allowed to be accessed
 */
export default function AppBody({ language }: AppBodyProps): JSX.Element {
  const { i18n } = useTranslation();

  if (language) {
    i18n.changeLanguage(language);
    moment.locale(language);
  }

  return (
    <>
      <PspProductsFetcher />
      <PspPricesFetcher />

      <Router>
        <Suspense fallback={<div />}>
          <Switch>
            <PublicRoute exact path={ROOT} component={Root} />
            <PublicRoute path={[LOGIN, REGISTER, PASSWORD_RECOVER, PASSWORD_RESET]} component={Root} />
            <UserAndAdminPrivateRoute
              path={[
                HOME,
                HOST,
                ACCOUNT,
                DOCS,
                GROUPS,
                GROUP,
                SUBSCRIPTIONS,
                ADMIN_USERS,
                ADMIN_USER,
                ADMIN_BLACKLIST,
                ADMIN_GROUPS,
                ADMIN_GROUP,
              ]}
              component={PrivateHome}
            />

            <Route path={PROFILING} component={Profiling} />

            {/* email confirmation can happen when a user is logged in and he is not*/}
            <Route path={EMAIL_VERIFICATION} component={EmailVerification} />

            <Route path="*" component={Root} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}
