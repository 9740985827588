import React, {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Group,
  GroupData,
  PlanData,
  PspCustomer,
  PspSubscription,
  PspSubscriptionItem,
  PspSubscriptionSchedule,
  PspSubscriptionStatus,
  RecurringPeriod,
  tablePageSize,
  User,
} from '../../../../types/types.d';
import { useDispatch, useSelector } from 'react-redux';
import { PspSliceState } from '../../../../slices/pspSlice';
import { AuthSliceState, userLoaded } from '../../../../slices/authSlice';
import { useLazyQuery } from '@apollo/client';
import { getCustomer, getGroupData, me } from '../../../../graphql/queries';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  GROUPS,
  REFERRER_KEY,
  SESSION_ID_KEY,
  SUBSCRIPTIONS,
  useURLSearchParams,
} from '../../../../utils/routingUtils';
import { OrderBy, Sorting } from '../../../../types/inputTypes.d';
import orderBy from 'lodash/orderBy';
import lowerFirst from 'lodash/lowerFirst';
import { ModalContext, ModalContextProps } from '../../../../contextProviders/ModalProvider';
import AddOrInviteMember from '../addOrInviteMember';
import DeleteMember from '../deleteMember';
import LeaveGroup from '../leaveGroup';
import { getLocalisedCurrencyFormatterWithoutDecimal } from '../../../../utils/i18nUtils';
import ChangePlan from '../changePlan';
import ConfirmPlanChange from '../changePlan/confirmPlanChange/ConfirmPlanChange';
import SubscriptionsHistory from '../subscriptionsHistory';
import InvoicesHistory from '../invoicesHistory';
import { formatPriceAmount } from '../../../../utils/businessUtils';
import { CondaChannelsViewer } from './condaChannels/CondaChannelsViewer';

export const GroupProviderContext = createContext({});

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting;
  usernameFilter: string;
}

export interface GroupProviderContextProps {
  loading: boolean;
  groupId?: string;
  group: Group;
  isGroupOwner: boolean;
  subscriptions: PspSubscription[];
  subscriptionSchedules: PspSubscriptionSchedule[];
  currentPlanData: PlanData;
  conda: PlanData;
  condaDeployKey: string | null;
  condaUserKey: string | null;

  membersTableState: TableState;
  members: User[];
  membersTotalCount: number;
  membersPageCount: number;
  membersFirstPageShown: boolean;
  membersLastPageShown: boolean;
  onMembersSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onMembersSortingChange: (orderBy: OrderBy) => void;
  onMembersPreviousPage: () => void;
  onMembersNextPage: () => void;

  onBack: () => void;
  onAddOrInviteMember: () => void;
  onMemberToDelete: (user: User) => void;
  onGroupLeave: () => void;
  onMembersChange: () => void;
  onShowPlans: () => void;
  onShowSubscriptionsHistory: () => void;
  onShowCondaKeys: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = PropsWithChildren<any>;

const GroupProvider: (props: Props) => JSX.Element = ({ children }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const referrer = useURLSearchParams().get(REFERRER_KEY);
  const checkoutSessionId = useURLSearchParams().get(SESSION_ID_KEY);

  const [fetchUser] = useLazyQuery<{ me?: User }>(me);
  const [fetchGroupData] = useLazyQuery<{ getGroupData?: GroupData }>(getGroupData);
  const [fetchCustomer, { data: pspCustomer }] = useLazyQuery<{ getCustomer?: PspCustomer }>(getCustomer);

  const { openModal, toggleModal } = useContext(ModalContext) as ModalContextProps;
  const { products, prices } = useSelector((state: { psp: PspSliceState }) => state.psp);
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);

  const [group, setGroup] = useState<Group | null>(null);
  const [subscriptions, setSubscriptions] = useState<PspSubscription[]>([]);
  const [subscriptionSchedules, setSubscriptionSchedules] = useState<PspSubscriptionSchedule[]>([]);
  const [currentPlanData, setCurrentPlanData] = useState<PlanData | null>(null);
  const [condaDeployKey, setCondaDeployKey] = useState<string | null>(null);

  const [members, setMembers] = useState<User[]>([]);

  const [membersTableState, setMembersTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.FirstNameAsc } as Sorting,
    usernameFilter: '',
  });

  const currencyFormatter = useMemo(() => getLocalisedCurrencyFormatterWithoutDecimal(language), [language]);

  // define group from the URL
  const groupId = useMemo(() => {
    if (!location.pathname) return;
    const paths = location.pathname.split('/') || [];
    return paths.length > 2 ? paths[2] : null;
  }, [location.pathname]);
  const isGroupOwner = useMemo(() => group && group.ownerId === user?.id, [group, user?.id]);
  const backToSubscriptions = useMemo(() => !!referrer, [referrer]);

  const currentSubscription = useMemo(
    () => subscriptions.find((s) => s.status == PspSubscriptionStatus.ACTIVE),
    [subscriptions],
  );

  const customer = useMemo(() => pspCustomer?.getCustomer, [pspCustomer?.getCustomer]);
  const condaUserKey = useMemo(() => user?.condaKey || null, [user?.condaKey]);
  const productsById = useMemo(() => new Map((products || []).map((p) => [p.id, p])), [products]);

  const allMembers = useMemo(() => group?.members || [], [group?.members]);
  const membersTotalCount = useMemo(() => allMembers.length, [allMembers.length]);
  const membersPageCount = useMemo(
    () => Math.ceil(membersTotalCount / membersTableState.perPage),
    [membersTableState.perPage, membersTotalCount],
  );
  const membersFirstPageShown = useMemo(() => membersTableState.page === 0, [membersTableState.page]);
  const membersLastPageShown = useMemo(
    () => membersTableState.page === membersPageCount - 1,
    [membersPageCount, membersTableState.page],
  );

  const onHide = useCallback(() => toggleModal(false), [toggleModal]);

  const onMembersChange = useCallback(() => {
    setMembersTableState((prevState) => ({
      ...prevState,
      page: 0,
    }));

    if (groupId) {
      fetchGroupData({ variables: { groupId } }).then((result) => {
        const resultData = result.data?.getGroupData;
        if (resultData) {
          setGroup(resultData.group);
          setSubscriptions(resultData.subscriptions || []);
          setSubscriptionSchedules(resultData.subscriptionSchedules || []);
        }

        // re-fetch user with groups because group's data changed
        fetchUser().then((result) => {
          if (result.data?.me) dispatch(userLoaded(result.data?.me));
        });
      });
    }
  }, [dispatch, fetchGroupData, fetchUser, groupId]);

  const onBack = useCallback(
    () => history.push(backToSubscriptions ? SUBSCRIPTIONS : GROUPS),
    [backToSubscriptions, history],
  );

  const onPlanChanged = useCallback(() => {
    if (groupId) {
      fetchGroupData({ variables: { groupId } }).then((result) => {
        const resultData = result.data?.getGroupData;
        if (resultData) {
          setGroup(resultData.group);
          setSubscriptions(resultData.subscriptions || []);
          setSubscriptionSchedules(resultData.subscriptionSchedules || []);
        }

        // re-fetch user with groups because group's data changed
        fetchUser().then((result) => {
          if (result.data?.me) dispatch(userLoaded(result.data?.me));
        });
      });
    }
  }, [dispatch, fetchGroupData, fetchUser, groupId]);

  const onAddOrInviteMember = useCallback(() => {
    if (!group) return;
    openModal({
      modalContent: (
        <AddOrInviteMember
          onHide={onHide}
          group={group}
          onAdd={() => {
            onMembersChange();
            onHide();
          }}
        />
      ),
      onHide,
    });
  }, [group, onHide, onMembersChange, openModal]);

  const onMemberToDelete = useCallback(
    (user: User) => {
      if (!group) return;
      openModal({
        modalContent: (
          <DeleteMember
            onHide={onHide}
            group={group}
            onDelete={() => {
              onMembersChange();
              onHide();
            }}
            member={user}
          />
        ),
        onHide,
      });
    },
    [group, onHide, onMembersChange, openModal],
  );

  const onGroupLeave = useCallback(() => {
    if (!group) return;
    openModal({
      modalContent: (
        <LeaveGroup
          onHide={onHide}
          group={group}
          onLeave={() => {
            // re-fetch user with groups because group's members count changed
            fetchUser().then((result) => {
              if (result.data?.me) dispatch(userLoaded(result.data?.me));
              onBack();
            });
          }}
        />
      ),
      onHide,
    });
  }, [dispatch, fetchUser, group, onBack, onHide, openModal]);

  const onMembersPreviousPage = useCallback(() => {
    if (!membersFirstPageShown) {
      setMembersTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [membersFirstPageShown]);
  const onMembersNextPage = useCallback(() => {
    if (!membersLastPageShown) {
      setMembersTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [membersLastPageShown]);

  const onMembersSortingChange = useCallback((orderBy: OrderBy) => {
    setMembersTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const onMembersSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setMembersTableState((prevState) => ({
      ...prevState,
      usernameFilter: !!value?.length ? value : '',
    }));
  }, []);

  const onShowConfirmPlanChange = useCallback(
    (planData: PlanData) => {
      if (!group || !currentPlanData || !customer) return;
      openModal({
        modalContent: (
          <ConfirmPlanChange
            onHide={onHide}
            currentPlanData={currentPlanData}
            newPlanData={planData}
            group={group}
            subscriptionExists={!!currentSubscription}
            customer={customer}
            pathname={location.pathname}
            onTaxUpdated={() => {
              fetchCustomer();
            }}
            onPlanChanged={() => {
              onPlanChanged();
              onHide();
            }}
            onBackToPlans={() => {
              // unfortunately there is a circular dependency that's why not using useCallback
              openModal({
                modalContent: (
                  <ChangePlan
                    onHide={onHide}
                    priceId={currentPlanData?.priceId || null}
                    onPlanSelected={(data: PlanData) => onShowConfirmPlanChange(data)}
                  />
                ),
                onHide,
              });
            }}
          />
        ),
        onHide,
      });
    },
    [
      currentPlanData,
      currentSubscription,
      fetchCustomer,
      group,
      location.pathname,
      onHide,
      onPlanChanged,
      openModal,
      customer,
    ],
  );

  const onShowPlans = useCallback(() => {
    openModal({
      modalContent: (
        <ChangePlan
          onHide={onHide}
          priceId={currentPlanData?.priceId || null}
          onPlanSelected={(data: PlanData) => onShowConfirmPlanChange(data)}
        />
      ),
      onHide,
    });
  }, [currentPlanData?.priceId, onHide, onShowConfirmPlanChange, openModal]);

  const onShowInvoices = useCallback(
    (subscriptionId: string) => {
      if (!subscriptions.length) return;
      openModal({
        modalContent: <InvoicesHistory onHide={onHide} subscriptionId={subscriptionId} />,
        onHide,
      });
    },
    [onHide, openModal, subscriptions],
  );

  const onShowSubscriptionsHistory = useCallback(() => {
    if (!subscriptions.length && !subscriptionSchedules.length) return;
    openModal({
      modalContent: (
        <SubscriptionsHistory
          onHide={onHide}
          subscriptions={subscriptions}
          subscriptionSchedules={subscriptionSchedules}
          onViewInvoices={(subscriptionId: string) => onShowInvoices(subscriptionId)}
        />
      ),
      onHide,
    });
  }, [onHide, onShowInvoices, openModal, subscriptionSchedules, subscriptions]);

  const getPlanDescription = useCallback(
    (items: PspSubscriptionItem[]) => {
      if (!items.length) return t('PLANS.FREE.DESCRIPTION');
      const name = productsById.get(items[0].price?.product || '')?.name || '';
      return t(`PLANS.${name.toUpperCase()}.DESCRIPTION`);
    },
    [productsById, t],
  );

  const onShowCondaKeys = useCallback(() => {
    if (!condaDeployKey && !condaUserKey) return;
    openModal({
      modalContent: (
        <CondaChannelsViewer
          onHide={onHide}
          channelName={group?.condaChannel || ''}
          deployKey={condaDeployKey}
          userKey={condaUserKey}
        />
      ),
      onHide,
    });
  }, [condaDeployKey, condaUserKey, group?.condaChannel, onHide, openModal]);

  useEffect(() => {
    if (groupId) {
      fetchGroupData({ variables: { groupId, checkoutSessionId } }).then((result) => {
        const resultData = result.data?.getGroupData;
        if (resultData) {
          setGroup(resultData.group);
          setSubscriptions(resultData.subscriptions || []);
          setSubscriptionSchedules(resultData.subscriptionSchedules || []);
          setCondaDeployKey(resultData.group.condaKey || null);
        }

        if (checkoutSessionId) {
          // re-fetch user with groups because group's subscription changed
          fetchUser().then((result) => {
            if (result.data?.me) dispatch(userLoaded(result.data?.me));
          });
        }
      });
    }
  }, [dispatch, fetchGroupData, fetchUser, groupId, checkoutSessionId]);

  useEffect(() => {
    fetchCustomer({ variables: { checkoutSessionId } });
  }, [checkoutSessionId, fetchCustomer]);

  useEffect(() => {
    // find current active subscription if there is one
    // set no price and "Free" product if there is no currently active subscription
    // otherwise define the price id and product name
    const currentPrice = !!currentSubscription?.items?.length ? currentSubscription?.items[0]?.price : null;
    const name = productsById.get(currentPrice?.product || '')?.name;
    const description = getPlanDescription(currentSubscription?.items || []);
    const amount = !currentPrice ? '0' : currentPrice.amount || '';
    const amountFormatted = !currentPrice
      ? currencyFormatter.format(0)
      : formatPriceAmount(currencyFormatter, currentPrice?.amount) || '';
    const amountDescription = !currentPrice
      ? ''
      : `${t('COMMON.PER_USER')}/${
          currentPrice.recurringPeriod === RecurringPeriod.MONTH ? t('COMMON.MONTH') : t('COMMON.YEAR')
        }`;
    const cancelledAt = currentSubscription?.cancelledAt || null;

    const planData: PlanData = {
      name: name || t('PLANS.FREE.NAME'),
      description,
      priceId: currentPrice?.id || null,
      amount,
      amountFormatted,
      amountDescription,
      cancelledAt,
    };
    setCurrentPlanData(planData);
  }, [
    currencyFormatter,
    currentSubscription?.cancelledAt,
    currentSubscription?.items,
    getPlanDescription,
    group?.plan,
    productsById,
    t,
  ]);

  useEffect(() => {
    // sort and filter
    const orderByValue = membersTableState.sorting.orderBy;
    const sortField = orderByValue.toLowerCase().endsWith('asc')
      ? orderByValue.substring(0, orderByValue.length - 3)
      : orderByValue.substring(0, orderByValue.length - 4);
    const asc = orderByValue.toLowerCase().endsWith('asc');

    const start = membersTableState.page * membersTableState.perPage;
    const end =
      start + membersTableState.perPage > allMembers.length ? allMembers.length : start + membersTableState.perPage;
    setMembers(
      orderBy(allMembers.slice(start, end), [lowerFirst(sortField)], [asc ? 'asc' : 'desc']).filter((m) =>
        m.techUsername.includes(membersTableState.usernameFilter),
      ),
    );
  }, [
    allMembers,
    membersTableState.page,
    membersTableState.perPage,
    membersTableState.sorting.orderBy,
    membersTableState.usernameFilter,
  ]);

  console.log({ currentPlanData });

  const value = {
    loading: !group || !products?.length || !prices?.length,
    groupId,
    group,
    isGroupOwner,
    subscriptions,
    subscriptionSchedules,
    currentPlanData,
    condaDeployKey,
    condaUserKey,

    membersTableState,
    members,
    membersTotalCount,
    membersPageCount,
    membersFirstPageShown,
    membersLastPageShown,
    onMembersSearch,
    onMembersSortingChange,
    onMembersPreviousPage,
    onMembersNextPage,

    onBack,
    onAddOrInviteMember,
    onMemberToDelete,
    onGroupLeave,
    onMembersChange,
    onShowPlans,
    onShowSubscriptionsHistory,
    onShowCondaKeys,
  } as GroupProviderContextProps;

  return <GroupProviderContext.Provider value={value}>{children}</GroupProviderContext.Provider>;
};

export default GroupProvider;
