/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Outputs console statements only if it's allowed via the ENV variable.
 */
export default {
  log(...args: any[]): void {
    const allowedLogs = (process.env.REACT_APP_ALLOWED_LOGS || '').split(',');
    if (allowedLogs.includes('log')) {
      console.log(...args);
    }
  },
  warn(...args: any[]): void {
    const allowedLogs = (process.env.REACT_APP_ALLOWED_LOGS || '').split(',');
    if (allowedLogs.includes('warn')) {
      console.warn(...args);
    }
  },
  error(...args: any[]): void {
    const allowedLogs = (process.env.REACT_APP_ALLOWED_LOGS || '').split(',');
    if (allowedLogs.includes('error')) {
      console.error(...args);
    }
  },
};
