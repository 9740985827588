import React, { useMemo } from 'react';
import './App.module.scss';
import { User } from '../../types/types';
import { useQuery } from '@apollo/client';
import { me } from '../../graphql/queries';
import { useDispatch } from 'react-redux';
import { userLoaded } from '../../slices/authSlice';
import logger from '../../utils/logger/logger';
import AppBody from '../appBody';
import { BlacklistedUsernamesFetcher } from '../dataFetchers/BlacklistedUsernamesFetcher';
import { userHasAdminRole } from '../../utils/userUtils';
import ModalProvider from '../../contextProviders/ModalProvider';
import TooltipProvider from '../../contextProviders/TooltipProvider';
import Tooltip from '../common/Tooltip';
import Modal from '../common/Modal';

/**
 * - Checks currently logged in user by sending a GraphQL request.
 * - User preferred language is checked and set if present.
 *
 * Note: 1) the user's roles and 2) whether a user is logged in define which routes are allowed to be accessed
 */
export default function App(): JSX.Element {
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery<{ me?: User }>(me);
  const user = useMemo(() => data?.me, [data?.me]);
  const isAdmin = useMemo(() => userHasAdminRole(user), [user]);

  if (loading) return <></>;

  let language;
  if (error) {
    logger.error(`error loading logged in user: ${error.message}`);
  } else {
    if (user) {
      language = user.languageIso6391;
      dispatch(userLoaded(user));
    }
    logger.log(`User loaded = ${user != undefined}`);
  }

  return (
    <>
      {isAdmin && <BlacklistedUsernamesFetcher />}

      <TooltipProvider>
        <ModalProvider>
          <AppBody language={language} />
          <Modal />
          <Tooltip />
        </ModalProvider>
      </TooltipProvider>
    </>
  );
}
