import { CSSProperties } from 'react';

export const primary = '#27B4FF';
export const secondary = '#57F4C6';
export const dark = '#0F1652';
export const selectionColor = 'rgba(64, 157, 244, 0.05)';

export const tooltipStyle = {
  textAlign: 'start',
  fontSize: '.8rem',
} as CSSProperties;
