import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ModalContext, ModalContextProps } from '../../contextProviders/ModalProvider';

const ModalPortal = (): JSX.Element | null => {
  const { t } = useTranslation();

  const DEFAULT_VARIANT_CANCEL = 'secondary';
  const DEFAULT_VARIANT_SUBMIT = 'primary';
  const DEFAULT_TITLE_CANCEL = t('COMMON.CANCEL');
  const DEFAULT_TITLE_SUBMIT = t('COMMON.SAVE');

  const {
    context = true,
    isOpened,
    modalContent,
    headerContent,
    bodyContent,
    footerContent,
    toggleModal,
    onHide,
    onSubmit,
    onCancel,
    options: {
      modal: { className: modalClassName = '' } = {},
      header: { title: headerTitle = '', closeButton = true, className: headerClassName = '' } = {},
      body: { className: bodyClassName = '' } = {},
      footer: {
        titleCancel = DEFAULT_TITLE_CANCEL,
        variantCancel = DEFAULT_VARIANT_CANCEL,
        showCancel = true,
        titleSubmit = DEFAULT_TITLE_SUBMIT,
        variantSubmit = DEFAULT_VARIANT_SUBMIT,
        showSubmit = true,
        className: footerClassName = {},
      } = {},
    } = {},
  } = useContext(ModalContext) as ModalContextProps;

  if (!context || !isOpened) {
    return null;
  }

  if (modalContent) {
    return ReactDOM.createPortal(modalContent, context);
  }

  const handlerHide = () => toggleModal(false);

  const defaultHeaderContent = <Modal.Title>{headerTitle}</Modal.Title>;

  const defaultBodyContent = <></>;

  const defaultFooterContent = (
    <>
      {showCancel && (
        <Button variant={variantCancel} onClick={onCancel || onHide}>
          {titleCancel}
        </Button>
      )}
      {showSubmit && (
        <Button variant={variantSubmit} onClick={onSubmit}>
          {titleSubmit}
        </Button>
      )}
    </>
  );

  return ReactDOM.createPortal(
    <Modal show={isOpened} onHide={onHide || handlerHide} animation={false} className={modalClassName}>
      <Modal.Header closeButton={closeButton} className={headerClassName}>
        {headerContent || defaultHeaderContent}
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{bodyContent || defaultBodyContent}</Modal.Body>
      <Modal.Footer className={footerClassName}>{footerContent || defaultFooterContent}</Modal.Footer>
    </Modal>,
    context,
  );
};

export default ModalPortal;
