/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuPlacement, OptionTypeBase } from 'react-select/src/types';
import { StylesConfig } from 'react-select/src/styles';
import { CSSObject } from '@emotion/serialize';
import Select, { components, IndicatorProps, OptionProps } from 'react-select';
import { primary, selectionColor } from '../../../../utils/styleUtils';
import { ReactComponent as DownIcon } from '../../../../../src/assets/img/down.svg';
import { OS } from '../../../../types/types.d';
import { getLocalisedOs } from '../../../../utils/stringUtils';

interface OptionType extends OptionTypeBase {
  value: OS;
  label: string;
}

const customStyles = <OptionType extends OptionTypeBase, IsMulti extends boolean>(): StylesConfig<
  OptionType,
  IsMulti
> => {
  return {
    container: (base: CSSObject) => ({
      ...base,
      background: 'inherit',
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      cursor: 'pointer',
    }),
    control: (base: CSSObject) => ({
      ...base,
      background: 'none',
      border: 'inherit',
      fontSize: 'inherit',
      boxShadow: 'inherit',
      cursor: 'inherit',
      paddingLeft: '1rem',
      paddingRight: '0.7rem',
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      minHeight: 'none',
    }),
    valueContainer: (base: CSSObject) => ({
      ...base,
      paddingLeft: '0',
      paddingRight: '0',
    }),
    placeholder: (base: CSSObject) => ({
      ...base,
      margin: '0',
    }),
    singleValue: (base: CSSObject) => ({
      ...base,
      marginLeft: 0,
      marginRight: 0,
      color: 'inherit',
      opacity: '0.8',
    }),
    multiValue: (base: CSSObject) => ({
      ...base,
      borderRadius: '.3rem',
    }),
    indicatorsContainer: (base: CSSObject) => ({
      ...base,
      height: 'inherit',
    }),
    dropdownIndicator: (base: CSSObject) => ({
      ...base,
      paddingRight: '0',
    }),
    indicatorSeparator: (base: CSSObject) => ({
      ...base,
      display: 'none',
    }),
    menu: (base: CSSObject) => ({
      ...base,
      borderRadius: '.5rem',
      boxShadow: '5px 5px 21px rgba(39, 42, 53, 0.1)',
      border: '1px solid rgba(39, 42, 53, 0.03)',
    }),
    menuList: (base: CSSObject) => ({
      ...base,
      maxHeight: '100%',
    }),
    option: (base: CSSObject, props: OptionProps<OptionType, IsMulti>) => {
      const isFocused = props.isFocused;
      const isSelected = props.isSelected;

      return {
        ...base,
        display: 'flex',
        alignItems: 'end',
        whiteSpace: 'break-spaces',
        opacity: '.8',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        cursor: 'pointer',
        color: isSelected ? primary : '',
        backgroundColor: isFocused ? selectionColor : '',
      };
    },
  };
};

interface Props {
  externallyManagedSelectedValues: boolean;
  selectedValues: OS[] | null;
  onSelect: (values: OS[]) => void;
  controlShouldRenderValue: boolean;
  isMulti: boolean;
  isSearchable: boolean;
  menuIsOpen?: boolean;
  closeMenuOnSelect: boolean;
  placeholder?: string;
  hideDropdownIndicator?: boolean;
  menuPlacement?: MenuPlacement;
}

export default function OsSelector({
  externallyManagedSelectedValues,
  selectedValues,
  onSelect,
  controlShouldRenderValue,
  isMulti,
  isSearchable,
  menuIsOpen,
  closeMenuOnSelect,
  placeholder,
  hideDropdownIndicator,
  menuPlacement,
}: Props): JSX.Element {
  const [selectedCount, setSelectedCount] = useState(selectedValues?.length || 0);
  useEffect(() => setSelectedCount(selectedValues?.length || 0), [selectedValues?.length]);

  const getLabel = useCallback((value: OS) => getLocalisedOs(value), []);

  const allOptions: OptionType[] = [OS.WINDOWS, OS.LINUX].map((value) => ({
    value: value,
    label: getLabel(value),
  }));

  const selectedOptions = useMemo(
    () =>
      selectedValues
        ? selectedValues.map(
            (i) =>
              ({
                value: i,
                label: getLabel(i),
              } as OptionType),
          )
        : null,
    [getLabel, selectedValues],
  );

  const handleSelection = useCallback(
    (values: OS[]) => {
      setSelectedCount(values.length);
      onSelect(values);
    },
    [onSelect],
  );

  const handleOnChange = useCallback(
    (value) => {
      if (value !== null) {
        if (Array.isArray(value)) {
          // multiple values selected
          handleSelection(value.map((option) => option.value));
        } else {
          // single value selected
          const option = value as OptionType;
          handleSelection([option.value]);
        }
      }
    },
    [handleSelection],
  );

  // const placeholderValue = useMemo(() => {
  //   if (selectedCount === 0) {
  //     // no selected items
  //     return placeholder || '';
  //   } else if (selectedCount === allOptions.length) {
  //     // all available items selected
  //     return t('OS_SELECTOR.ALL_SELECTED');
  //   } else {
  //     // some of the available items selected
  //     return selectedCount === 1
  //       ? t('OS_SELECTOR.ONE_SELECTED')
  //       : t('OS_SELECTOR.SOME_SELECTED', {
  //           selected: selectedCount,
  //           total: allOptions.length,
  //         });
  //   }
  // }, [selectedCount, placeholder, allOptions.length, t]);

  // the components in a constant solve the problem of jumping to the top dropdown when navigating away from it
  const selectComponent = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      DropdownIndicator(props: IndicatorProps<OptionType, typeof isMulti>) {
        return hideDropdownIndicator ? (
          <></>
        ) : (
          <components.DropdownIndicator {...props}>
            <DownIcon />
          </components.DropdownIndicator>
        );
      },
      Option(props: OptionProps<OptionType, typeof isMulti>) {
        const label = props.data.label;
        const content = <span>{label}</span>;
        return <components.Option {...props}>{content}</components.Option>;
      },
    }),
    [hideDropdownIndicator],
  );

  return (
    <Select
      styles={customStyles<OptionType, typeof isMulti>()}
      // placeholder={placeholderValue || ''}
      isMulti={isMulti}
      isClearable={false}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement || 'auto'}
      hideSelectedOptions={false}
      controlShouldRenderValue={controlShouldRenderValue}
      closeMenuOnSelect={closeMenuOnSelect}
      name="osSelector"
      value={externallyManagedSelectedValues ? selectedOptions : undefined}
      defaultValue={externallyManagedSelectedValues ? undefined : selectedOptions}
      options={allOptions}
      onChange={handleOnChange}
      components={selectComponent}
    />
  );
}
