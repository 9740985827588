import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Groups.module.scss';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftIcon } from '../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../assets/img/right.svg';
import { ReactComponent as SortIcon } from '../../../assets/img/sort.svg';
import { ReactComponent as OptionsIcon } from '../../../assets/img/options.svg';
import classNames from 'classnames';
import { OrderBy, Sorting } from '../../../types/inputTypes.d';
import { Group, tablePageSize, User } from '../../../types/types.d';
import { primary } from '../../../utils/styleUtils';
import DeleteGroup from './deleteGroup';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSliceState, userLoaded } from '../../../slices/authSlice';
import AddGroup from './addGroup';
import { useLazyQuery } from '@apollo/client';
import { me } from '../../../graphql/queries';
import orderBy from 'lodash/orderBy';
import lowerFirst from 'lodash/lowerFirst';
import { GROUPS } from '../../../utils/routingUtils';
import { useHistory } from 'react-router';
import RenameGroup from './renameGroup';
import LeaveGroup from '../common/leaveGroup';

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting;
  nameFilter: string;
}

/**
 * Groups page where an admin can see list of groups and manage them
 */
export default function Groups(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [fetchUser] = useLazyQuery<{ me?: User }>(me);
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const [groupToDelete, setGroupToDelete] = useState<Group | null>(null);
  const [groupToLeave, setGroupToLeave] = useState<Group | null>(null);
  const [groupToRename, setGroupToRename] = useState<Group | null>(null);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [openedOptions, setOpenedOptions] = useState<string | null>(null);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
    nameFilter: '',
  });

  const allGroups = useMemo(() => user?.groups || [], [user?.groups]);
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    // sort and filter
    const orderByValue = tableState.sorting.orderBy;
    const sortField = orderByValue.toLowerCase().endsWith('asc')
      ? orderByValue.substring(0, orderByValue.length - 3)
      : orderByValue.substring(0, orderByValue.length - 4);
    const asc = orderByValue.toLowerCase().endsWith('asc');

    const start = tableState.page * tableState.perPage;
    const end = start + tableState.perPage > allGroups.length ? allGroups.length : start + tableState.perPage;
    setGroups(
      orderBy(allGroups.slice(start, end), [lowerFirst(sortField)], [asc ? 'asc' : 'desc']).filter((g) =>
        g.name.includes(tableState.nameFilter),
      ),
    );
  }, [allGroups, tableState.nameFilter, tableState.page, tableState.perPage, tableState.sorting.orderBy]);

  const totalCount = useMemo(() => allGroups.length, [allGroups.length]);
  const pageCount = useMemo(() => Math.ceil(totalCount / tableState.perPage), [tableState.perPage, totalCount]);
  const firstPageShown = useMemo(() => tableState.page === 0, [tableState.page]);
  const lastPageShown = useMemo(() => tableState.page === pageCount - 1, [pageCount, tableState.page]);

  const handlePreviousPage = useCallback(() => {
    if (!firstPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [firstPageShown]);
  const handleNextPage = useCallback(() => {
    if (!lastPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [lastPageShown]);

  const handleSorting = useCallback((orderBy: OrderBy) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setTableState((prevState) => ({
      ...prevState,
      nameFilter: !!value?.length ? value : '',
    }));
  }, []);

  const handleGroupAction = useCallback(() => {
    setTableState((prevState) => ({
      ...prevState,
      page: 0,
    }));

    fetchUser().then((result) => {
      const newUser = result?.data?.me;
      if (newUser) {
        return dispatch(userLoaded(newUser));
      }
    });

    // close modal
    setGroupToDelete(null);
    setGroupToLeave(null);
    setGroupToRename(null);
    setShowAddGroup(false);
  }, [dispatch, fetchUser]);

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          {
            order: OrderBy.CreatedDateDesc,
            title: t('PRIVATE.GROUPS.SORTING.CREATED_DATE_DESC'),
          },
          { order: OrderBy.CreatedDateAsc, title: t('PRIVATE.GROUPS.SORTING.CREATED_DATE_ASC') },
          { order: OrderBy.NameDesc, title: t('PRIVATE.GROUPS.SORTING.NAME_ASC') },
          { order: OrderBy.NameAsc, title: t('PRIVATE.GROUPS.SORTING.NAME_DESC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === tableState.sorting?.orderBy ? primary : '' }}
            onClick={() => handleSorting(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  const openGroupDetails = useCallback((group: Group) => history.push(`${GROUPS}/${group.id}`), [history]);

  return (
    <>
      {groupToDelete && (
        <DeleteGroup
          show={!!groupToDelete}
          onHide={() => setGroupToDelete(null)}
          group={groupToDelete}
          onDelete={handleGroupAction}
        />
      )}
      {groupToLeave && (
        <LeaveGroup onHide={() => setGroupToLeave(null)} group={groupToLeave} onLeave={handleGroupAction} />
      )}
      {groupToRename && (
        <RenameGroup
          show={!!groupToRename}
          onHide={() => setGroupToRename(null)}
          group={groupToRename}
          onRename={handleGroupAction}
        />
      )}
      <AddGroup show={showAddGroup} onHide={() => setShowAddGroup(false)} onAdd={handleGroupAction} />
      <Container className="box full-h p-5">
        <div className={styles.header}>
          <div>
            <h1 className="flex-grow-1">{t('PRIVATE.GROUPS.GROUPS')}</h1>
          </div>
          <div>
            <Button variant="primary" onClick={() => setShowAddGroup(true)}>
              {t('PRIVATE.GROUPS.ADD_GROUP.TITLE')}
            </Button>
          </div>
        </div>
        <Row>
          <Col>
            <div className={styles.toolbar}>
              <div className={styles.sortAndSearch}>
                {buttonSorting}
                <input
                  className={styles.search}
                  placeholder={t('PRIVATE.GROUPS.SEARCH_BY_NAME')}
                  onChange={handleSearch}
                />
              </div>
              <div className={styles.paginationInfo}>
                {totalCount > 0 &&
                  t('PRIVATE.GROUPS.PAGINATION_INFO', {
                    start: tableState.page * tableState.perPage + 1,
                    end:
                      totalCount < (tableState.page + 1) * tableState.perPage
                        ? totalCount
                        : (tableState.page + 1) * tableState.perPage,
                    total: totalCount,
                  })}
                {pageCount > 1 && (
                  <div className={styles.previousNext}>
                    <div
                      className={styles.previous}
                      style={{
                        opacity: firstPageShown ? 0.2 : 0.8,
                        cursor: firstPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handlePreviousPage()}
                    >
                      <LeftIcon />
                    </div>
                    <div
                      className={styles.next}
                      style={{
                        opacity: lastPageShown ? 0.2 : 0.8,
                        cursor: lastPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handleNextPage()}
                    >
                      <RightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {totalCount === 0 && <div className={styles.noResults}>{t('PRIVATE.GROUPS.NO_GROUPS_FOUND')}</div>}
              {totalCount > 0 && (
                <>
                  <div className={classNames('data-row', styles.tableHeader)}>
                    <div className={styles.name}>{t('PRIVATE.GROUPS.NAME')}</div>
                    <div className={styles.role}>{t('PRIVATE.GROUPS.ROLE')}</div>
                    <div className={styles.membersCount}>{t('PRIVATE.GROUPS.MEMBERS_COUNT')}</div>
                    <div className={styles.plan}>{t('PRIVATE.GROUPS.PLAN')}</div>
                    <div className={styles.placeholderOptions} />
                  </div>
                  {groups.map((item, index) => {
                    const owner = item.ownerId === user?.id;
                    return (
                      <div
                        key={index}
                        className={classNames('data-row', styles.row)}
                        style={{ cursor: 'pointer' }}
                        onClick={() => openGroupDetails(item)}
                      >
                        <div className={styles.name}>{item.name}</div>
                        <div className={styles.role}>
                          {owner ? t('PRIVATE.GROUPS.ROLE_OWNER') : t('PRIVATE.GROUPS.ROLE_DEVELOPER')}
                        </div>
                        <div className={styles.membersCount}>{item.membersCount}</div>
                        <div className={styles.plan}>{item.plan || t('PRIVATE.GROUPS.PLAN_FREE')}</div>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Dropdown
                            onToggle={(isOpen) => {
                              if (isOpen) setOpenedOptions(item.id);
                              else setOpenedOptions(null);
                            }}
                            show={openedOptions === item.id}
                          >
                            <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                              <OptionsIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu alignRight={true}>
                              {owner ? (
                                <>
                                  {/*<Dropdown.Item onClick={() => setGroupToRename(item)}>*/}
                                  {/*  {t('PRIVATE.GROUPS.OPTIONS.RENAME_GROUP')}*/}
                                  {/*</Dropdown.Item>*/}
                                  <Dropdown.Item onClick={() => setGroupToDelete(item)}>
                                    {t('PRIVATE.GROUPS.OPTIONS.DELETE_GROUP')}
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <Dropdown.Item onClick={() => setGroupToLeave(item)}>
                                  {t('PRIVATE.GROUPS.OPTIONS.LEAVE_GROUP')}
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
