import React, { useContext } from 'react';
import styles from './Group.module.scss';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftIcon } from '../../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../../assets/img/right.svg';
import { ReactComponent as SortIcon } from '../../../../assets/img/sort.svg';
import { ReactComponent as OptionsIcon } from '../../../../assets/img/options.svg';
import classNames from 'classnames';
import { OrderBy } from '../../../../types/inputTypes.d';
import { primary } from '../../../../utils/styleUtils';
import { Redirect } from 'react-router-dom';
import arrowBack from '../../../../assets/img/arrow-back.svg';
import { GROUPS } from '../../../../utils/routingUtils';
import { getFullName } from '../../../../utils/userUtils';
import PlanAndBilling from './planAndBilling';
import { Loading } from '../../../utils/Loading';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import CondaChannels from './condaChannels';
import GroupProvider, { GroupProviderContext, GroupProviderContextProps } from './GroupProvider';

/**
 * Group details page
 */
export const Group = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    loading,
    groupId,
    group,
    isGroupOwner,
    onMembersSortingChange,
    onMembersSearch,
    membersTableState,
    membersTotalCount,
    membersPageCount,
    membersFirstPageShown,
    membersLastPageShown,
    onMembersPreviousPage,
    onMembersNextPage,
    members,
    onBack,
    onAddOrInviteMember,
    onGroupLeave,
    onMemberToDelete,
  } = useContext(GroupProviderContext) as GroupProviderContextProps;

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          { order: OrderBy.FirstNameAsc, title: t('PRIVATE.COMMON.GROUP.SORTING.FIRST_NAME_ASC') },
          { order: OrderBy.FirstNameDesc, title: t('PRIVATE.COMMON.GROUP.SORTING.FIRST_NAME_DESC') },
          { order: OrderBy.LastNameAsc, title: t('PRIVATE.COMMON.GROUP.SORTING.LAST_NAME_ASC') },
          { order: OrderBy.LastNameDesc, title: t('PRIVATE.COMMON.GROUP.SORTING.LAST_NAME_DESC') },
          { order: OrderBy.TechUsernameAsc, title: t('PRIVATE.COMMON.GROUP.SORTING.USERNAME_ASC') },
          { order: OrderBy.TechUsernameDesc, title: t('PRIVATE.COMMON.GROUP.SORTING.USERNAME_DESC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === membersTableState.sorting?.orderBy ? primary : '' }}
            onClick={() => onMembersSortingChange(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  if (!groupId) {
    return <Redirect to={GROUPS} />;
  }

  return (
    <Container>
      {!loading ? (
        <>
          <header className={styles.header}>
            <div className={styles.start}>
              <img alt="back" className="mr-4 arrowback" src={arrowBack} onClick={onBack} />
              <div className={styles.title}>{capitalizeFirstLetter(group?.name)}</div>
            </div>
            <div>
              {isGroupOwner ? (
                <Button variant="primary" onClick={onAddOrInviteMember}>
                  {t('PRIVATE.COMMON.GROUP.INVITE_MEMBER_CTA')}
                </Button>
              ) : (
                <Button variant="outline-primary" onClick={onGroupLeave}>
                  {t('PRIVATE.COMMON.GROUP.LEAVE_GROUP_CTA')}
                </Button>
              )}
            </div>
          </header>
          <Row>
            <Col>
              <div className={classNames('box', styles.members)}>
                <div className={styles.header}>{t('PRIVATE.COMMON.GROUP.MEMBERS')}</div>
                <div className={styles.toolbar}>
                  <div className={styles.sortAndSearch}>
                    {buttonSorting}
                    <input
                      className={styles.search}
                      placeholder={t('PRIVATE.COMMON.GROUP.SEARCH_BY_EMAIL')}
                      onChange={onMembersSearch}
                    />
                  </div>
                  <div className={styles.paginationInfo}>
                    {membersTotalCount > 0 &&
                      t('PRIVATE.COMMON.GROUP.PAGINATION_INFO', {
                        start: membersTableState.page * membersTableState.perPage + 1,
                        end:
                          membersTotalCount < (membersTableState.page + 1) * membersTableState.perPage
                            ? membersTotalCount
                            : (membersTableState.page + 1) * membersTableState.perPage,
                        total: membersTotalCount,
                      })}
                    {membersPageCount > 1 && (
                      <div className={styles.previousNext}>
                        <div
                          className={styles.previous}
                          style={{
                            opacity: membersFirstPageShown ? 0.2 : 0.8,
                            cursor: membersFirstPageShown ? 'default' : 'pointer',
                          }}
                          onClick={onMembersPreviousPage}
                        >
                          <LeftIcon />
                        </div>
                        <div
                          className={styles.next}
                          style={{
                            opacity: membersLastPageShown ? 0.2 : 0.8,
                            cursor: membersLastPageShown ? 'default' : 'pointer',
                          }}
                          onClick={onMembersNextPage}
                        >
                          <RightIcon />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {membersTotalCount === 0 && (
                    <div className={styles.noResults}>{t('PRIVATE.COMMON.GROUP.NO_MEMBERS_FOUND')}</div>
                  )}
                  {membersTotalCount > 0 && (
                    <>
                      <div className={classNames('data-row', styles.tableHeader)}>
                        <div className={styles.name}>{t('PRIVATE.COMMON.GROUP.NAME')}</div>
                        <div className={styles.username}>{t('PRIVATE.COMMON.GROUP.USERNAME')}</div>
                        <div className={styles.role}>{t('PRIVATE.COMMON.GROUP.ROLE')}</div>
                        <div className={styles.placeholderOptions} />
                      </div>
                      {members.map((item, index) => {
                        const owner = group && group.ownerId === item.id;
                        return (
                          <div key={index} className={classNames('data-row', styles.row)}>
                            <div className={styles.name}>{getFullName(item)}</div>
                            <div className={styles.username}>{item.techUsername}</div>
                            <div className={styles.role}>
                              {owner ? t('GROUP_MEMBER_ROLE.OWNER') : t('GROUP_MEMBER_ROLE.DEVELOPER')}
                            </div>
                            {owner || !isGroupOwner ? (
                              <div className={styles.placeholderOptions} />
                            ) : (
                              <div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="link"
                                    className={classNames('row-functions', styles.options)}
                                  >
                                    <OptionsIcon />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu alignRight>
                                    <Dropdown.Item onClick={() => onMemberToDelete(item)}>
                                      {t('PRIVATE.COMMON.GROUP.DELETE_FROM_GROUP_CTA')}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </Col>

            <Col sm="3">
              <PlanAndBilling />
              <br />
              <CondaChannels />
            </Col>
          </Row>
        </>
      ) : (
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      )}
    </Container>
  );
};

export default function GroupWrapper(): JSX.Element {
  return (
    <GroupProvider>
      <Group />
    </GroupProvider>
  );
}
