import React, { useContext } from 'react';
import styles from './CondaChannels.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { GroupProviderContext, GroupProviderContextProps } from '../GroupProvider';

export default function CondaChannels(): JSX.Element {
  const { t } = useTranslation();
  const { condaUserKey, onShowCondaKeys, group } = useContext(GroupProviderContext) as GroupProviderContextProps;

  return (
    <>
      <div className={classNames('box', styles.content)}>
        <div className={styles.header}>{t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.TITLE')}</div>
        <div className={styles.channels}>
          {condaUserKey && group.condaChannel ? (
            <div className={styles.cta} onClick={onShowCondaKeys}>
              {t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.VIEW_DATA')}
            </div>
          ) : (
            <div className={styles.noData}>{t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.NO_DATA')}</div>
          )}
        </div>
      </div>
    </>
  );
}
