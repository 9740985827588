import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AdminGroups.module.scss';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftIcon } from '../../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../../assets/img/right.svg';
import { ReactComponent as SortIcon } from '../../../../assets/img/sort.svg';
import classNames from 'classnames';
import moment from 'moment';
import { GroupSearchCriteria, OrderBy, SearchPaging, Sorting } from '../../../../types/inputTypes.d';
import { getGroups } from '../../../../graphql/queries';
import { Group, Groups, tablePageSize } from '../../../../types/types.d';
import { primary } from '../../../../utils/styleUtils';
import { Loading } from '../../../utils/Loading';
import { DATE_TIME_FORMAT } from '../../../../utils/dateTimeUtils';
import DeleteGroup from './deleteGroup';
import { ReactComponent as OptionsIcon } from '../../../../assets/img/options.svg';
import RenameGroup from './renameGroup';
import { ADMIN_GROUPS } from '../../../../utils/routingUtils';
import { useHistory } from 'react-router';

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting | null;
  nameFilter: string;
}

/**
 * AdminGroups page where an admin can see list of groups and manage them
 */
export default function AdminGroups(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [groupToDelete, setGroupToDelete] = useState<Group | null>(null);
  const [groupToRename, setGroupToRename] = useState<Group | null>(null);
  const [openedOptions, setOpenedOptions] = useState<string | null>(null);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
    nameFilter: '',
  });

  const [fetchGroups, { data, loading }] = useLazyQuery<{ getGroups: Groups }>(getGroups);
  const doFetchGroups = useCallback(() => {
    fetchGroups({
      variables: {
        paging: {
          page: tableState.page,
          perPage: tableState.perPage,
        } as SearchPaging,
        sorting: tableState.sorting,
        searchCriteria: {
          name: tableState.nameFilter,
        } as GroupSearchCriteria,
      },
    });
  }, [fetchGroups, tableState]);
  useEffect(() => doFetchGroups(), [doFetchGroups]);

  const groups: Group[] = useMemo(() => data?.getGroups?.groups || [], [data?.getGroups?.groups]);
  const totalCount = useMemo(() => data?.getGroups?.paging?.totalCount || 0, [data?.getGroups?.paging?.totalCount]);
  const pageCount = useMemo(() => data?.getGroups?.paging?.pageCount || 0, [data?.getGroups?.paging?.pageCount]);

  const firstPageShown = useMemo(() => tableState.page === 0, [tableState.page]);
  const lastPageShown = useMemo(() => tableState.page === pageCount - 1, [pageCount, tableState.page]);

  const handlePreviousPage = useCallback(() => {
    if (!firstPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [firstPageShown]);
  const handleNextPage = useCallback(() => {
    if (!lastPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [lastPageShown]);

  const handleSorting = useCallback((orderBy: OrderBy) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setTableState((prevState) => ({
      ...prevState,
      nameFilter: !!value?.length ? value : '',
    }));
  }, []);

  const handleGroupAction = useCallback(() => {
    setTableState((prevState) => ({
      ...prevState,
      page: 0,
    }));
    doFetchGroups();
    // close modal
    setGroupToDelete(null);
    setGroupToRename(null);
  }, [doFetchGroups]);

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          {
            order: OrderBy.CreatedDateDesc,
            title: t('PRIVATE.ADMIN.GROUPS.SORTING.CREATED_DATE_DESC'),
          },
          { order: OrderBy.CreatedDateAsc, title: t('PRIVATE.ADMIN.GROUPS.SORTING.CREATED_DATE_ASC') },
          { order: OrderBy.NameAsc, title: t('PRIVATE.ADMIN.GROUPS.SORTING.NAME_ASC') },
          { order: OrderBy.NameDesc, title: t('PRIVATE.ADMIN.GROUPS.SORTING.NAME_DESC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === tableState.sorting?.orderBy ? primary : '' }}
            onClick={() => handleSorting(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  const openGroupDetails = useCallback((group: Group) => history.push(`${ADMIN_GROUPS}/${group.id}`), [history]);

  return (
    <>
      {groupToDelete && (
        <DeleteGroup
          show={!!groupToDelete}
          onHide={() => setGroupToDelete(null)}
          group={groupToDelete}
          onDelete={handleGroupAction}
        />
      )}
      {groupToRename && (
        <RenameGroup
          show={!!groupToRename}
          onHide={() => setGroupToRename(null)}
          group={groupToRename}
          onRename={handleGroupAction}
        />
      )}
      <Container className="box full-h p-5">
        <div className="d-flex">
          <h1 className="flex-grow-1">{t('PRIVATE.ADMIN.GROUPS.GROUPS')}</h1>
        </div>
        <Row>
          <Col>
            <div className={styles.toolbar}>
              <div className={styles.sortAndSearch}>
                {buttonSorting}
                <input
                  className={styles.search}
                  placeholder={t('PRIVATE.ADMIN.GROUPS.SEARCH_BY_NAME')}
                  onChange={handleSearch}
                />
              </div>
              <div className={styles.paginationInfo}>
                {totalCount > 0 &&
                  t('PRIVATE.ADMIN.GROUPS.PAGINATION_INFO', {
                    start: tableState.page * tableState.perPage + 1,
                    end:
                      totalCount < (tableState.page + 1) * tableState.perPage
                        ? totalCount
                        : (tableState.page + 1) * tableState.perPage,
                    total: totalCount,
                  })}
                {pageCount > 1 && (
                  <div className={styles.previousNext}>
                    <div
                      className={styles.previous}
                      style={{
                        opacity: firstPageShown ? 0.2 : 0.8,
                        cursor: firstPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handlePreviousPage()}
                    >
                      <LeftIcon />
                    </div>
                    <div
                      className={styles.next}
                      style={{
                        opacity: lastPageShown ? 0.2 : 0.8,
                        cursor: lastPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handleNextPage()}
                    >
                      <RightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {loading && (
                <div className={styles.loadingWrapperOrError}>
                  <Loading />
                </div>
              )}
              {!loading && totalCount === 0 && (
                <div className={styles.loadingWrapperOrError}>{t('PRIVATE.ADMIN.GROUPS.NO_GROUPS_FOUND')}</div>
              )}
              {!loading && totalCount > 0 && (
                <>
                  <div className={classNames('data-row', styles.tableHeader)}>
                    <div className={styles.name}>{t('PRIVATE.ADMIN.GROUPS.NAME')}</div>
                    <div className={styles.membersCount}>{t('PRIVATE.ADMIN.GROUPS.MEMBERS_COUNT')}</div>
                    <div className={styles.plan}>{t('PRIVATE.GROUPS.PLAN')}</div>
                    <div className={styles.createdBy}>{t('PRIVATE.ADMIN.GROUPS.CREATED_BY')}</div>
                    <div className={styles.createdDate}>{t('PRIVATE.ADMIN.GROUPS.CREATED_DATE')}</div>
                    <div className={styles.placeholderOptions} />
                  </div>
                  {groups.map((item, index) => (
                    <div
                      key={index}
                      className={classNames('data-row', styles.row)}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        openGroupDetails(item);
                      }}
                    >
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.membersCount}>{item.membersCount}</div>
                      <div className={styles.plan}>{item.plan || t('PRIVATE.GROUPS.PLAN_FREE')}</div>
                      <div className={styles.createdBy}>{item.createdBy}</div>
                      <div className={styles.createdDate}>{moment(item.createdDate).format(DATE_TIME_FORMAT)}</div>
                      <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                          onToggle={(isOpen) => {
                            if (isOpen) setOpenedOptions(item.id);
                            else setOpenedOptions(null);
                          }}
                          show={openedOptions === item.id}
                        >
                          <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                            <OptionsIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu alignRight>
                            {/*<Dropdown.Item onClick={() => setGroupToRename(item)}>*/}
                            {/*  {t('PRIVATE.ADMIN.GROUPS.OPTIONS.RENAME_GROUP')}*/}
                            {/*</Dropdown.Item>*/}
                            <Dropdown.Item onClick={() => setGroupToDelete(item)}>
                              {t('PRIVATE.ADMIN.GROUPS.OPTIONS.DELETE_GROUP')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
