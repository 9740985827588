import React, { useEffect, useRef, useState, FC, createContext } from 'react';

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface ModalProviderProps {
  children?: any;
}
interface OptionsProps {
  modal?: {
    className?: any; //React.HTMLAttributes<any>;
  };
  header: {
    title?: string;
    closeButton?: boolean;
    className?: any; //React.HTMLAttributes<any>;
  };
  body?: {
    className?: any; //React.HTMLAttributes<any>;
  };
  footer?: {
    titleCancel?: string;
    variantCancel?: string;
    showCancel?: boolean;
    titleSubmit?: string;
    variantSubmit?: string;
    showSubmit?: string;
    className?: any; //React.HTMLAttributes<any>;
  };
}

export interface ModalOptionsProps {
  isOpened?: boolean;
  modalContent?: JSX.Element;
  headerContent?: JSX.Element;
  bodyContent?: JSX.Element;
  footerContent?: JSX.Element;
  onSubmit?: () => void;
  onCancel?: () => void;
  onHide?: () => void;
  options?: OptionsProps;
}

export interface ModalContextProps extends ModalOptionsProps {
  context?: any;
  toggleModal: (isShown: boolean) => void;
  openModal: (options: ModalOptionsProps) => void;
}

export const ModalContext = createContext({});

/**
 * ModalProvider is responsible for modals and includes two methods:
 * toggleModal method allows to open/close modal,
 * openModal renders content of modal popup inside React portal which is isolated from the main application
 */
const ModalProvider: FC = (props: ModalProviderProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [context, setContext] = useState<ModalContextProps | null>(null);
  const [modalOptions, setModalOptions] = useState<ModalOptionsProps>({ isOpened: false });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setContext(modalRef?.current);
  }, [modalRef]);

  const updaters = {
    toggleModal: (isOpen: boolean) => setModalOptions({ isOpened: isOpen }),
    openModal: (options: ModalOptionsProps) => setModalOptions({ ...options, isOpened: true }),
  };

  const value = {
    ...updaters,
    ...modalOptions,
    context,
  };

  return (
    <>
      <ModalContext.Provider value={value}>{props?.children}</ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
};

export default ModalProvider;
