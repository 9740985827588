import { Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { Group } from '../../../../../types/types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import logger from '../../../../../utils/logger/logger';
import { deleteGroup } from '../../../../../graphql/mutations';
import { Store } from 'react-notifications-component';
import { baseErrorNotification, baseSuccessNotification } from '../../../../../utils/nitificationUtils';

interface Values {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, name: string): any | (() => any) => {
  return Yup.object().shape({
    name: Yup.string()
      .test('name-test', t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.VALIDATION', { name }), (value) => value == name)
      .required(t('VALIDATION.REQUIRED')),
  });
};

interface Props {
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
  group: Group;
}

/**
 * Shows form to delete a group.
 */
export default function DeleteGroup({ show, onHide, onDelete, group }: Props): JSX.Element {
  const { t } = useTranslation();
  const [doDelete] = useMutation<{ deleteGroup: boolean }>(deleteGroup);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    (setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitting(true);

      doDelete({ variables: { groupId: group.id } })
        .then((result) => {
          if (result) {
            showSuccessNotification(t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.SUCCESS', { name: group.name }));
            onDelete();
          } else {
            showErrorNotification(t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.ERROR', { name: group.name }));
            logger.error(`Not deleted group: ${group.id}`);
            setSubmitting(false);
          }
        })
        .catch((error) => {
          showErrorNotification(t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.ERROR', { name: group.name }));
          logger.error(`Error deleting group ${group.id}: ${error}`);
          setSubmitting(false);
        });
    },
    [doDelete, group.id, group.name, onDelete, showErrorNotification, showSuccessNotification, t],
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema(t, group.name)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(setSubmitting)}
          initialValues={{ name: '' }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Form.Group className="position-relative" controlId="formGroupName">
                <Form.Label style={{ cursor: 'text' }}>
                  {t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.CONFIRMATION', { name: group.name })}
                </Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  autoFocus={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              <div className="mt-4 text-right">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {t('PRIVATE.ADMIN.GROUPS.DELETE_GROUP.CTA')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
