import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { PspSubscription, tablePageSize } from '../../../types/types.d';
import { getCheckoutSessionUrlForSubscription, getSubscriptions } from '../../../graphql/queries';
import { OrderBy, Sorting } from '../../../types/inputTypes.d';
import styles from './Plans.module.scss';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { ReactComponent as SortIcon } from '../../../assets/img/sort.svg';
import { primary } from '../../../utils/styleUtils';
import { GROUPS, REFERRER_KEY, REFERRER_SUBSCRIPTIONS } from '../../../utils/routingUtils';
import { ReactComponent as LeftIcon } from '../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../assets/img/right.svg';
import classNames from 'classnames';
import { ReactComponent as OptionsIcon } from '../../../assets/img/options.svg';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/dateTimeUtils';
import { getDomain } from '../../../utils/windowUtils';
import { Store } from 'react-notifications-component';
import { baseErrorNotification } from '../../../utils/nitificationUtils';
import logger from '../../../utils/logger/logger';
import { Loading } from '../../utils/Loading';
import { useLocation } from 'react-router-dom';
import { useLocalisedCurrencyFormatterWithDecimal } from '../../../utils/i18nUtils';
import InvoicesHistory from '../common/invoicesHistory';
import orderBy from 'lodash/orderBy';
import lowerFirst from 'lodash/lowerFirst';

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting;
  nameFilter: string;
}

export default function Plans(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const currencyFormatter = useLocalisedCurrencyFormatterWithDecimal();

  const [fetchSubscriptions, { loading }] = useLazyQuery<{ getSubscriptions: PspSubscription[] }>(getSubscriptions);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.StartDateDesc } as Sorting,
    nameFilter: '',
  });

  const [subscriptionToShow, setSubscriptionToShow] = useState<PspSubscription | null>(null);

  const [allSubscriptions, setAllSubscriptions] = useState<PspSubscription[]>([]);
  useEffect(() => {
    fetchSubscriptions().then((result) => {
      setAllSubscriptions(result?.data?.getSubscriptions || []);
    });
  }, [fetchSubscriptions]);

  const [subscriptions, setSubscriptions] = useState<PspSubscription[]>([]);
  useEffect(() => {
    // sort and filter
    const orderByValue = tableState.sorting.orderBy;
    const sortField = orderByValue.toLowerCase().endsWith('asc')
      ? orderByValue.substring(0, orderByValue.length - 3)
      : orderByValue.substring(0, orderByValue.length - 4);
    const asc = orderByValue.toLowerCase().endsWith('asc');

    const start = tableState.page * tableState.perPage;
    const end =
      start + tableState.perPage > allSubscriptions.length ? allSubscriptions.length : start + tableState.perPage;
    setSubscriptions(
      orderBy(allSubscriptions.slice(start, end), [lowerFirst(sortField)], [asc ? 'asc' : 'desc']).filter(
        (i) => i.groupName && i.groupName.includes(tableState.nameFilter),
      ),
    );
  }, [allSubscriptions, tableState.nameFilter, tableState.page, tableState.perPage, tableState.sorting.orderBy]);

  const totalCount = useMemo(() => allSubscriptions.length, [allSubscriptions.length]);
  const pageCount = useMemo(() => Math.ceil(totalCount / tableState.perPage), [tableState.perPage, totalCount]);
  const firstPageShown = useMemo(() => tableState.page === 0, [tableState.page]);
  const lastPageShown = useMemo(() => tableState.page === pageCount - 1, [pageCount, tableState.page]);

  const handlePreviousPage = useCallback(() => {
    if (!firstPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [firstPageShown]);
  const handleNextPage = useCallback(() => {
    if (!lastPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [lastPageShown]);

  const handleSorting = useCallback((orderBy: OrderBy) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setTableState((prevState) => ({
      ...prevState,
      nameFilter: !!value?.length ? value : '',
    }));
  }, []);

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          { order: OrderBy.StartDateDesc, title: t('PRIVATE.SUBSCRIPTIONS.SORTING.START_DATE_DESC') },
          { order: OrderBy.StartDateAsc, title: t('PRIVATE.SUBSCRIPTIONS.SORTING.START_DATE_ASC') },
          {
            order: OrderBy.GroupNameDesc,
            title: t('PRIVATE.SUBSCRIPTIONS.SORTING.GROUP_DESC'),
          },
          { order: OrderBy.GroupNameAsc, title: t('PRIVATE.SUBSCRIPTIONS.SORTING.GROUP_ASC') },
          { order: OrderBy.ProductNameDesc, title: t('PRIVATE.SUBSCRIPTIONS.SORTING.PRODUCT_NAME_DESC') },
          { order: OrderBy.ProductNameAsc, title: t('PRIVATE.SUBSCRIPTIONS.SORTING.PRODUCT_NAME_ASC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === tableState.sorting?.orderBy ? primary : '' }}
            onClick={() => handleSorting(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  const showErrorNotification = useCallback(
    () =>
      Store?.addNotification({
        ...baseErrorNotification,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const [fetchCheckoutUrl] = useLazyQuery<{
    getCheckoutSessionUrlForSubscription?: string;
  }>(getCheckoutSessionUrlForSubscription);
  const changeSubscription = useCallback(
    (groupId: string) => {
      fetchCheckoutUrl({
        variables: {
          groupId,
          priceId: 'price_1KeojgDTt0zdQSbjgH4wGIs9', // todo
          returnUrl: `${getDomain()}/#${location.pathname}`,
        },
      })
        .then((result) => {
          console.log(result?.data?.getCheckoutSessionUrlForSubscription);
        })
        .catch((e) => {
          logger.error(`Error fetching change url for group ${groupId}: ${e}`);
          showErrorNotification();
        });
    },
    [fetchCheckoutUrl, location.pathname, showErrorNotification],
  );

  const navigateToGroup = useCallback(
    (groupId: string) => history.push(`${GROUPS}/${groupId}?${REFERRER_KEY}=${REFERRER_SUBSCRIPTIONS}`),
    [history],
  );

  const getStatus = useCallback((value: string) => t(`SUBSCRIPTION_STATUS.${value.toUpperCase()}`), [t]);

  return (
    <>
      {subscriptionToShow && (
        <InvoicesHistory onHide={() => setSubscriptionToShow(null)} subscriptionId={subscriptionToShow.id} />
      )}
      <Container className="box full-h p-5">
        <div className={styles.header}>
          <div>
            <h1 className="flex-grow-1">{t('PRIVATE.SUBSCRIPTIONS.TITLE')}</h1>
          </div>
        </div>
        <Row>
          <Col>
            <div className={styles.toolbar}>
              <div className={styles.sortAndSearch}>
                {buttonSorting}
                <input
                  className={styles.search}
                  placeholder={t('PRIVATE.SUBSCRIPTIONS.SEARCH_BY_GROUP')}
                  onChange={handleSearch}
                />
              </div>
              <div className={styles.paginationInfo}>
                {totalCount > 0 &&
                  t('PRIVATE.GROUPS.PAGINATION_INFO', {
                    start: tableState.page * tableState.perPage + 1,
                    end:
                      totalCount < (tableState.page + 1) * tableState.perPage
                        ? totalCount
                        : (tableState.page + 1) * tableState.perPage,
                    total: totalCount,
                  })}
                {pageCount > 1 && (
                  <div className={styles.previousNext}>
                    <div
                      className={styles.previous}
                      style={{
                        opacity: firstPageShown ? 0.2 : 0.8,
                        cursor: firstPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handlePreviousPage()}
                    >
                      <LeftIcon />
                    </div>
                    <div
                      className={styles.next}
                      style={{
                        opacity: lastPageShown ? 0.2 : 0.8,
                        cursor: lastPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handleNextPage()}
                    >
                      <RightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {loading && (
                <div className={styles.loadingWrapperOrError}>
                  <Loading />
                </div>
              )}
              {!loading && totalCount === 0 && (
                <div className={styles.noResults}>{t('PRIVATE.SUBSCRIPTIONS.NO_RESULTS_FOUND')}</div>
              )}
              {!loading && totalCount > 0 && (
                <>
                  <div className={classNames('data-row', styles.tableHeader)}>
                    <div className={styles.group}>{t('PRIVATE.SUBSCRIPTIONS.GROUP')}</div>
                    <div className={styles.plan}>{t('PRIVATE.SUBSCRIPTIONS.PLAN')}</div>
                    <div className={styles.status}>{t('PRIVATE.SUBSCRIPTIONS.STATUS')}</div>
                    <div className={styles.price}>{t('PRIVATE.SUBSCRIPTIONS.PRICE')}</div>
                    <div className={styles.startDate}>{t('PRIVATE.SUBSCRIPTIONS.START_DATE')}</div>
                    <div className={styles.placeholderOptions} />
                  </div>
                  {subscriptions.map((i, index) => {
                    const item = !!i.items?.length ? i.items[0].price : null;
                    const period = item?.recurringPeriod || null;
                    const price =
                      !!item?.amount?.length && period
                        ? `${currencyFormatter.format(
                            item.amount.length > 2
                              ? parseFloat(
                                  parseFloat(
                                    `${item.amount.substring(0, item.amount.length - 2)}.${item.amount.substring(
                                      item.amount.length - 2,
                                    )}`,
                                  ).toFixed(2),
                                )
                              : parseFloat(item.amount),
                          )} / ${period}`
                        : null;
                    if (item?.amount) {
                      console.log(parseFloat(item?.amount).toFixed(2));
                    }

                    return (
                      <div key={index} className={classNames('data-row', styles.row)}>
                        <div className={styles.group}>{i.groupName}</div>
                        {/*<div className={styles.plan}>{i.productName}</div>*/}
                        <div className={styles.status}>{getStatus(i.status)}</div>
                        <div className={styles.price}>{price}</div>
                        <div className={styles.startDate}>{moment.unix(parseInt(i.startDate)).format(DATE_FORMAT)}</div>
                        {!!i.groupId?.length && (
                          <div onClick={(e) => e.stopPropagation()}>
                            <Dropdown>
                              <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                                <OptionsIcon />
                              </Dropdown.Toggle>

                              <Dropdown.Menu alignRight>
                                <Dropdown.Item onClick={() => changeSubscription(i.groupId || '')}>
                                  {t('PRIVATE.SUBSCRIPTIONS.OPTIONS.CHANGE')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => navigateToGroup(i.groupId || '')}>
                                  {t('PRIVATE.SUBSCRIPTIONS.OPTIONS.VIEW_GROUP')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setSubscriptionToShow(i)}>
                                  {t('PRIVATE.SUBSCRIPTIONS.OPTIONS.VIEW_INVOICES')}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
