import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Blacklist.module.scss';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftIcon } from '../../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../../assets/img/right.svg';
import { ReactComponent as SortIcon } from '../../../../assets/img/sort.svg';
import classNames from 'classnames';
import moment from 'moment';
import { BlacklistedUsernameSearchCriteria, OrderBy, SearchPaging, Sorting } from '../../../../types/inputTypes.d';
import { getBlacklistedUsernames } from '../../../../graphql/queries';
import { BlacklistedUsername, BlacklistedUsernames, tablePageSize } from '../../../../types/types.d';
import { primary } from '../../../../utils/styleUtils';
import { Loading } from '../../../utils/Loading';
import { DATE_TIME_FORMAT } from '../../../../utils/dateTimeUtils';
import DeleteUsername from './deleteUsername';
import AddUsername from './addUsername';
import { usernamesSet } from '../../../../slices/blacklistSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as OptionsIcon } from '../../../../assets/img/options.svg';

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting | null;
  usernameFilter: string;
}

/**
 * Blacklist page where an admin can see list of users and manage them
 */
export default function Blacklist(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [usernameToDelete, setUsernameToDelete] = useState<BlacklistedUsername | null>(null);
  const [showAddUsername, setShowAddUsername] = useState<boolean>(false);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
    usernameFilter: '',
  });

  const [fetchUsernames, { data, loading }] =
    useLazyQuery<{ getBlacklistedUsernames: BlacklistedUsernames }>(getBlacklistedUsernames);
  const doFetchUsernames = useCallback(() => {
    fetchUsernames({
      variables: {
        paging: {
          page: tableState.page,
          perPage: tableState.perPage,
        } as SearchPaging,
        sorting: tableState.sorting,
        searchCriteria: {
          username: tableState.usernameFilter,
        } as BlacklistedUsernameSearchCriteria,
      },
    });
  }, [fetchUsernames, tableState]);
  useEffect(() => doFetchUsernames(), [doFetchUsernames]);

  const usernames: BlacklistedUsername[] = useMemo(
    () => data?.getBlacklistedUsernames?.usernames || [],
    [data?.getBlacklistedUsernames?.usernames],
  );
  const totalCount = useMemo(
    () => data?.getBlacklistedUsernames?.paging?.totalCount || 0,
    [data?.getBlacklistedUsernames?.paging?.totalCount],
  );
  const pageCount = useMemo(
    () => data?.getBlacklistedUsernames?.paging?.pageCount || 0,
    [data?.getBlacklistedUsernames?.paging?.pageCount],
  );

  useEffect(() => {
    if (data?.getBlacklistedUsernames?.usernames) {
      dispatch(usernamesSet(data.getBlacklistedUsernames.usernames));
    }
  }, [data?.getBlacklistedUsernames?.usernames, dispatch]);

  const firstPageShown = useMemo(() => tableState.page === 0, [tableState.page]);
  const lastPageShown = useMemo(() => tableState.page === pageCount - 1, [pageCount, tableState.page]);

  const handlePreviousPage = useCallback(() => {
    if (!firstPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [firstPageShown]);
  const handleNextPage = useCallback(() => {
    if (!lastPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [lastPageShown]);

  const handleSorting = useCallback((orderBy: OrderBy) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setTableState((prevState) => ({
      ...prevState,
      usernameFilter: !!value?.length ? value : '',
    }));
  }, []);

  const handleUsernameAction = useCallback(() => {
    setTableState((prevState) => ({
      ...prevState,
      page: 0,
    }));
    doFetchUsernames();
    // close modal
    setUsernameToDelete(null);
    setShowAddUsername(false);
  }, [doFetchUsernames]);

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          {
            order: OrderBy.CreatedDateDesc,
            title: t('PRIVATE.ADMIN.BLACKLIST.SORTING.CREATED_DATE_DESC'),
          },
          { order: OrderBy.CreatedDateAsc, title: t('PRIVATE.ADMIN.BLACKLIST.SORTING.CREATED_DATE_ASC') },
          { order: OrderBy.UsernameDesc, title: t('PRIVATE.ADMIN.BLACKLIST.SORTING.USERNAME_ASC') },
          { order: OrderBy.UsernameAsc, title: t('PRIVATE.ADMIN.BLACKLIST.SORTING.USERNAME_DESC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === tableState.sorting?.orderBy ? primary : '' }}
            onClick={() => handleSorting(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      {usernameToDelete && (
        <DeleteUsername
          show={!!usernameToDelete}
          onHide={() => setUsernameToDelete(null)}
          username={usernameToDelete}
          onDelete={handleUsernameAction}
        />
      )}
      <AddUsername show={showAddUsername} onHide={() => setShowAddUsername(false)} onAdd={handleUsernameAction} />
      <Container className="box full-h p-5">
        <div className="d-flex">
          <h1 className="flex-grow-1">{t('PRIVATE.ADMIN.BLACKLIST.USERNAMES')}</h1>
          <Button variant="primary" onClick={() => setShowAddUsername(true)}>
            {t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME_TO_BLACKLIST')}
          </Button>
        </div>
        <Row>
          <Col>
            <div className={styles.toolbar}>
              <div className={styles.sortAndSearch}>
                {buttonSorting}
                <input
                  className={styles.search}
                  placeholder={t('PRIVATE.ADMIN.BLACKLIST.SEARCH_BY_USERNAME')}
                  onChange={handleSearch}
                />
              </div>
              <div className={styles.paginationInfo}>
                {totalCount > 0 &&
                  t('PRIVATE.ADMIN.BLACKLIST.PAGINATION_INFO', {
                    start: tableState.page * tableState.perPage + 1,
                    end:
                      totalCount < (tableState.page + 1) * tableState.perPage
                        ? totalCount
                        : (tableState.page + 1) * tableState.perPage,
                    total: totalCount,
                  })}
                {pageCount > 1 && (
                  <div className={styles.previousNext}>
                    <div
                      className={styles.previous}
                      style={{
                        opacity: firstPageShown ? 0.2 : 0.8,
                        cursor: firstPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handlePreviousPage()}
                    >
                      <LeftIcon />
                    </div>
                    <div
                      className={styles.next}
                      style={{
                        opacity: lastPageShown ? 0.2 : 0.8,
                        cursor: lastPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handleNextPage()}
                    >
                      <RightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {loading && (
                <div className={styles.loadingWrapperOrError}>
                  <Loading />
                </div>
              )}
              {!loading && totalCount === 0 && (
                <div className={styles.loadingWrapperOrError}>{t('PRIVATE.ADMIN.BLACKLIST.NO_USERNAMES_FOUND')}</div>
              )}
              {!loading && totalCount > 0 && (
                <>
                  <div className={classNames('data-row', styles.tableHeader)}>
                    <div className={styles.username}>Username</div>
                    <div className={styles.createdBy}>Created by</div>
                    <div className={styles.createdDate}>Created date</div>
                    <div className={styles.placeholderOptions} />
                  </div>
                  {usernames.map((username, index) => (
                    <div key={index} className={classNames('data-row', styles.userRow)}>
                      <div className={styles.username}>{username.username}</div>
                      <div className={styles.createdBy}>{username.createdBy}</div>
                      <div className={styles.createdDate}>{moment(username.createdDate).format(DATE_TIME_FORMAT)}</div>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                            <OptionsIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu alignRight>
                            <Dropdown.Item onClick={() => setUsernameToDelete(username)}>
                              {t('PRIVATE.ADMIN.BLACKLIST.DELETE_USERNAME.TITLE')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
