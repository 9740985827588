import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import logger from '../../../../utils/logger/logger';
import { createGroup } from '../../../../graphql/mutations';
import { Store } from 'react-notifications-component';
import {
  baseErrorNotification,
  baseInfoNotification,
  baseSuccessNotification,
} from '../../../../utils/nitificationUtils';
import { Group } from '../../../../types/types';
import { groupExists } from '../../../../graphql/queries';
import { createCondaChannelName } from '../../../../utils/businessUtils';

interface Values {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction): any | (() => any) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(t('VALIDATION.REQUIRED'))
      .matches(/^[a-zA-Z0-9 -]+$/, t('PRIVATE.GROUPS.ADD_GROUP.CONDA_CHANNEL_VALIDATION')),
  });
};

interface Props {
  show: boolean;
  onHide: () => void;
  onAdd: () => void;
}

/**
 * Shows form to create a group.
 */
export default function AddGroup({ show, onHide, onAdd }: Props): JSX.Element {
  const { t } = useTranslation();
  const [doGroupExists] = useLazyQuery<{ groupExists: boolean }>(groupExists);
  const [doAdd] = useMutation<{ createGroup?: Group }>(createGroup);

  const [channelName, setChannelName] = useState('');

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showInfoNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseInfoNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const add = useCallback(
    ({ name }: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      doAdd({ variables: { name: name.trim(), channelName } })
        .then((result) => {
          if (result) {
            showSuccessNotification(t('PRIVATE.GROUPS.ADD_GROUP.SUCCESS', { name: name.trim() }));
            setChannelName('');
            onAdd();
          } else {
            showErrorNotification(t('PRIVATE.GROUPS.ADD_GROUP.ERROR', { name: name.trim() }));
            logger.error(`Not added group: ${name.trim()}`);
            setSubmitting(false);
          }
        })
        .catch((error) => {
          showErrorNotification(t('PRIVATE.GROUPS.ADD_GROUP.ERROR', { name: name.trim() }));
          logger.error(`Error adding group ${name.trim()}: ${error}`);
          setSubmitting(false);
        });
    },
    [channelName, doAdd, onAdd, showErrorNotification, showSuccessNotification, t],
  );

  const handleSubmit = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitting(true);

      doGroupExists({ variables: { name: channelName.trim() } }).then((result) => {
        if (!result.data?.groupExists) {
          add(values, setSubmitting);
        } else {
          showInfoNotification(t('PRIVATE.GROUPS.ADD_GROUP.GROUP_EXISTS', { name: channelName.trim() }));
          setSubmitting(false);
        }
      });
    },
    [add, channelName, doGroupExists, showInfoNotification, t],
  );

  return (
    <Modal
      show={show}
      onHide={() => {
        setChannelName('');
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.GROUPS.ADD_GROUP.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema(t)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{ name: '' }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Form.Group className="position-relative" controlId="formGroupName">
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  autoFocus={true}
                  onChange={(e) => {
                    const value = e.target?.value?.trim() || '';
                    setChannelName(createCondaChannelName(value.trim()));
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              {!!channelName.length && <div>{t('PRIVATE.GROUPS.ADD_GROUP.CONDA_CHANNEL', { name: channelName })}</div>}

              <div className="mt-4 text-right">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {t('PRIVATE.GROUPS.ADD_GROUP.CTA')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
