import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from '../../slices/authSlice';
import blacklistReducer from '../../slices/blacklistSlice';
import pspReducer from '../../slices/pspSlice';

const allReducers = {
  auth: authReducer,
  blacklist: blacklistReducer,
  psp: pspReducer,
};

const combinedReducer = combineReducers(allReducers);

export const RESET = { type: 'reset' };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  if (action.type === RESET.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
