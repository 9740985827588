import { CompanyType, CountryCode, LanguageCode, Maybe, Scalars, SecurityRole, UserPosition } from './types';

export type SearchPaging = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type Sorting = {
  orderBy: OrderBy;
};

export enum OrderBy {
  EmailAsc = 'EmailAsc',
  EmailDesc = 'EmailDesc',
  FirstNameAsc = 'FirstNameAsc',
  FirstNameDesc = 'FirstNameDesc',
  LastNameAsc = 'LastNameAsc',
  LastNameDesc = 'LastNameDesc',
  RoleAsc = 'RoleAsc',
  RoleDesc = 'RoleDesc',
  PositionAsc = 'PositionAsc',
  PositionDesc = 'PositionDesc',
  CompanyNameAsc = 'CompanyNameAsc',
  CompanyTypeDesc = 'CompanyTypeDesc',
  CreatedDateAsc = 'CreatedDateAsc',
  CreatedDateDesc = 'CreatedDateDesc',
  UsernameAsc = 'UsernameAsc',
  UsernameDesc = 'UsernameDesc',
  TechUsernameAsc = 'TechUsernameAsc',
  TechUsernameDesc = 'TechUsernameDesc',
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  MembersCountAsc = 'MembersCountAsc',
  MembersCountDesc = 'MembersCountDesc',
  GroupNameAsc = 'GroupNameAsc',
  GroupNameDesc = 'GroupNameDesc',
  ProductNameAsc = 'ProductNameAsc',
  ProductNameDesc = 'ProductNameDesc',
  StartDateDesc = 'StartDateDesc',
  StartDateAsc = 'StartDateAsc',
}

export type UserSearchCriteria = {
  email?: Maybe<Scalars['String']>;
};

export const CREATED_BY_ADMIN = 'created by admin';

export type UserInput = {
  __typename?: 'UserInput';
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>; // todo must be made mandatory once migration from fullName is finished
  lastName?: Maybe<Scalars['String']>; // todo must be made mandatory once migration from fullName is finished
  techUsername: Scalars['String'];
  role: SecurityRole;
  languageIso6391: LanguageCode;
  countryIsoAlpha2: CountryCode;
  position: UserPosition;
  companyName: Scalars['String'];
  companyType: CompanyType;
  supportWays?: Maybe<Scalars['String']>;
  otherSupportWays?: Maybe<Scalars['String']>;
  twoFAEnabled: Scalars['Boolean'];
  productUpdatesEnabled: Scalars['Boolean'];
  maxDevHostsCount: Scalars['Int'];
  regIp?: Maybe<Scalars['String']>;
  createdByAdmin: Scalars<'Boolean'>;
};

export type BlacklistedUsernameSearchCriteria = {
  username?: Maybe<Scalars['String']>;
};

export type GroupSearchCriteria = {
  name?: Maybe<Scalars['String']>;
};
