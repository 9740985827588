import React, { useCallback, useMemo } from 'react';
import styles from './CondaChannels.module.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../../../../assets/img/copy.svg';
import { copyToClipboard } from '../../../../../utils/windowUtils';
import { Store } from 'react-notifications-component';
import { baseInfoNotification } from '../../../../../utils/nitificationUtils';

interface Props {
  onHide: () => void;
  channelName: string;
  deployKey: string | null;
  userKey: string | null;
}

export const CondaChannelsViewer = ({ onHide, channelName, deployKey, userKey }: Props): JSX.Element => {
  const { t } = useTranslation();

  const link = useMemo(
    () => (userKey && channelName ? `${t('COMMON.CONDA_URL')}/${userKey}/get/${channelName}` : null),
    [channelName, t, userKey],
  );

  const showInfoNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseInfoNotification,
        dismiss: {
          duration: 3000,
          onScreen: false,
          click: true,
          touch: true,
          showIcon: true,
        },
        message: msg,
      }),
    [],
  );
  const handleCopy = useCallback(
    (value: string | null) => {
      if (value) {
        copyToClipboard(value);
        showInfoNotification(t('COMMON.COPIED'));
      }
    },
    [showInfoNotification, t],
  );

  return (
    <Modal show={true} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.VIEWER.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.viewer}>
        {link && (
          <>
            <div className={styles.user}>
              <div className={styles.title}>
                <span>{t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.VIEWER.LINK')}</span>
                <span className={styles.copy} onClick={() => handleCopy(link)}>
                  <CopyIcon />
                </span>
              </div>
              <pre className={styles.channel}>{link}</pre>
              <div className={styles.msg}>{t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.VIEWER.MSG')}</div>
            </div>
          </>
        )}
        {!!deployKey?.length && (
          <div className={styles.deploy}>
            <div className={styles.title}>
              <span>{t('PRIVATE.COMMON.GROUP.CONDA_CHANNELS.VIEWER.DEPLOY_TOKEN')}</span>
              <span className={styles.copy} onClick={() => handleCopy(deployKey)}>
                <CopyIcon />
              </span>
            </div>
            <pre className={styles.channel}>{deployKey}</pre>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
