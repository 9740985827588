import React, { useCallback, useMemo } from 'react';
import { MenuPlacement, OptionTypeBase } from 'react-select/src/types';
import { StylesConfig } from 'react-select/src/styles';
import { CSSObject } from '@emotion/serialize';
import Select, { components, IndicatorProps, OptionProps } from 'react-select';
import { primary, selectionColor } from '../../../../utils/styleUtils';
import { ReactComponent as DownIcon } from '../../../../../src/assets/img/down.svg';
import { CompanyType } from '../../../../types/types.d';
import { useTranslation } from 'react-i18next';
import { getLocalisedCompanyType } from '../../../../utils/userUtils';

interface OptionType extends OptionTypeBase {
  value: CompanyType;
  label: string;
}

const customStyles = <OptionType extends OptionTypeBase, IsMulti extends boolean>(): StylesConfig<
  OptionType,
  IsMulti
> => {
  return {
    container: (base: CSSObject) => ({
      ...base,
      background: 'inherit',
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      cursor: 'pointer',
      fontSize: 'inherit',
    }),
    control: (base: CSSObject) => ({
      ...base,
      background: 'none',
      border: 'inherit',
      fontSize: 'inherit',
      boxShadow: 'inherit',
      cursor: 'inherit',
      paddingLeft: '0.8rem',
      paddingRight: '0.7rem',
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      minHeight: 'none',
    }),
    valueContainer: (base: CSSObject) => ({
      ...base,
      paddingLeft: '0',
      paddingRight: '0',
      fontSize: 'inherit',
      opacity: 0.85,
    }),
    placeholder: (base: CSSObject) => ({
      ...base,
      margin: '0',
    }),
    singleValue: (base: CSSObject) => ({
      ...base,
      marginLeft: 0,
      marginRight: 0,
      color: 'inherit',
      opacity: '0.8',
      fontSize: 'inherit',
    }),
    multiValue: (base: CSSObject) => ({
      ...base,
      borderRadius: '.3rem',
    }),
    indicatorsContainer: (base: CSSObject) => ({
      ...base,
      height: 'inherit',
    }),
    dropdownIndicator: (base: CSSObject) => ({
      ...base,
      paddingRight: '0',
    }),
    indicatorSeparator: (base: CSSObject) => ({
      ...base,
      display: 'none',
    }),
    menu: (base: CSSObject) => ({
      ...base,
      borderRadius: '.5rem',
      boxShadow: '5px 5px 21px rgba(39, 42, 53, 0.1)',
      border: '1px solid rgba(39, 42, 53, 0.03)',
    }),
    menuList: (base: CSSObject) => ({
      ...base,
      maxHeight: '100%',
    }),
    option: (base: CSSObject, props: OptionProps<OptionType, IsMulti>) => {
      const isFocused = props.isFocused;
      const isSelected = props.isSelected;

      return {
        ...base,
        display: 'flex',
        alignItems: 'end',
        whiteSpace: 'break-spaces',
        opacity: '.8',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        cursor: 'pointer',
        color: isSelected ? primary : '',
        backgroundColor: isFocused ? selectionColor : '',
      };
    },
  };
};

interface Props {
  externallyManagedSelectedValues: boolean;
  selectedValues: CompanyType[] | null;
  onSelect: (values: CompanyType[]) => void;
  controlShouldRenderValue: boolean;
  isMulti: boolean;
  isSearchable: boolean;
  menuIsOpen?: boolean;
  closeMenuOnSelect: boolean;
  placeholder?: string;
  hideDropdownIndicator?: boolean;
  menuPlacement?: MenuPlacement;
}

export default function CompanyTypeSelector({
  externallyManagedSelectedValues,
  selectedValues,
  onSelect,
  controlShouldRenderValue,
  isMulti,
  isSearchable,
  menuIsOpen,
  closeMenuOnSelect,
  // placeholder,
  hideDropdownIndicator,
  menuPlacement,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const getLabel = useCallback((value: CompanyType) => getLocalisedCompanyType(value, t), [t]);

  const allOptions: OptionType[] = [CompanyType.OEM, CompanyType.TIER_1_2, CompanyType.ACADEMIA, CompanyType.OTHER].map(
    (value) => ({
      value: value,
      label: getLabel(value),
    }),
  );

  const selectedOptions = useMemo(
    () =>
      selectedValues
        ? selectedValues.map(
            (i) =>
              ({
                value: i,
                label: getLabel(i),
              } as OptionType),
          )
        : null,
    [getLabel, selectedValues],
  );

  const handleSelection = useCallback(
    (values: CompanyType[]) => {
      onSelect(values);
    },
    [onSelect],
  );

  const handleOnChange = useCallback(
    (value) => {
      if (value !== null) {
        if (Array.isArray(value)) {
          // multiple values selected
          handleSelection(value.map((option) => option.value));
        } else {
          // single value selected
          const option = value as OptionType;
          handleSelection([option.value]);
        }
      }
    },
    [handleSelection],
  );

  // the components in a constant solve the problem of jumping to the top dropdown when navigating away from it
  const selectComponent = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      DropdownIndicator(props: IndicatorProps<OptionType, typeof isMulti>) {
        return hideDropdownIndicator ? (
          <></>
        ) : (
          <components.DropdownIndicator {...props}>
            <DownIcon />
          </components.DropdownIndicator>
        );
      },
      Option(props: OptionProps<OptionType, typeof isMulti>) {
        const label = props.data.label;
        const content = <span>{label}</span>;
        return <components.Option {...props}>{content}</components.Option>;
      },
    }),
    [hideDropdownIndicator],
  );

  return (
    <Select
      styles={customStyles<OptionType, typeof isMulti>()}
      // placeholder={placeholderValue || ''}
      isMulti={isMulti}
      isClearable={false}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement || 'auto'}
      hideSelectedOptions={false}
      controlShouldRenderValue={controlShouldRenderValue}
      closeMenuOnSelect={closeMenuOnSelect}
      name="companyTypeSelector"
      value={externallyManagedSelectedValues ? selectedOptions : undefined}
      defaultValue={externallyManagedSelectedValues ? undefined : selectedOptions}
      options={allOptions}
      onChange={handleOnChange}
      components={selectComponent}
    />
  );
}
