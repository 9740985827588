import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { User } from '../types/types';
import { URL_QUERY_PARAM_KEY, URL_QUERY_PARAM_USERNAME } from '../utils/routingUtils';
import logger from '../utils/logger/logger';

/**
 * Sends a POST request to the BE to register a new user.
 */
export const register = (data: User): Promise<boolean> => {
  return axios
    .post('api/user', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (response.status === StatusCodes.CREATED) {
        return true;
      } else {
        logger.error(`User registration result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`User registration error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to verify the email of a newly registered user.
 */
export const verifyEmail = (key: string): Promise<boolean> => {
  return axios
    .get(`/api/user/email?${URL_QUERY_PARAM_KEY}=${key}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Email verification result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Email verification error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a POST request to the BE to trigger another verification email for the currently logged in user.
 */
export const triggerVerificationEmail = (): Promise<boolean> => {
  return axios
    .post('/api/user/email')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Email verification triggering result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Email verification triggering error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a DELETE request to the BE to initialize a password reset process.
 */
export const initializePasswordReset = (email: string): Promise<boolean> => {
  return axios
    .patch(
      'api/user/password',
      {
        email: email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Password reset init result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Password reset init error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a POST request to the BE to verify if a password reset is possible with this key.
 * Checks if the key is not expired.
 *
 * @return true if the key is still valid, false otherwise
 */
export const verifyPasswordResetKey = (key: string): Promise<boolean> => {
  return axios
    .get(`/api/user/password?${URL_QUERY_PARAM_KEY}=${key}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Password reset key verification result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Password reset key verification error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a POST request to the BE to reset a password to a new value.
 */
export const resetPassword = (password: string, key: string): Promise<boolean> => {
  return axios
    .post(
      `/api/user/password?${URL_QUERY_PARAM_KEY}=${key}`,
      {
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Password reset result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Password reset error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to verify if the provided tech_username already taken by some user or not.
 */
export const verifyTechUsername = (username: string): Promise<boolean> => {
  return axios
    .get(`/api/user/username?${URL_QUERY_PARAM_USERNAME}=${username}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Tech username verification result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Tech username verification error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to fetch a list of blacklisted usernames.
 */
export const getBlacklistedUsernames = (): Promise<string[]> => {
  return axios
    .get(`/api/user/blacklist`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data || [];
      } else {
        logger.error(`Not fetched blacklisted usernames: ${response.status}`);
        return [];
      }
    })
    .catch((error) => {
      logger.error(`Error fetching blacklisted usernames: ${error}`);
      return [];
    });
};
