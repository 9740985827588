import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlacklistedUsername } from '../types/types';

export type BlacklistSliceState = {
  usernames: BlacklistedUsername[];
};

const blacklistSlice = createSlice({
  name: 'blacklist',
  initialState: { usernames: [] } as BlacklistSliceState,
  reducers: {
    usernamesSet(state, action: PayloadAction<BlacklistedUsername[]>) {
      state.usernames = action.payload;
    },
  },
});

export const { usernamesSet } = blacklistSlice.actions;

export default blacklistSlice.reducer;
