import { useLocation } from 'react-router-dom';

export const REFERRER_KEY = 'r';
export const REFERRER_SUBSCRIPTIONS = 'subscriptions';
export const SESSION_ID_KEY = 'session_id';

/**
 * Public routes
 */
export const ROOT = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const EMAIL_VERIFICATION = '/verify-email'; // can be public as well as private
export const PASSWORD_RECOVER = '/recover-password';
export const PASSWORD_RESET = '/reset-password';

/**
 * USER private routes
 */
export const ID = 'id';
export const NEW = 'new';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const DOCS = '/docs';
export const SUBSCRIPTIONS = '/subscriptions';
export const PROFILING = '/profile';
export const HOST = '/host';
export const GROUPS = '/groups';
export const GROUP = `${GROUPS}/:${ID}`;

/**
 * ADMIN private routes
 */
export const ADMIN_USERS = '/admin-users';
export const ADMIN_USER = `/admin-users/:${ID}`;
export const ADMIN_BLACKLIST = '/admin-blacklist';
export const ADMIN_GROUPS = '/admin-groups';
export const ADMIN_GROUP = `${ADMIN_GROUPS}/:${ID}`;

export const allPrivateRoutes = [
  HOME,
  ACCOUNT,
  DOCS,
  SUBSCRIPTIONS,
  PROFILING,
  HOST,
  GROUPS,
  GROUP,
  ADMIN_USERS,
  ADMIN_USER,
  ADMIN_BLACKLIST,
  ADMIN_GROUPS,
  ADMIN_GROUP,
];

export const URL_QUERY_PARAM_KEY = 'key';
export const URL_QUERY_PARAM_USERNAME = 'username';
export const URL_QUERY_PARAM_EXPIRED_SESSION = 'expired';

export const useURLSearchParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};
