import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { LOGIN } from '../../../utils/routingUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { getBlacklistedUsernames, register, verifyTechUsername } from '../../../services/user.service';
import { User } from '../../../types/types';
import { getClientIpAddress, login } from '../../../services/auth.service';
import { Store } from 'react-notifications-component';
import { baseErrorNotification } from '../../../utils/nitificationUtils';

interface Values {
  email: string;
  firstName: string;
  lastName: string;
  techUsername: string;
  password: string;
  productUpdatesEnabled: boolean;
}

const validationSchema = (
  t: TFunction,
  validateOnSubmit: boolean,
  blacklistedUsernames: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string().email(t('VALIDATION.EMAIL_VALID')).max(100, t('VALIDATION.EMAIL_MAX_LENGTH')).optional(),
    firstName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.FIRST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.FIRST_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.FIRST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.FIRST_NAME_MAX_LENGTH'))
          .optional(),
    lastName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.LAST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.LAST_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.LAST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.LAST_NAME_MAX_LENGTH'))
          .optional(),
    techUsername: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.TECH_USERNAME_MIN_LENGTH'))
          .max(30, t('VALIDATION.TECH_USERNAME_MAX_LENGTH'))
          .test(
            'name-test',
            t('VALIDATION.TECH_USERNAME_NOT_ALLOWED'),
            (value) => !value || !blacklistedUsernames.includes(value.trim()),
          )
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.TECH_USERNAME_MIN_LENGTH'))
          .max(30, t('VALIDATION.TECH_USERNAME_MAX_LENGTH'))
          .test(
            'name-test',
            t('VALIDATION.TECH_USERNAME_NOT_ALLOWED'),
            (value) => !value || !blacklistedUsernames.includes(value.trim()),
          )
          .optional(),
    password: validateOnSubmit
      ? Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .optional(),
  });
};

export default function Register(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [ipAddress, setIpAddress] = useState('');

  const showErrorNotification = useCallback(
    (title: string, withMsg: boolean) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title: withMsg ? title : null,
        message: withMsg ? t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE') : title,
      }),
    [t],
  );

  const [blacklistedUsernames, setBlacklistedUsernames] = useState<string[]>([]);
  useEffect(() => {
    getBlacklistedUsernames().then((result) => setBlacklistedUsernames(result));
  }, []);

  useEffect(() => {
    getClientIpAddress().then((result) => setIpAddress(result));
  }, []);

  /**
   * Tries to log in. If succeeds then refreshes the page, otherwise redirects to the login page.
   */
  const doLogin = useCallback(
    (values: Values) => {
      login(values.email, values.password, false)
        .then((result: boolean) => {
          if (result) {
            // update the page and let user start working
            window.location.reload();
          } else {
            history.push(LOGIN);
          }
        })
        .catch(() => {
          history.push(LOGIN);
        });
    },
    [history],
  );

  const doRegister = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      const user = {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        techUsername: values.techUsername,
        productUpdatesEnabled: values.productUpdatesEnabled,
        regIp: ipAddress,
      } as User;

      register(user)
        .then((result: boolean) => {
          if (result) {
            doLogin(values);
          } else {
            showErrorNotification(t('PUBLIC.AUTH.REG.FAILED'), true);
            setSubmitting(false);
          }
        })
        .catch(() => {
          showErrorNotification(t('PUBLIC.AUTH.REG.FAILED'), true);
          setSubmitting(false);
        });
    },
    [doLogin, ipAddress, showErrorNotification, t],
  );

  const handleSubmit = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      verifyTechUsername(values.techUsername)
        .then((result: boolean) => {
          if (result) {
            doRegister(values, setSubmitting);
          } else {
            showErrorNotification(t('PUBLIC.AUTH.REG.TECH_USERNAME_EXISTS'), false);
            setSubmitting(false);
          }
        })
        .catch(() => {
          showErrorNotification(t('PUBLIC.AUTH.REG.TECH_USERNAME_EXISTS'), false);
          setSubmitting(false);
        });
    },
    [doRegister, showErrorNotification, t],
  );

  return (
    <div className="authpage">
      <div className="subheader">{t('PUBLIC.AUTH.REG.SUBHEADING')}</div>
      <h1 className="text-center">{t('PUBLIC.AUTH.REG.HEADING')}</h1>

      <div className="authbox">
        <Formik
          validationSchema={validationSchema(t, submitTriggered, blacklistedUsernames)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            techUsername: '',
            password: '',
            productUpdatesEnabled: false,
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="authform">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>{t('PUBLIC.AUTH.EMAIL_ADDRESS')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder={t('PUBLIC.AUTH.EMAIL_ADDRESS_PLACEHOLDER')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupFirstName">
                <Form.Label>{t('PUBLIC.AUTH.FIRST_NAME')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="firstName"
                  placeholder={t('PUBLIC.AUTH.FIRST_NAME_PLACEHOLDER')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  isInvalid={touched.firstName && !!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupLastName">
                <Form.Label>{t('PUBLIC.AUTH.LAST_NAME')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="lastName"
                  placeholder={t('PUBLIC.AUTH.LAST_NAME_PLACEHOLDER')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  isInvalid={touched.lastName && !!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('PUBLIC.AUTH.TECH_USERNAME')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="techUsername"
                  placeholder={t('PUBLIC.AUTH.TECH_USERNAME_PLACEHOLDER')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.techUsername}
                  isInvalid={touched.techUsername && !!errors.techUsername}
                />
                <Form.Control.Feedback type="invalid">{errors.techUsername}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
                <Form.Label>{t('PUBLIC.AUTH.PASSWORD')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder={t('PUBLIC.AUTH.PASSWORD_PLACEHOLDER')}
                  autoComplete="true"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupLegal">
                <label>
                  <small id="legal" className="form-text">
                    {t('PUBLIC.AUTH.REG.LEGAL_1')}
                    <a href="https://linkworks.io/legal/terms" rel="noreferrer" target="_blank" className="alert-link">
                      {t('PUBLIC.AUTH.REG.LEGAL_2')}
                    </a>
                    {t('PUBLIC.AUTH.REG.LEGAL_3')}
                    <a
                      href="https://linkworks.io/legal/privacy"
                      rel="noreferrer"
                      target="_blank"
                      className="alert-link"
                    >
                      {t('PUBLIC.AUTH.REG.LEGAL_4')}
                    </a>
                    .
                  </small>
                </label>
              </Form.Group>
              <Form.Group controlId="formGroupProductUpdatesEnabled" className="form-check">
                <Form.Check
                  type="checkbox"
                  name="productUpdatesEnabled"
                  className="form-check-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultChecked={values.productUpdatesEnabled}
                />
                <label className="form-check-label" htmlFor="exampleCheck1" id="exampleCheck1">
                  <small id="emailHelp" className="form-text">
                    {t('PUBLIC.AUTH.REG.OPTIN')}
                  </small>
                </label>
              </Form.Group>

              <div className="mt-3">
                <Button
                  variant="primary"
                  size="lg"
                  block
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => setSubmitTriggered(true)}
                >
                  {t('PUBLIC.AUTH.REG.CTA')}
                </Button>
              </div>

              <small className="form-text mt-3 text-center">
                <span>{t('PUBLIC.AUTH.HAVE_ACCOUNT')}</span>{' '}
                <Link to={LOGIN} className="alert-link">
                  {t('PUBLIC.AUTH.HAVE_ACCOUNT_CTA')}
                </Link>
              </small>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
