import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { User } from '../../../../types/types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { changePassword } from '../../../../graphql/mutations';
import logger from '../../../../utils/logger/logger';

interface Values {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction): any | (() => any) => {
  return Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
      .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
      .when('newPassword', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().required(t('VALIDATION.REQUIRED')),
      })
      .optional(),
    newPassword: Yup.string()
      .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
      .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
      .optional(),
    newPasswordConfirmation: Yup.string()
      .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
      .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
      .when('newPassword', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string()
          .oneOf([Yup.ref('newPassword')], t('VALIDATION.BOTH_PASSWORDS_MUST_EQUAL'))
          .required(t('VALIDATION.REQUIRED')),
      })
      .optional(),
  });
};

interface ChangePasswordProps {
  show: boolean;
  onHide: () => void;
  user: User | null;
}

export default function ChangePassword({ show, onHide, user }: ChangePasswordProps): JSX.Element {
  const { t } = useTranslation();

  const [doChangePassword] = useMutation<{ changePassword?: boolean }>(changePassword);

  const [updateFailed, setUpdateFailed] = useState(false);
  const [updateSucceeded, setUpdateSucceeded] = useState(false);

  const handleModalClosing = useCallback(() => {
    setUpdateFailed(false);
    setUpdateSucceeded(false);
    onHide();
  }, [onHide]);

  if (!user) {
    return (
      <Modal show={show} onHide={handleModalClosing}>
        <Modal.Header closeButton>
          <Modal.Title>Password Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sorry, an error happened. Please contact our customer support.</Modal.Body>
      </Modal>
    );
  }

  const handleSubmit = async (values: Values, setSubmitting: (isSubmitting: boolean) => void): Promise<void> => {
    setSubmitting(true);
    setUpdateFailed(false);
    setUpdateSucceeded(false);

    doChangePassword({
      variables: {
        email: user.email,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
    })
      .then((result) => {
        if (result?.data?.changePassword) {
          setUpdateSucceeded(true);
        } else {
          logger.error('No result for password change');
          setUpdateFailed(true);
        }
      })
      .catch((error) => {
        logger.error(`Password change error from Account: ${error}`);
        setUpdateFailed(true);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal show={show} onHide={handleModalClosing}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.PROFILE.CHANGE_PASSWORD_HEADING')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema(t)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Form.Group className="position-relative" controlId="formGroupCurrentPassword">
                <Form.Label>{t('PUBLIC.AUTH.PASSWORD')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="password"
                  name="currentPassword"
                  placeholder={t('PUBLIC.AUTH.PASSWORD_PLACEHOLDER')}
                  autoComplete="false"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currentPassword}
                  isInvalid={touched.currentPassword && !!errors.currentPassword}
                />
                <Form.Control.Feedback type="invalid">{errors.currentPassword}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formGroupNewPassword">
                <Form.Label>{t('PUBLIC.AUTH.RESET.NEW_PASSWORD')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="password"
                  name="newPassword"
                  placeholder={t('PUBLIC.AUTH.RESET.NEW_PASSWORD_PLACEHOLDER')}
                  autoComplete="false"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  isInvalid={touched.newPassword && !!errors.newPassword}
                />
                <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formGroupNewPasswordConfirmation">
                <Form.Label>{t('PUBLIC.AUTH.RESET.CONFIRM_PASSWORD')}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="password"
                  name="newPasswordConfirmation"
                  placeholder={t('PUBLIC.AUTH.RESET.CONFIRM_PASSWORD_PLACEHOLDER')}
                  autoComplete="true"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPasswordConfirmation}
                  isInvalid={touched.newPasswordConfirmation && !!errors.newPasswordConfirmation}
                />
                <Form.Control.Feedback type="invalid">{errors.newPasswordConfirmation}</Form.Control.Feedback>
              </Form.Group>

              <div className="mt-4 text-right">
                {updateFailed ? (
                  <Alert variant="danger" className="mt-3 text-left">
                    {t('PRIVATE.PROFILE.PASSWORD_FAILED')}
                  </Alert>
                ) : (
                  <></>
                )}
                {updateSucceeded ? (
                  <Alert variant="success" className="mt-3 text-left">
                    {t('PRIVATE.PROFILE.PASSWORD_CHANGED_MSG')}
                  </Alert>
                ) : (
                  <></>
                )}
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {t('PRIVATE.PROFILE.CHANGE_PASSWORD_CTA')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
