import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import logger from '../utils/logger/logger';

/**
 * @return an object indicating if login request was OK and 2FA flow must take place
 */
export const login = (email: string, password: string, rememberMe: boolean): Promise<boolean> => {
  return axios
    .post(
      '/api/auth/login',
      {
        email: email,
        password: password,
        rememberMe: rememberMe,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Login result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Login error happened`);
      return false;
    });
};

/**
 * @return true if 2FA request was OK, false otherwise
 */
export const loginWithTwoFA = (code: string): Promise<boolean> => {
  return axios
    .post(
      '/api/auth/2fa',
      {
        code: code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`2FA login result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`2FA login error happened`);
      return false;
    });
};

/**
 * @return true in any case because the actual result is not important to the user
 */
export const logout = (): Promise<boolean> => {
  return axios
    .delete('/api/auth/logout')
    .then(() => {
      return true;
    })
    .catch(() => {
      logger.error(`Logout error happened`);
      return true;
    });
};

/**
 * Sends a request to get new tokens (in cookies) based on the provided on the current token cookie
 * @return true if refresh request was OK, false otherwise
 */
export const refresh = (): Promise<boolean> => {
  return axios
    .post('/api/auth/refresh')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Refresh result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Refresh error happened`);
      return false;
    });
};

/**
 * Sends a GET request to define client IP address.
 */
export const getClientIpAddress = (): Promise<string> => {
  return axios
    .get(`https://api.db-ip.com/v2/free/self`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data?.ipAddress || '';
      } else {
        logger.error(`Could not define client IP address: ${response.status}`);
        return '';
      }
    })
    .catch((error) => {
      logger.error(`Error defining client IP: ${error}`);
      return '';
    });
};
