import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './Account.module.scss';
import * as Yup from 'yup';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import ChangePassword from './changePassword/ChangePassword';
import { CompanyType, Host, User, UserPosition } from '../../../types/types.d';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSliceState, userLoaded } from '../../../slices/authSlice';
import { useMutation, useQuery } from '@apollo/client';
import { updateUser } from '../../../graphql/mutations';
import logger from '../../../utils/logger/logger';
import ChangeEmail from './changeEmail';
import { HOST } from '../../../utils/routingUtils';
import { getHosts } from '../../../graphql/queries';
import { userHasAdminRole } from '../../../utils/userUtils';
import { UserInput } from '../../../types/inputTypes';
import { Store } from 'react-notifications-component';
import { baseErrorNotification, baseSuccessNotification } from '../../../utils/nitificationUtils';
import { verifyTechUsername } from '../../../services/user.service';
import { BlacklistSliceState } from '../../../slices/blacklistSlice';
import UserPositionSelector from '../common/userPositionSelector';
import classNames from 'classnames';
import CompanyTypeSelector from '../common/companyTypeSelector';

interface Values {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  techUsername: string;
  position: UserPosition;
  companyName: string;
  companyType: CompanyType;
}

const validationSchema = (
  t: TFunction,
  validateOnSubmit: boolean,
  blacklistedUsernames: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | (() => any) => {
  return Yup.object().shape({
    firstName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.FIRST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.FIRST_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.FIRST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.FIRST_NAME_MAX_LENGTH'))
          .optional(),
    lastName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.LAST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.LAST_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.LAST_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.LAST_NAME_MAX_LENGTH'))
          .optional(),
    techUsername: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.TECH_USERNAME_MIN_LENGTH'))
          .max(30, t('VALIDATION.TECH_USERNAME_MAX_LENGTH'))
          .test(
            'name-test',
            t('VALIDATION.TECH_USERNAME_NOT_ALLOWED'),
            (value) => !value || !blacklistedUsernames.includes(value.trim()),
          )
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.TECH_USERNAME_MIN_LENGTH'))
          .max(30, t('VALIDATION.TECH_USERNAME_MAX_LENGTH'))
          .test(
            'name-test',
            t('VALIDATION.TECH_USERNAME_NOT_ALLOWED'),
            (value) => !value || !blacklistedUsernames.includes(value.trim()),
          )
          .optional(),
    companyName: validateOnSubmit
      ? Yup.string()
          .min(3, t('VALIDATION.COMPANY_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.COMPANY_NAME_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(3, t('VALIDATION.COMPANY_NAME_MIN_LENGTH'))
          .max(100, t('VALIDATION.COMPANY_NAME_MAX_LENGTH'))
          .optional(),
  });
};

export default function Account(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { usernames } = useSelector((state: { blacklist: BlacklistSliceState }) => state.blacklist);
  const blacklistedUsernames = useMemo(() => usernames.map((u) => u.username), [usernames]);

  const [doUpdateUser] = useMutation<{ updateUser?: User }>(updateUser);

  const [showEmailChanger, setShowEmailChanger] = useState(false);
  const [showPasswordChanger, setShowPasswordChanger] = useState(false);
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string, withMsg: boolean) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title: withMsg ? title : null,
        message: withMsg ? t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE') : title,
      }),
    [t],
  );

  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const splitFullName = useMemo(() => user?.fullName?.split(' ') || [], [user?.fullName]);
  const userFirstName = useMemo(
    () => user?.firstName || (splitFullName.length > 0 ? splitFullName[0] : ''),
    [splitFullName, user?.firstName],
  );
  const userLastName = useMemo(
    () => user?.lastName || (splitFullName.length > 1 ? splitFullName[1] : ''),
    [splitFullName, user?.lastName],
  );

  const { data, loading } = useQuery<{ getHosts?: Host[] }>(getHosts);
  const isAdmin = useMemo(() => userHasAdminRole(user as User), [user]);

  const doUpdate = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      if (!user) return;
      setSubmitting(true);

      doUpdateUser({
        variables: {
          input: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            techUsername: values.techUsername,
            role: user.role,
            languageIso6391: user.languageIso6391,
            countryIsoAlpha2: user.countryIsoAlpha2,
            position: values.position,
            companyName: values.companyName,
            companyType: values.companyType,
            twoFAEnabled: user.twoFAEnabled,
            productUpdatesEnabled: user.productUpdatesEnabled,
            maxDevHostsCount: user.maxDevHostsCount,
          } as UserInput,
        },
      })
        .then((result) => {
          if (result && result.data && result.data.updateUser) {
            dispatch(userLoaded(result.data.updateUser));
            showSuccessNotification(t('PRIVATE.PROFILE.ACCOUNT_SAVED_MSG'));
          } else {
            logger.error('No result for user update from Account');
            showErrorNotification(t('PRIVATE.PROFILE.ACCOUNT_NOT_SAVED_MSG'), true);
          }
        })
        .catch((error) => {
          logger.error(`User update error from Account: ${error}`);
          showErrorNotification(t('PRIVATE.PROFILE.ACCOUNT_NOT_SAVED_MSG'), true);
        })
        .finally(() => setSubmitting(false));
    },
    [dispatch, doUpdateUser, showErrorNotification, showSuccessNotification, t, user],
  );

  const handleSubmit = useCallback(
    (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      // verify username uniqueness if username has changed
      if (user?.techUsername !== values.techUsername) {
        verifyTechUsername(values.techUsername)
          .then((result: boolean) => {
            if (result) {
              doUpdate(values, setSubmitting);
            } else {
              showErrorNotification(t('PUBLIC.AUTH.REG.TECH_USERNAME_EXISTS'), false);
              setSubmitting(false);
            }
          })
          .catch(() => {
            showErrorNotification(t('PUBLIC.AUTH.REG.TECH_USERNAME_EXISTS'), false);
            setSubmitting(false);
          });
      } else {
        doUpdate(values, setSubmitting);
      }
    },
    [user, doUpdate, showErrorNotification, t],
  );

  // at least 1 host must be provided for the user so that he can see this page
  if (!isAdmin && !loading && !data?.getHosts?.length) {
    return <Redirect to={HOST} />;
  }
  if (loading) return <></>;

  return (
    <>
      <ChangeEmail show={showEmailChanger} onHide={() => setShowEmailChanger(false)} user={user} />
      <ChangePassword show={showPasswordChanger} onHide={() => setShowPasswordChanger(false)} user={user} />
      <Container className={styles.content}>
        <header className="d-flex align-items-center mb-4">
          <h1>{t('PRIVATE.PROFILE.MY_ACCOUNT')}</h1>
        </header>
        <Row>
          <Col>
            <div className="p-5 box">
              <h4 className="mb-5">{t('PRIVATE.PROFILE.PROFILE')}</h4>
              <Formik
                validationSchema={validationSchema(t, submitTriggered, blacklistedUsernames)}
                onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) =>
                  handleSubmit(values, setSubmitting)
                }
                initialValues={{
                  email: user?.email || '',
                  password: '********',
                  firstName: userFirstName.trim(),
                  lastName: userLastName.trim(),
                  techUsername: user?.techUsername || '',
                  position: (user?.position !== UserPosition.NONE
                    ? user?.position || UserPosition.DEVELOPER
                    : UserPosition.DEVELOPER) as UserPosition,
                  companyName: user?.companyName || '',
                  companyType: user?.companyType
                    ? [CompanyType.OEM, CompanyType.TIER_1_2, CompanyType.ACADEMIA, CompanyType.OTHER].includes(
                        user.companyType,
                      )
                      ? user.companyType
                      : CompanyType.OTHER
                    : CompanyType.OTHER,
                }}
              >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="position-relative">
                      <Form.Label>{t('PUBLIC.AUTH.EMAIL_ADDRESS')}</Form.Label>
                      <Form.Control readOnly plaintext type="text" name="email" value={values.email} />
                      <Button
                        variant="btn btn-link"
                        onClick={() => setShowEmailChanger(true)}
                        className={styles.changelink}
                      >
                        <small className="font-weight-bold">{t('PRIVATE.PROFILE.CHANGE')}</small>
                      </Button>
                    </Form.Group>
                    <Form.Group className="position-relative">
                      <Form.Label>{t('PUBLIC.AUTH.PASSWORD')}</Form.Label>
                      <Form.Control readOnly plaintext type="text" name="password" value={values.password} />
                      <Button
                        variant="btn btn-link"
                        onClick={() => setShowPasswordChanger(true)}
                        className={styles.changelink}
                      >
                        <small className="font-weight-bold">{t('PRIVATE.PROFILE.CHANGE')}</small>
                      </Button>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{t('PUBLIC.AUTH.FIRST_NAME')}</Form.Label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="firstName"
                        placeholder={t('PUBLIC.AUTH.FIRST_NAME_PLACEHOLDER')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        isInvalid={touched.firstName && !!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{t('PUBLIC.AUTH.LAST_NAME')}</Form.Label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="lastName"
                        placeholder={t('PUBLIC.AUTH.LAST_NAME_PLACEHOLDER')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        isInvalid={touched.lastName && !!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{t('PUBLIC.AUTH.TECH_USERNAME')}</Form.Label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="techUsername"
                        placeholder={t('PUBLIC.AUTH.TECH_USERNAME_PLACEHOLDER')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.techUsername}
                        isInvalid={touched.techUsername && !!errors.techUsername}
                      />
                      <Form.Control.Feedback type="invalid">{errors.techUsername}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{t('PRIVATE.PROFILE.ROLE')}</Form.Label>
                      <div className={classNames(styles.selector)}>
                        <UserPositionSelector
                          externallyManagedSelectedValues={false}
                          selectedValues={[values.position]}
                          onSelect={(value) => {
                            if (value.length === 1) {
                              values.position = value[0];
                            }
                          }}
                          controlShouldRenderValue={true}
                          isMulti={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{t('PRIVATE.PROFILE.COMPANY_NAME')}</Form.Label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="companyName"
                        placeholder={t('PRIVATE.PROFILE.COMPANY_NAME_PLACEHOLDER')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.companyName}
                        isInvalid={touched.companyName && !!errors.companyName}
                      />
                      <Form.Control.Feedback type="invalid">{errors.companyName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formGroupName">
                      <Form.Label>{t('PRIVATE.PROFILE.COMPANY_TYPE')}</Form.Label>
                      <div className={classNames(styles.selector)}>
                        <CompanyTypeSelector
                          externallyManagedSelectedValues={false}
                          selectedValues={[values.companyType]}
                          onSelect={(value) => {
                            if (value.length === 1) {
                              values.companyType = value[0];
                            }
                          }}
                          controlShouldRenderValue={true}
                          isMulti={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">{errors.companyType}</Form.Control.Feedback>
                    </Form.Group>

                    <div className="mt-4 text-right">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => setSubmitTriggered(true)}
                      >
                        {t('PRIVATE.PROFILE.SAVE_CTA')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
