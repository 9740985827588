import React from 'react';
import styles from './Root.module.scss';
import { Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Login from '../login/Login';
import Register from '../register/Register';
import PublicRoute from '../PublicRoute';
import { LOGIN, PASSWORD_RECOVER, PASSWORD_RESET, PROFILING, REGISTER, ROOT } from '../../../utils/routingUtils';
import Profiling from '../../private/profiling';
import PasswordRecover from '../passwordRecover';
import PasswordReset from '../passwordReset';

export default function Root(): JSX.Element {
  return (
    <>
      {/*<PublicNav />*/}
      <Container className={styles.content}>
        <Switch>
          <PublicRoute exact path={ROOT} component={Root} />
          <PublicRoute path={LOGIN} component={Login} />
          <PublicRoute path={REGISTER} component={Register} />
          <PublicRoute path={PROFILING} component={Profiling} />
          <PublicRoute path={PASSWORD_RECOVER} component={PasswordRecover} />
          <PublicRoute path={PASSWORD_RESET} component={PasswordReset} />
          <PublicRoute path={PROFILING} component={Profiling} />
        </Switch>
      </Container>
    </>
  );
}
