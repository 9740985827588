export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: string;
};

export const tablePageSize = [10, 30, 50, 100];

export type Paging = {
  __typename?: 'Paging';
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum OS {
  WINDOWS = 'windows',
  LINUX = 'linux',
}

export enum LanguageCode {
  EN = 'en',
}

export enum CountryCode {
  DE = 'de',
}

export enum SecurityRole {
  USER = 'user',
  ADMIN = 'admin',
}

export enum RecurringPeriod {
  MONTH = 'month',
  YEAR = 'year',
}

export enum Plans {
  TEAM = 'team',
  ENTERPRISE = 'enterprise',
}

export interface PlanData {
  name: string;
  description: string;
  priceId: string | null;
  amount: string;
  amountFormatted: string;
  amountDescription: string;
  cancelledAt: string | null;
}

export type Users = {
  __typename?: 'Users';
  users: Array<User>;
  paging: Paging;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>; // todo remove once migration from fullName is finished
  firstName?: Maybe<Scalars['String']>; // todo must be made mandatory once migration from fullName is finished
  lastName?: Maybe<Scalars['String']>; // todo must be made mandatory once migration from fullName is finished
  techUsername: Scalars['String'];
  techPassword?: Maybe<Scalars['String']>;
  role: SecurityRole;
  languageIso6391: LanguageCode;
  countryIsoAlpha2: CountryCode;
  position: UserPosition;
  companyName: Scalars['String'];
  companyType: CompanyType;
  supportWays: Scalars['String'];
  otherSupportWays: Scalars['String'];
  twoFAEnabled: Scalars['Boolean'];
  productUpdatesEnabled: Scalars['Boolean'];
  maxDevHostsCount: Scalars['Int'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  regIp?: Maybe<Scalars['String']>;
  createdByAdmin: Scalars['Boolean'];
  groups?: Maybe<Array<Group>>;
  condaKey?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

// todo LAB, AGENCY, PRODUCER - to be removed once the DB doesn't contain such values
export enum CompanyType {
  LAB = 'LAB',
  AGENCY = 'AGENCY',
  PRODUCER = 'PRODUCER',
  OEM = 'OEM',
  TIER_1_2 = 'TIER_1_2',
  ACADEMIA = 'ACADEMIA',
  OTHER = 'OTHER',
  NONE = 'NONE',
}

export enum UserPosition {
  DEVELOPER = 'DEVELOPER',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  TEAM_LEAD = 'TEAM_LEAD',
  SCIENTIST = 'SCIENTIST',
  NONE = 'NONE',
}

export type Host = {
  __typename?: 'Host';
  id: Scalars['String'];
  machineId: Scalars['String'];
  hostName: Scalars['String'];
  expirationDate: Scalars['String'];
  createdDate: Scalars['String'];
  hostQualifier: Scalars['String'];
};

export type DeveloperKey = {
  __typename?: 'DeveloperKey';
  hostIds: Array<Scalars['String']>;
  expirationDate: Scalars['String'];
  createdDate: Scalars['String'];
  developerKey?: Maybe<Scalars['String']>;
};

export type HostsData = {
  __typename?: 'HostsData';
  hosts?: Maybe<Array<Host>>;
  developerKey?: Maybe<DeveloperKey>;
};

export type BlacklistedUsernames = {
  __typename?: 'BlacklistedUsernames';
  usernames: Array<BlacklistedUsername>;
  paging: Paging;
};

export type BlacklistedUsername = {
  __typename?: 'BlacklistedUsername';
  id: Scalars['String'];
  username: Scalars['String'];
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
};

export type Groups = {
  __typename?: 'Groups';
  groups: Array<Group>;
  paging: Paging;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  membersCount: Scalars['Int'];
  members?: Maybe<Array<User>>;
  plan?: Maybe<Scalars['String']>;
  condaChannel?: Maybe<Scalars['String']>;
  condaKey?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
};

export type GroupData = {
  __typename?: 'GroupData';
  group: Group;
  subscriptions?: Maybe<Array<PspSubscription>>;
  subscriptionSchedules?: Maybe<Array<PspSubscriptionSchedule>>;
};

export type PspProduct = {
  __typename?: 'PspProduct';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PspPrice = {
  __typename?: 'PspPrice';
  id: Scalars['String'];
  product: Scalars['String'];
  amount: Scalars['String'];
  recurringPeriod: Scalars['String'];
};

export type PspInvoice = {
  __typename?: 'PspInvoice';
  created: Scalars['String'];
  number: Scalars['String'];
  hostedUrl?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
};

export type PspPromotionCode = {
  __typename?: 'PspPromotionCode';
  id: Scalars['String'];
  code: Scalars['String'];
  active: Scalars['Boolean'];
};

export type PspSubscriptionSchedule = {
  __typename?: 'PspSubscriptionSchedule';
  id: Scalars['String'];
  created: Scalars['String'];
  status: Scalars['String'];
  startDate: Scalars['String'];
  subscription?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  plan: Scalars['String'];
};

export type PspSubscription = {
  __typename?: 'PspSubscription';
  id: Scalars['String'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  cancelledAt?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  currentPeriodEnd: Scalars['String'];
  currentPeriodStart: Scalars['String'];
  discount?: Maybe<PspDiscount>;
  endedAt?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PspSubscriptionItem>>;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  status: PspSubscriptionStatus;
};

export type PspDiscount = {
  __typename?: 'PspDiscount';
  coupon?: Maybe<PspCoupon>;
};

export type PspCoupon = {
  __typename?: 'PspCoupon';
  id: Scalars['String'];
  amountOff: Maybe<Scalars['String']>;
  duration: Scalars['String'];
  name: Scalars['String'];
  percentOff: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export enum PspSubscriptionStatus {
  ACTIVE = 'active',
}

export type PspSubscriptionItem = {
  __typename?: 'PspSubscriptionItem';
  price?: Maybe<PspPrice>;
};

export type PspCustomer = {
  __typename?: 'PspCustomer';
  address?: Maybe<PspCustomerAddress>;
  invoiceSettings: PspCustomerInvoiceSettings;
  taxId?: Maybe<Scalars['String']>;
};

export type PspCustomerAddress = {
  __typename?: 'PspCustomerAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type PspCustomerInvoiceSettings = {
  __typename?: 'PspCustomerInvoiceSettings';
  defaultPaymentMethodObject?: Maybe<PspPaymentMethod>;
};

export type PspPaymentMethod = {
  __typename?: 'PspPaymentMethod';
  type: Scalars['String'];
  card?: Maybe<PspPaymentMethodCard>;
  sepaDebit?: Maybe<PspPaymentMethodSepaDebit>;
};

export type PspPaymentMethodCard = {
  __typename?: 'PspPaymentMethodCard';
  brand: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  last4: Scalars['String'];
};

export type PspPaymentMethodSepaDebit = {
  __typename?: 'PspPaymentMethodSepaDebit';
  bankCode: Scalars['String'];
  last4: Scalars['String'];
};
