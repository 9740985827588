import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AdminUsers.module.scss';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ADMIN_USERS, NEW } from '../../../../utils/routingUtils';
import { getFullName, getFullNameAbbr, userHasAdminRole } from '../../../../utils/userUtils';
import { OrderBy, SearchPaging, Sorting, UserSearchCriteria } from '../../../../types/inputTypes.d';
import { tablePageSize, User, Users } from '../../../../types/types.d';
import { useLazyQuery } from '@apollo/client';
import { getUsers } from '../../../../graphql/queries';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../utils/Loading';
import { ReactComponent as LeftIcon } from '../../../../assets/img/left.svg';
import { ReactComponent as RightIcon } from '../../../../assets/img/right.svg';
import { ReactComponent as SortIcon } from '../../../../assets/img/sort.svg';
import { Store } from 'react-notifications-component';
import { baseErrorNotification } from '../../../../utils/nitificationUtils';
import { primary } from '../../../../utils/styleUtils';
import classNames from 'classnames';
import DeleteUser from './deleteUser';
import moment from 'moment';
import { getLocalisedPosition } from '../../../../utils/businessUtils';
import { DATE_TIME_FORMAT } from '../../../../utils/dateTimeUtils';
import { ReactComponent as OptionsIcon } from '../../../../assets/img/options.svg';

interface TableState {
  page: number;
  perPage: number;
  sorting: Sorting | null;
  emailFilter: string;
}

/**
 * Admin page where an admin can see list of users and manage them
 */
export default function AdminUsers(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [openedOptions, setOpenedOptions] = useState<string | null>(null);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    perPage: tablePageSize[2],
    sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
    emailFilter: '',
  });

  const [fetchUsers, { data, loading }] = useLazyQuery<{ getUsers: Users }>(getUsers);
  const doFetchUsers = useCallback(() => {
    fetchUsers({
      variables: {
        paging: {
          page: tableState.page,
          perPage: tableState.perPage,
        } as SearchPaging,
        sorting: tableState.sorting,
        searchCriteria: {
          email: tableState.emailFilter,
        } as UserSearchCriteria,
      },
    });
  }, [fetchUsers, tableState]);
  useEffect(() => doFetchUsers(), [doFetchUsers]);

  const users: User[] = useMemo(() => data?.getUsers?.users || [], [data?.getUsers?.users]);
  const totalCount = useMemo(() => data?.getUsers?.paging?.totalCount || 0, [data?.getUsers?.paging?.totalCount]);
  const pageCount = useMemo(() => data?.getUsers?.paging?.pageCount || 0, [data?.getUsers?.paging?.pageCount]);
  const firstPageShown = useMemo(() => tableState.page === 0, [tableState.page]);
  const lastPageShown = useMemo(() => tableState.page === pageCount - 1, [pageCount, tableState.page]);

  const handlePreviousPage = useCallback(() => {
    if (!firstPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page - 1,
      }));
    }
  }, [firstPageShown]);
  const handleNextPage = useCallback(() => {
    if (!lastPageShown) {
      setTableState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [lastPageShown]);

  const handleSorting = useCallback((orderBy: OrderBy) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: { orderBy: orderBy } as Sorting,
    }));
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setTableState((prevState) => ({
      ...prevState,
      emailFilter: !!value?.length ? value : '',
    }));
  }, []);

  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const openUser = useCallback((user: User) => history.push(`${ADMIN_USERS}/${user.id}`), [history]);
  const createUser = useCallback(() => history.push(`${ADMIN_USERS}/${NEW}`), [history]);

  const handleUserDelete = useCallback(() => {
    setTableState((prevState) => ({
      ...prevState,
      page: 0,
    }));
    doFetchUsers();
    // close modal
    setUserToDelete(null);
  }, [doFetchUsers]);

  const buttonSorting = (
    <div className={styles.action}>
      <DropdownButton variant="link" alignRight={false} className={styles.sort} title={<SortIcon />}>
        {[
          {
            order: OrderBy.CreatedDateDesc,
            title: t('PRIVATE.ADMIN.USERS.SORTING.CREATED_DATE_DESC'),
          },
          { order: OrderBy.CreatedDateAsc, title: t('PRIVATE.ADMIN.USERS.SORTING.CREATED_DATE_ASC') },
          { order: OrderBy.EmailAsc, title: t('PRIVATE.ADMIN.USERS.SORTING.EMAIL_ASC') },
          { order: OrderBy.EmailDesc, title: t('PRIVATE.ADMIN.USERS.SORTING.EMAIL_DESC') },
          { order: OrderBy.FirstNameAsc, title: t('PRIVATE.ADMIN.USERS.SORTING.FIRST_NAME_ASC') },
          { order: OrderBy.FirstNameDesc, title: t('PRIVATE.ADMIN.USERS.SORTING.FIRST_NAME_DESC') },
          { order: OrderBy.LastNameAsc, title: t('PRIVATE.ADMIN.USERS.SORTING.LAST_NAME_ASC') },
          { order: OrderBy.LastNameDesc, title: t('PRIVATE.ADMIN.USERS.SORTING.LAST_NAME_DESC') },
          { order: OrderBy.RoleAsc, title: t('PRIVATE.ADMIN.USERS.SORTING.ROLE_ASC') },
          { order: OrderBy.RoleDesc, title: t('PRIVATE.ADMIN.USERS.SORTING.ROLE_DESC') },
        ].map(({ order, title }) => (
          <Dropdown.Item
            key={order}
            style={{ color: order === tableState.sorting?.orderBy ? primary : '' }}
            onClick={() => handleSorting(order)}
          >
            {title}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      {userToDelete && (
        <DeleteUser
          show={!!userToDelete}
          onHide={() => setUserToDelete(null)}
          user={userToDelete}
          onDelete={handleUserDelete}
        />
      )}
      <Container className="box full-h p-5">
        <div className="d-flex">
          <h1 className="flex-grow-1">{t('PRIVATE.ADMIN.USERS.TITLE')}</h1>
          <Button variant="primary" onClick={createUser}>
            {t('PRIVATE.ADMIN.USERS.CREATE_USER')}
          </Button>
        </div>
        <Row>
          <Col>
            <div className={styles.toolbar}>
              <div className={styles.sortAndSearch}>
                {buttonSorting}
                <input
                  className={styles.search}
                  placeholder={t('PRIVATE.ADMIN.USERS.SEARCH_BY_EMAIL')}
                  onChange={handleSearch}
                />
              </div>
              <div className={styles.paginationInfo}>
                {t('PRIVATE.ADMIN.USERS.PAGINATION_INFO', {
                  start: tableState.page * tableState.perPage + 1,
                  end:
                    totalCount < (tableState.page + 1) * tableState.perPage
                      ? totalCount
                      : (tableState.page + 1) * tableState.perPage,
                  total: totalCount,
                })}
                {pageCount > 1 && (
                  <div className={styles.previousNext}>
                    <div
                      className={styles.previous}
                      style={{
                        opacity: firstPageShown ? 0.2 : 0.8,
                        cursor: firstPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handlePreviousPage()}
                    >
                      <LeftIcon />
                    </div>
                    <div
                      className={styles.next}
                      style={{
                        opacity: lastPageShown ? 0.2 : 0.8,
                        cursor: lastPageShown ? 'default' : 'pointer',
                      }}
                      onClick={() => handleNextPage()}
                    >
                      <RightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {loading && (
                <div className={styles.loadingWrapperOrError}>
                  <Loading />
                </div>
              )}
              {!loading && totalCount === 0 && (
                <div className={styles.loadingWrapperOrError}>{t('PRIVATE.ADMIN.USERS.NO_USERS_FOUND')}</div>
              )}
              {!loading && totalCount > 0 && (
                <>
                  <div className={classNames('data-row', styles.tableHeader)}>
                    <div className={styles.placeholderAvatar} />
                    <div className={styles.nameAndEmail}>User</div>
                    <div className={styles.createdDate}>Created date</div>
                    <div className={styles.companyAndPosition}>Company & position</div>
                    <div className={styles.role}>Role</div>
                    <div className={styles.placeholderOptions} />
                  </div>
                  {users.map((user, index) => (
                    <div
                      key={index}
                      className={classNames('data-row row-clickable', styles.userRow)}
                      onClick={() => openUser(user)}
                    >
                      <div>
                        <figure className="avatar">{getFullNameAbbr(user)}</figure>
                      </div>
                      <div className={styles.nameAndEmail}>
                        <div>{getFullName(user)}</div>
                        <div className={styles.email}>{user.email}</div>
                      </div>
                      <div className={styles.createdDate}>{moment(user.createdDate).format(DATE_TIME_FORMAT)}</div>
                      <div className={styles.companyAndPosition}>
                        <div>{user.companyName}</div>
                        <div className={styles.position}>{getLocalisedPosition(user.position, t)}</div>
                      </div>
                      <div className={styles.role}>{user.role}</div>
                      <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                          onToggle={(isOpen) => {
                            if (isOpen) setOpenedOptions(user.id);
                            else setOpenedOptions(null);
                          }}
                          show={openedOptions === user.id}
                        >
                          <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                            <OptionsIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu alignRight>
                            <Dropdown.Item
                              onClick={() => {
                                if (userHasAdminRole(user)) {
                                  showErrorNotification(t('PRIVATE.ADMIN.USERS.DELETE_USER.ADMIN_CANNOT_BE_DELETED'));
                                } else {
                                  setUserToDelete(user);
                                }
                              }}
                            >
                              {t('PRIVATE.ADMIN.USERS.DELETE_USER.TITLE')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
