import styles from './ChangePlan.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocalisedCurrencyFormatterWithoutDecimal } from '../../../../utils/i18nUtils';
import { ReactComponent as SingleCheckIcon } from '../../../../assets/img/singleCheck.svg';
import { ReactComponent as DoubleCheckIcon } from '../../../../assets/img/doubleCheck.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/img/check.svg';
import { redirectToExternalUrl } from '../../../../utils/windowUtils';
import { useSelector } from 'react-redux';
import { PspSliceState } from '../../../../slices/pspSlice';
import ReactSwitch from 'react-switch';
import { primary } from '../../../../utils/styleUtils';
import { formatPriceAmount } from '../../../../utils/businessUtils';
import { PlanData, Plans, RecurringPeriod } from '../../../../types/types.d';

interface Props {
  onHide: () => void;
  priceId: string | null;
  onPlanSelected: (data: PlanData) => void;
}

/**
 * Shows form to change the current subscription plan
 */
export default function ChangePlan({ onHide, priceId, onPlanSelected }: Props): JSX.Element {
  const { t } = useTranslation();
  const currencyFormatter = useLocalisedCurrencyFormatterWithoutDecimal();

  const { products, prices } = useSelector((state: { psp: PspSliceState }) => state.psp);

  const [isMonthly, setIsMonthly] = useState(true);

  // update toggle according to the current plan period
  useEffect(() => {
    if (!priceId) return;
    const period = prices?.find((p) => p.id === priceId)?.recurringPeriod;
    if (period && period === RecurringPeriod.MONTH) {
      setIsMonthly(true);
    } else if (period && period === RecurringPeriod.YEAR) {
      setIsMonthly(false);
    }
  }, [priceId, prices]);

  const teamProduct = useMemo(() => products?.find((p) => p.name.toLowerCase() === Plans.TEAM), [products]);
  const enterpriseProduct = useMemo(() => products?.find((p) => p.name.toLowerCase() === Plans.ENTERPRISE), [products]);
  const teamPrice = useMemo(
    () =>
      prices?.find(
        (p) =>
          p.product === teamProduct?.id &&
          p.recurringPeriod === (isMonthly ? RecurringPeriod.MONTH : RecurringPeriod.YEAR),
      ),
    [isMonthly, prices, teamProduct?.id],
  );
  const enterprisePrice = useMemo(
    () =>
      prices?.find(
        (p) =>
          p.product === enterpriseProduct?.id &&
          p.recurringPeriod === (isMonthly ? RecurringPeriod.MONTH : RecurringPeriod.YEAR),
      ),
    [isMonthly, prices, enterpriseProduct?.id],
  );

  const priceDescription = useMemo(
    () => `${t('COMMON.PER_USER')}/${isMonthly ? t('COMMON.MONTH') : t('COMMON.YEAR')}`,
    [isMonthly, t],
  );

  const plansData = useMemo(
    () => [
      {
        name: t('PLANS.FREE.NAME'),
        description: t('PLANS.FREE.DESCRIPTION'),
        price: currencyFormatter.format(0),
        priceDescription,
        cta: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.FREE.CTA'),
        ctaCallback: () =>
          onPlanSelected({
            name: t('PLANS.FREE.NAME'),
            description: t('PLANS.FREE.DESCRIPTION'),
            priceId: null,
            amount: '0',
            amountFormatted: currencyFormatter.format(0),
            amountDescription: priceDescription,
            cancelledAt: null,
          }),
        items: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.FREE.ITEMS').split('\n'),
        selected: !priceId,
        priceId: null,
      },
      {
        name: t('PLANS.TEAM.NAME'),
        description: t('PLANS.TEAM.DESCRIPTION'),
        price: formatPriceAmount(currencyFormatter, teamPrice?.amount || ''),
        priceDescription,
        cta: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.TEAM.CTA'),
        ctaCallback: () =>
          onPlanSelected({
            name: t('PLANS.TEAM.NAME'),
            description: t('PLANS.TEAM.DESCRIPTION'),
            priceId: teamPrice?.id || null,
            amount: teamPrice?.amount || '',
            amountFormatted: formatPriceAmount(currencyFormatter, teamPrice?.amount || ''),
            amountDescription: priceDescription,
            cancelledAt: null,
          }),
        items: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.TEAM.ITEMS').split('\n'),
        selected: !!priceId && teamPrice?.id === priceId,
        priceId: teamPrice?.id,
      },
      {
        name: t('PLANS.ENTERPRISE.NAME'),
        description: t('PLANS.ENTERPRISE.DESCRIPTION'),
        price: formatPriceAmount(currencyFormatter, enterprisePrice?.amount || ''),
        priceDescription,
        cta: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.ENTERPRISE.CTA'),
        ctaCallback: () =>
          onPlanSelected({
            name: t('PLANS.ENTERPRISE.NAME'),
            description: t('PLANS.ENTERPRISE.DESCRIPTION'),
            priceId: enterprisePrice?.id || null,
            amount: enterprisePrice?.amount || '',
            amountFormatted: formatPriceAmount(currencyFormatter, enterprisePrice?.amount || ''),
            amountDescription: priceDescription,
            cancelledAt: null,
          }),
        items: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.ENTERPRISE.ITEMS').split('\n'),
        selected: !!priceId && enterprisePrice?.id === priceId,
        priceId: enterprisePrice?.id,
      },
      {
        name: t('PLANS.ULTIMATE.NAME'),
        description: t('PLANS.ULTIMATE.DESCRIPTION'),
        price: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.ULTIMATE.PRICE'),
        priceDescription: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.ULTIMATE.PRICE_CTA'),
        cta: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.ULTIMATE.CTA'),
        items: t('PRIVATE.COMMON.CHANGE_PLAN.PLANS.ULTIMATE.ITEMS').split('\n'),
        ctaCallback: () => redirectToExternalUrl(t('PRIVATE.COMMON.CHANGE_PLAN.CONTACT_US')),
        selected: false,
        priceId: null,
      },
    ],
    [
      t,
      currencyFormatter,
      priceDescription,
      priceId,
      teamPrice?.amount,
      teamPrice?.id,
      enterprisePrice?.amount,
      enterprisePrice?.id,
      onPlanSelected,
    ],
  );

  return (
    <Modal show={true} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.COMMON.CHANGE_PLAN.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.periodChanger}>
          <div className={styles.monthly}>{t('PRIVATE.COMMON.CHANGE_PLAN.MONTHLY')}</div>
          <div className={styles.toggle}>
            <ReactSwitch
              onChange={() => setIsMonthly(!isMonthly)}
              checked={!isMonthly}
              width={50}
              height={25}
              checkedIcon={false}
              uncheckedIcon={false}
              offColor={primary}
              onColor={primary}
            />
          </div>
          <div className={styles.annually}>{t('PRIVATE.COMMON.CHANGE_PLAN.ANNUALLY')}</div>
          <div className={styles.savePercentage}>{t('PRIVATE.COMMON.CHANGE_PLAN.SAVE_PERCENTAGE')}</div>
        </div>
        <Row className={styles.plans}>
          {plansData.map(({ name, description, price, items, cta, ctaCallback, selected }) => {
            return (
              <Col key={name} className={styles.plan}>
                <div className={styles.name}>{name}</div>
                <div className={styles.description}>{description}</div>
                <div className={styles.price}>{price}</div>
                <div className={styles.priceDesc}>{priceDescription}</div>
                <div className={styles.items}>
                  {items.map((i, index) => (
                    <div key={index} className={styles.item}>
                      <div className={styles.check}>
                        {i.startsWith('Everything') ? <DoubleCheckIcon /> : <SingleCheckIcon />}
                      </div>
                      <div
                        className={styles.text}
                        style={{ fontWeight: i.startsWith('Everything') ? 'bold' : 'normal' }}
                      >
                        {i}
                      </div>
                    </div>
                  ))}
                </div>
                <Button variant="primary" className={styles.cta} onClick={ctaCallback} disabled={selected}>
                  {selected && (
                    <span className={styles.check}>
                      <CheckIcon />
                    </span>
                  )}
                  <span>{selected ? name : cta}</span>
                </Button>
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
