import { OS } from '../types/types';

export const capitalizeFirstLetter = (value: string | null): string | null => {
  if (value && value.length > 0) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return null;
  }
};

export const getLocalisedOs = (value: OS): string => {
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}`;
};

export const makeId = (len: number | undefined = 16): string => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
