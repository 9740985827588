import React, { useContext, useMemo } from 'react';
import styles from './PlanAndBilling.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { GroupProviderContext, GroupProviderContextProps } from '../GroupProvider';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../utils/dateTimeUtils';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as OptionsIcon } from '../../../../../assets/img/options.svg';

export default function PlanAndBilling(): JSX.Element {
  const { t } = useTranslation();
  const {
    subscriptions,
    subscriptionSchedules,
    isGroupOwner,
    currentPlanData: { name, amountFormatted, amountDescription, cancelledAt },
    onShowPlans,
    onShowSubscriptionsHistory,
  } = useContext(GroupProviderContext) as GroupProviderContextProps;

  const { futurePlan, futureStartDate } = useMemo(() => {
    if (!!subscriptionSchedules?.length) {
      return {
        futurePlan: subscriptionSchedules[0].plan,
        futureStartDate: moment.unix(parseInt(subscriptionSchedules[0].startDate)),
      };
    } else if (cancelledAt) {
      return {
        futurePlan: t('PLANS.FREE.NAME'),
        futureStartDate: moment.unix(parseInt(cancelledAt)).add(1, 'day'),
      };
    } else {
      return {
        futurePlan: null,
        futureStartDate: null,
      };
    }
  }, [cancelledAt, subscriptionSchedules, t]);

  return (
    <div className={classNames('box', styles.content)}>
      <div className={styles.header}>{t('PRIVATE.COMMON.GROUP.PLAN_AND_BILLING.TITLE')}</div>
      <div className={styles.plan}>
        <div className={styles.change}>
          {isGroupOwner ? (
            <>
              <div className={styles.cta} onClick={onShowPlans}>
                {name}
              </div>
              {!!subscriptions.length && (
                <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                      <OptionsIcon />
                    </Dropdown.Toggle>

                    <Dropdown.Menu alignRight>
                      {!!subscriptions.length && (
                        <Dropdown.Item onClick={onShowSubscriptionsHistory}>
                          {t('PRIVATE.COMMON.GROUP.PLAN_AND_BILLING.SUBSCRIPTIONS_HISTORY')}
                        </Dropdown.Item>
                      )}
                      {/*<Dropdown.Item onClick={onShowPaymentSettings}>*/}
                      {/*  {t('PRIVATE.COMMON.GROUP.PLAN_AND_BILLING.PAYMENT_SETTINGS')}*/}
                      {/*</Dropdown.Item>*/}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </>
          ) : (
            <div className={styles.planName}>{name}</div>
          )}
        </div>
        {isGroupOwner && (
          <div className={styles.amount}>
            {amountFormatted} {amountDescription}
          </div>
        )}
        {isGroupOwner && futurePlan && futureStartDate && (
          <div className={styles.schedule}>{`${futurePlan} ${t('COMMON.FROM')} ${futureStartDate.format(
            DATE_FORMAT,
          )}`}</div>
        )}
        {/*{isGroupOwner && !!subscriptions.length && (*/}
        {/*  <div className={styles.subscriptionsHistory} onClick={onShowSubscriptionsHistory}>*/}
        {/*    {t('PRIVATE.COMMON.GROUP.PLAN_AND_BILLING.SUBSCRIPTIONS_HISTORY')}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
}
