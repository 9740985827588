import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { userHasUserOrAdminRoles, userProfilingCompleted } from '../../utils/userUtils';
import { HOME, LOGIN, PROFILING, ROOT } from '../../utils/routingUtils';
import { useSelector } from 'react-redux';
import { AuthSliceState } from '../../slices/authSlice';

/**
 * @return provided component if the provided user is not defined,
 * otherwise redirects to the corresponding to the user's role EmailConfirmation page
 */
export default function PublicRoute({ path, ...rest }: RouteProps): JSX.Element {
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);

  if (!user) {
    return path === ROOT ? <Redirect {...rest} to={{ pathname: LOGIN }} /> : <Route {...rest} render={undefined} />;
  } else {
    if (userHasUserOrAdminRoles(user)) {
      return userProfilingCompleted(user) ? (
        <Redirect to={{ pathname: HOME }} />
      ) : (
        <Redirect to={{ pathname: PROFILING }} />
      );
    } else {
      return <Redirect {...rest} to={{ pathname: LOGIN }} />;
    }
  }
}
