import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Form } from 'react-bootstrap';
import { LOGIN, REGISTER } from '../../../utils/routingUtils';
import { useTranslation } from 'react-i18next';
import { initializePasswordReset } from '../../../services/user.service';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { gsap } from 'gsap';

interface Values {
  email: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(100, t('VALIDATION.EMAIL_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string().email(t('VALIDATION.EMAIL_VALID')).max(100, t('VALIDATION.EMAIL_MAX_LENGTH')).optional(),
  });
};

/**
 * Handles a link of type http://localhost:3000/#/recover-password
 *
 * Note: special use cases like EMAIL_NOT_CONFIRMED, USER_NOT_FOUND are not covered and user is not notified about such error
 */
export default function PasswordRecover(): JSX.Element {
  const { t } = useTranslation();
  const successAlert = useRef(null);
  const formContent = useRef(null);

  const [recoveryFailed, setRecoveryFailed] = useState(false);
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const handleSubmit = (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
    setSubmitting(true);

    initializePasswordReset(values.email)
      .then((result: boolean) => {
        if (result) {
          const tl = gsap.timeline();
          // eslint-disable-next-line prettier/prettier
          tl
            //.set(form.current, { css:  { height:  form.current.height } })
            .to(formContent.current, { duration: 0.3, autoAlpha: 0, ease: 'power2.easeOut' })
            .set(formContent.current, { css: { display: 'none' } })
            .set(successAlert.current, { autoAlpha: 0, css: { display: 'block' } })
            .to(formContent.current, { duration: 0.3, autoAlpha: 0, ease: 'power2.easeOut' });
        } else {
          setRecoveryFailed(true);
          setSubmitting(false);
        }
      })
      .catch(() => {
        setRecoveryFailed(true);
        setSubmitting(false);
      });
  };

  return (
    <div className="authpage">
      <h1 className="text-center">{t('PUBLIC.AUTH.PASSWORD_RESET')}</h1>
      <div className="authbox">
        <Formik
          validationSchema={validationSchema(t, submitTriggered)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            email: '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="authform">
              <div ref={formContent}>
                {recoveryFailed ? (
                  <Alert variant="warning" className="mb-3">
                    <small>
                      {t('PUBLIC.AUTH.RECOVERY.FAILED') + ' '}
                      <Link to={REGISTER}>{t('PUBLIC.AUTH.RECOVERY.FAILED_SUGNUP')}</Link>
                    </small>
                  </Alert>
                ) : (
                  <></>
                )}
                <Form.Group controlId="formGroupEmail">
                  <Form.Label>{t('PUBLIC.AUTH.EMAIL_ADDRESS')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder={t('PUBLIC.AUTH.EMAIL_ADDRESS_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <div className="mt-2">
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    block
                    disabled={isSubmitting}
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {t('PUBLIC.AUTH.RECOVERY.CTA')}
                  </Button>
                </div>

                <small id="emailHelp" className="form-text mt-3 text-center">
                  <span>{t('PUBLIC.AUTH.HAVE_ACCOUNT')}</span>{' '}
                  <Link to={LOGIN} className="alert-link">
                    {t('PUBLIC.AUTH.HAVE_ACCOUNT_CTA')}
                  </Link>
                </small>
                <small id="emailHelp" className="form-text mt-1 text-center">
                  <span>{t('PUBLIC.AUTH.NO_ACCOUNT')}</span>{' '}
                  <Link to={REGISTER} className="alert-link">
                    {t('PUBLIC.AUTH.NO_ACCOUNT_CTA')}
                  </Link>
                </small>
              </div>
              <Alert variant="success" style={{ display: 'none' }} ref={successAlert}>
                {t('PUBLIC.AUTH.RECOVERY.SUCCESS')}
              </Alert>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
