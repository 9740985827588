import styles from './SubscriptionsHistory.module.scss';
import React, { useCallback, useMemo } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import { ReactComponent as OptionsIcon } from '../../../../assets/img/options.svg';
import { PspSubscription, PspSubscriptionItem, PspSubscriptionSchedule } from '../../../../types/types';
import { DATE_FORMAT } from '../../../../utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import { PspSliceState } from '../../../../slices/pspSlice';

interface Props {
  onHide: () => void;
  subscriptions: PspSubscription[];
  subscriptionSchedules: PspSubscriptionSchedule[];
  onViewInvoices: (subscriptionId: string) => void;
}

export default function SubscriptionsHistory({
  onHide,
  subscriptions,
  subscriptionSchedules,
  onViewInvoices,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { products } = useSelector((state: { psp: PspSliceState }) => state.psp);
  const productsById = useMemo(() => new Map(products.map((p) => [p.id, p])), [products]);
  const getPlanName = useCallback(
    (items: PspSubscriptionItem[]) => {
      if (!items.length) return '';
      return productsById.get(items[0].price?.product || '')?.name || '';
    },
    [productsById],
  );

  const data = useMemo(() => {
    const subs = subscriptions.map((s) => ({
      id: s.id,
      created: s.created,
      name: getPlanName(s.items || []),
      status: s.status,
      startDate: moment.unix(parseInt(s.startDate)).format(DATE_FORMAT),
      endDate: s.endedAt
        ? moment.unix(parseInt(s.endedAt)).format(DATE_FORMAT)
        : s.cancelAtPeriodEnd
        ? moment.unix(parseInt(s.currentPeriodEnd)).format(DATE_FORMAT)
        : '',
    }));
    const schedules = subscriptionSchedules.map((s) => ({
      id: s.id,
      created: s.created,
      name: s.plan,
      status: s.status.replaceAll('_', ' '),
      startDate: moment.unix(parseInt(s.startDate)).format(DATE_FORMAT),
      endDate: '',
    }));
    return [...schedules, ...subs];
  }, [getPlanName, subscriptionSchedules, subscriptions]);

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={classNames('data-row', styles.tableHeader)}>
          <div className={styles.plan}>{t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.PLAN')}</div>
          <div className={styles.status}>{t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.STATUS')}</div>
          <div className={styles.date}>{t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.START_DATE')}</div>
          <div className={styles.date}>{t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.END_DATE')}</div>
          {/*<div className={styles.currentPeriodStartDate}>*/}
          {/*  {t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.CURRENT_PERIOD_START_DATE')}*/}
          {/*</div>*/}
          {/*<div className={styles.currentPeriodEndDate}>*/}
          {/*  {t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.CURRENT_PERIOD_END_DATE')}*/}
          {/*</div>*/}
          {/*<div className={styles.cancelDate}>{t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.CANCEL_DATE')}</div>*/}
          <div className={styles.placeholderOptions} />
        </div>
        {data.map((i) => (
          <div key={i.created} className={classNames('data-row', styles.item)}>
            <div className={styles.plan}>{i.name}</div>
            <div className={styles.status}>{i.status}</div>
            <div className={styles.date}>{i.startDate}</div>
            <div className={styles.date}>{i.endDate}</div>
            {/*<div className={styles.currentPeriodStartDate}>*/}
            {/*  {moment.unix(parseInt(i.currentPeriodStart)).format(DATE_FORMAT)}*/}
            {/*</div>*/}
            {/*<div className={styles.currentPeriodEndDate}>*/}
            {/*  {moment.unix(parseInt(i.currentPeriodEnd)).format(DATE_FORMAT)}*/}
            {/*</div>*/}
            {/*<div className={styles.cancelDate}>*/}
            {/*  {i.cancelledAt ? moment.unix(parseInt(i.cancelledAt)).format(DATE_FORMAT) : ''}*/}
            {/*</div>*/}
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="link" className={classNames('row-functions', styles.options)}>
                  <OptionsIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight={true}>
                  <Dropdown.Item onClick={() => onViewInvoices(i.id)}>
                    {t('PRIVATE.COMMON.SUBSCRIPTIONS_HISTORY.VIEW_INVOICES')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}
