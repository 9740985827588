import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Alert, Button, Form } from 'react-bootstrap';
import { LOGIN, PASSWORD_RECOVER } from '../../../utils/routingUtils';
import { useTranslation } from 'react-i18next';
import { resetPassword, verifyPasswordResetKey } from '../../../services/user.service';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { gsap } from 'gsap';

interface Values {
  password: string;
  password2: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    password: validateOnSubmit
      ? Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(8, t('VALIDATION.PASSWORD_MIN_LENGTH'))
          .max(100, t('VALIDATION.PASSWORD_MAX_LENGTH'))
          .optional(),
    password2: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Handles a link of type http://localhost:3000/#/reset-password?key=4205648725691707
 *
 * Note: special use cases like EMAIL_NOT_CONFIRMED, USER_NOT_FOUND, PASSWORD_RESET_EXPIRED are not covered
 * and user is not notified about such error
 */
export default function PasswordReset(): JSX.Element {
  const { t } = useTranslation();
  const successAlert = useRef(null);
  const formContent = useRef(null);
  const key = useQuery().get('key') as string;

  const [resetFailed, setResetFailed] = useState(false);
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [keyValid, setKeyValid] = useState(true);

  useEffect(() => {
    verifyPasswordResetKey(key).then((result) => {
      setKeyValid(result);
    });
  });

  if (!key) {
    return <Redirect to={PASSWORD_RECOVER} />;
  }

  const handleSubmit = (values: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
    setSubmitting(true);

    resetPassword(values.password, key)
      .then((result: boolean) => {
        if (result) {
          const tl = gsap.timeline();
          // eslint-disable-next-line prettier/prettier
          tl
            //.set(form.current, { css:  { height:  form.current.height } })
            .to(formContent.current, { duration: 0.3, autoAlpha: 0, ease: 'power2.easeOut' })
            .set(formContent.current, { css: { display: 'none' } })
            .set(successAlert.current, { autoAlpha: 0, css: { display: 'block' } })
            .to(formContent.current, { duration: 0.3, autoAlpha: 0, ease: 'power2.easeOut' });
        } else {
          setResetFailed(true);
          setSubmitting(false);
        }
      })
      .catch(() => {
        setResetFailed(true);
        setSubmitting(false);
      });
  };

  return (
    <div className="authpage">
      <h1 className="text-center">{t('PUBLIC.AUTH.RESET.HEADING')}</h1>
      {!keyValid && (
        <Alert variant="danger" className="mt-4">
          {t('PUBLIC.AUTH.RESET.OLDKEY') + ' '}
          <Link to={PASSWORD_RECOVER}>{t('PUBLIC.AUTH.RESET.FAILED_CTA')}</Link>
        </Alert>
      )}

      <div className="authbox">
        <Formik
          validationSchema={validationSchema(t, submitTriggered)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            password: '',
            password2: '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="authform">
              <div ref={formContent}>
                {resetFailed ? (
                  <Alert variant="warning" className="mb-3">
                    <small>
                      {t('PUBLIC.AUTH.RESET.FAILED') + ' '}
                      <Link to={PASSWORD_RECOVER}>{t('PUBLIC.AUTH.RESET.FAILED_CTA')}</Link>
                    </small>
                  </Alert>
                ) : (
                  <></>
                )}
                <Form.Group controlId="formGroupPassword">
                  <Form.Label>{t('PUBLIC.AUTH.RESET.NEW_PASSWORD')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder={t('PUBLIC.AUTH.RESET.NEW_PASSWORD_PLACEHOLDER')}
                    autoComplete="true"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formGroupPassword">
                  <Form.Label>{t('PUBLIC.AUTH.RESET.CONFIRM_PASSWORD')}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="password"
                    name="password2"
                    placeholder={t('PUBLIC.AUTH.RESET.CONFIRM_PASSWORD_PLACEHOLDER')}
                    autoComplete="true"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password2}
                    isInvalid={touched.password2 && !!errors.password2}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
                </Form.Group>

                <div className="mt-2">
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    block
                    disabled={isSubmitting}
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {t('PUBLIC.AUTH.RESET.CTA')}
                  </Button>
                </div>
              </div>
              <Alert variant="success" style={{ display: 'none' }} ref={successAlert}>
                <div>{t('PUBLIC.AUTH.RESET.SUCCESS')}</div>
                <br />
                <Link to={LOGIN} className="alert-link">
                  {t('PUBLIC.AUTH.RESET.LOGIN')}
                </Link>
              </Alert>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
