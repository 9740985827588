import { DeveloperKey, Host, UserPosition } from '../types/types.d';
import { TFunction } from 'i18next';

export const condarcContent = (baseUrl: string, userToken: string): string => `channels:
  - https://${baseUrl}/t/${userToken}/get/link-developers/
  - https://${baseUrl}/t/${userToken}/get/draive-release/
  - conda-forge


auto_activate_base: false
channel_priority: strict
`;

export const linkhqFileName = 'link2.hq';
export const link2dkFileName = 'link2.dk';

export const developerKeyContainsHost = (developerKey: DeveloperKey, host: Host): boolean =>
  developerKey.hostIds?.includes(host.id);

export const getLocalisedPosition = (value: UserPosition, t: TFunction): string => {
  switch (value) {
    case UserPosition.DEVELOPER:
      return t('USER_POSITION.DEVELOPER');
    case UserPosition.PROJECT_MANAGER:
      return t('USER_POSITION.PROJECT_MANAGER');
    case UserPosition.TEAM_LEAD:
      return t('USER_POSITION.TEAM_LEAD');
    case UserPosition.SCIENTIST:
      return t('USER_POSITION.SCIENTIST');
    case UserPosition.NONE:
    default:
      return '';
  }
};

export const formatPriceAmount = (currencyFormatter: Intl.NumberFormat, amount: string | undefined): string => {
  const value = !!amount?.length
    ? amount.length > 2
      ? parseFloat(
          parseFloat(`${amount.substring(0, amount.length - 2)}.${amount.substring(amount.length - 2)}`).toFixed(2),
        )
      : parseFloat(amount)
    : null;
  return value !== null ? currencyFormatter.format(value) : '';
};

export const createCondaChannelName = (value: string): string => value.toLowerCase().replaceAll(' ', '');
