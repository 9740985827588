import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { SecurityRole } from '../../types/types.d';
import { EMAIL_VERIFICATION, LOGIN } from '../../utils/routingUtils';
import { useSelector } from 'react-redux';
import { AuthSliceState } from '../../slices/authSlice';
import { userHasAnyRole } from '../../utils/userUtils';

interface PrivateRouteProps extends RouteProps {
  roles: SecurityRole[];
}

/**
 * @return provided component if access is allowed, otherwise redirects to the LoginTwoFA component
 */
function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { roles, ...rest } = props;
  const { user } = useSelector((state: { auth: AuthSliceState }) => state.auth);
  const isAllowed = userHasAnyRole(user, roles);
  const isVerified = user && user.emailVerified;

  if (isVerified) {
    return isAllowed ? <Route {...rest} render={undefined} /> : <Redirect to={{ pathname: LOGIN }} />;
  } else {
    return <Redirect to={{ pathname: EMAIL_VERIFICATION }} />;
  }
}

const UserPrivateRoute = (props: RouteProps): JSX.Element => {
  return <PrivateRoute {...props} roles={[SecurityRole.USER]} />;
};

const AdminPrivateRoute = (props: RouteProps): JSX.Element => {
  return <PrivateRoute {...props} roles={[SecurityRole.ADMIN]} />;
};

const UserAndAdminPrivateRoute = (props: RouteProps): JSX.Element => {
  return <PrivateRoute {...props} roles={[SecurityRole.USER, SecurityRole.ADMIN]} />;
};

export { UserPrivateRoute, AdminPrivateRoute, UserAndAdminPrivateRoute };
