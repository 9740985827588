import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import logger from '../../../../../utils/logger/logger';
import { addUsernameToBlacklist } from '../../../../../graphql/mutations';
import { Store } from 'react-notifications-component';
import { baseErrorNotification, baseSuccessNotification } from '../../../../../utils/nitificationUtils';
import { useSelector } from 'react-redux';
import { BlacklistSliceState } from '../../../../../slices/blacklistSlice';
import { BlacklistedUsername } from '../../../../../types/types';

interface Values {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, names: string[]): any | (() => any) => {
  return Yup.object().shape({
    name: Yup.string()
      .test(
        'name-test',
        t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME.VALIDATION'),
        (value) => !!value && !names.includes(value.trim()),
      )
      .required(t('VALIDATION.REQUIRED')),
  });
};

interface Props {
  show: boolean;
  onHide: () => void;
  onAdd: () => void;
}

/**
 * Shows form to delete a user.
 */
export default function AddUsername({ show, onHide, onAdd }: Props): JSX.Element {
  const { t } = useTranslation();
  const { usernames } = useSelector((state: { blacklist: BlacklistSliceState }) => state.blacklist);
  const names = useMemo(() => usernames.map((u) => u.username), [usernames]);
  const [doAdd] = useMutation<{ addUsernameToBlacklist?: BlacklistedUsername }>(addUsernameToBlacklist);

  const showSuccessNotification = useCallback(
    (msg: string) =>
      Store?.addNotification({
        ...baseSuccessNotification,
        message: msg,
      }),
    [],
  );
  const showErrorNotification = useCallback(
    (title: string) =>
      Store?.addNotification({
        ...baseErrorNotification,
        title,
        message: t('COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE'),
      }),
    [t],
  );

  const handleSubmit = async ({ name }: Values, setSubmitting: (isSubmitting: boolean) => void): Promise<void> => {
    setSubmitting(true);

    doAdd({ variables: { username: name.trim() } })
      .then((result) => {
        if (result) {
          showSuccessNotification(t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME.SUCCESS', { name: name.trim() }));
          onAdd();
        } else {
          showErrorNotification(t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME.ERROR', { name: name.trim() }));
          logger.error(`Not added username: ${name.trim()}`);
        }
      })
      .catch((error) => {
        showErrorNotification(t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME.DELETE_ERROR', { name: name.trim() }));
        logger.error(`Error adding user ${name.trim()}: ${error}`);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema(t, names)}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{ name: '' }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Form.Group className="position-relative" controlId="formGroupName">
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  autoFocus={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              <div className="mt-4 text-right">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {t('PRIVATE.ADMIN.BLACKLIST.ADD_USERNAME.CTA')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
