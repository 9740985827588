import React, { useMemo, useState } from 'react';
import styles from './PlanAndBilling.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PspSubscription } from '../../../../../../types/types';
import InvoicesHistory from '../../../../common/invoicesHistory';
import { formatPriceAmount } from '../../../../../../utils/businessUtils';
import { useLocalisedCurrencyFormatterWithoutDecimal } from '../../../../../../utils/i18nUtils';
import { useSelector } from 'react-redux';
import { PspSliceState } from '../../../../../../slices/pspSlice';

interface Props {
  subscriptions: PspSubscription[];
}

export default function PlanAndBilling({ subscriptions }: Props): JSX.Element {
  const { t } = useTranslation();
  const currencyFormatter = useLocalisedCurrencyFormatterWithoutDecimal();
  const { products } = useSelector((state: { psp: PspSliceState }) => state.psp);
  const [showInvoices, setShowInvoices] = useState(false);

  const { productName, amount } = useMemo(() => {
    const price = !!subscriptions[0]?.items?.length ? subscriptions[0].items[0]?.price : null;
    if (!price)
      return {
        priceId: null,
        productName: t('PRIVATE.GROUP.PLAN_AND_BILLING.FREE'),
        amount: currencyFormatter.format(0),
      };

    return {
      priceId: price.id,
      productName: products.find((p) => p.id === price?.product)?.name || '',
      amount: `${formatPriceAmount(currencyFormatter, price?.amount) || ''}/p${
        price?.recurringPeriod?.substring(0, 1).toUpperCase() || ''
      }`,
    };
  }, [currencyFormatter, products, subscriptions, t]);

  return (
    <>
      {subscriptions && showInvoices && (
        <InvoicesHistory onHide={() => setShowInvoices(false)} subscriptionId={subscriptions[0].id} />
      )}
      <div className={classNames('box', styles.content)}>
        <div className={styles.header}>{t('PRIVATE.ADMIN.GROUPS.GROUP.PLAN_AND_BILLING.TITLE')}</div>
        <div className={styles.plan}>
          <div className={styles.change}>
            <div>{productName}</div>
            <div>{amount}</div>
          </div>
          {subscriptions && (
            <div className={styles.invoices} onClick={() => setShowInvoices(true)}>
              {t('PRIVATE.ADMIN.GROUPS.GROUP.PLAN_AND_BILLING.INVOICES')}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
