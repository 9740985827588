import { Spinner } from 'react-bootstrap';
import React from 'react';
import { Variant } from 'react-bootstrap/esm/types';

interface LoadingProps {
  variant?: Variant;
  size?: 'sm' | undefined;
}

export const Loading = ({ variant, size }: LoadingProps): JSX.Element => (
  <Spinner animation="border" role="status" variant={variant ? variant : 'primary'} size={size ? size : undefined}>
    {/*<span className="sr-only">Loading...</span>*/}
  </Spinner>
);
